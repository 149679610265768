import { memo } from 'react';
import { infoIcon, warningInfoIcon, pen } from '../../assets';
import { Container, ErrorSvg, ImageIcon, InfoSvg } from './styles';

export type InfoPanelProps = {
  children?: JSX.Element | JSX.Element[];
  content?: string;
  className?: string;
  type?: 'INFO' | 'WARNING' | 'ERROR' | 'UPDATE';
  onClick?: () => void;
};

const InfoPanel = ({ children, content, className, type = 'INFO', onClick }: InfoPanelProps): JSX.Element => {
  return (
    <Container type={type} className={className} onClick={onClick}>
      {type === 'INFO' && className !== 'detail-proposal' && <ImageIcon src={infoIcon} alt="Info" loading="lazy" />}
      {type === 'INFO' && className === 'detail-proposal' && <InfoSvg />}
      {type === 'WARNING' && <ImageIcon src={warningInfoIcon} alt="Info" loading="lazy" />}
      {type === 'UPDATE' && <ImageIcon src={pen} alt="Pen icon" loading="lazy" className="update" />}
      {type === 'ERROR' && <ErrorSvg aria-label="Error tag" />}
      {children || content}
    </Container>
  );
};

export default memo(InfoPanel);
