const fakeOccupationData = [
  {
    partnerCode: '525',
    description: 'ACOUGUEIRO',
  },
  {
    partnerCode: '1017',
    description: 'ACUPUNTURISTA',
  },
  {
    partnerCode: '125',
    description: 'ADMINISTRADOR',
  },
  {
    partnerCode: '1069',
    description: 'ADMINISTRADOR DE COMARCA',
  },
  {
    partnerCode: '1111',
    description: 'ADMINISTRADOR DE TRIBUNAL',
  },
  {
    partnerCode: '131',
    description: 'ADVOGADO',
  },
  {
    partnerCode: '1018',
    description: 'AERONAUTA',
  },
  {
    partnerCode: '189',
    description: 'AEROVIARIO',
  },
  {
    partnerCode: '405',
    description: 'AGENCIADOR DE PROPAGANDA',
  },
  {
    partnerCode: '1004',
    description: 'AGENTE ADMINISTRATIVO',
  },
  {
    partnerCode: '1100',
    description: 'AGENTE COMUNITARIO DE SAUDE',
  },
  {
    partnerCode: '1009',
    description: 'AGENTE DE SERVICOS FUNERARIOS E EMBALSAMADOR',
  },
  {
    partnerCode: '594',
    description: 'AGENTE DE VIAGEM E GUIA DE TURISMO',
  },
  {
    partnerCode: '601',
    description: 'AGRICULTOR',
  },
  {
    partnerCode: '1062',
    description: 'AGRIMENSOR',
  },
  {
    partnerCode: '103',
    description: 'AGRONOMO',
  },
  {
    partnerCode: '1068',
    description: 'AGROPECUARISTA',
  },
  {
    partnerCode: '591',
    description: 'ALFAIATE',
  },
  {
    partnerCode: '410',
    description: 'AMBULANTE',
  },
  {
    partnerCode: '381',
    description: 'ANALISTA',
  },
  {
    partnerCode: '126',
    description: 'ANALISTA DE SISTEMA',
  },
  {
    partnerCode: '1050',
    description: 'ANTIQUARIO',
  },
  {
    partnerCode: '1019',
    description: 'ANTROPOLOGO',
  },
  {
    partnerCode: '1051',
    description: 'APICULTOR',
  },
  {
    partnerCode: '923',
    description: 'APOSENTADO / PENSIONISTA',
  },
  {
    partnerCode: '1108',
    description: 'APRENDIZ BANCO DO BRASIL',
  },
  {
    partnerCode: '1079',
    description: 'AQUICULTOR',
  },
  {
    partnerCode: '1020',
    description: 'ARMEIRO',
  },
  {
    partnerCode: '1047',
    description: 'ARQUEOLOGO E MUSEOLOGO',
  },
  {
    partnerCode: '102',
    description: 'ARQUITETO OU PAISAGISTA',
  },
  {
    partnerCode: '199',
    description: 'ARTESAO',
  },
  {
    partnerCode: '1021',
    description: 'ARTISTA PLASTICO',
  },
  {
    partnerCode: '134',
    description: 'ASSISTENTE SOCIAL',
  },
  {
    partnerCode: '1022',
    description: 'ASTROLOGO, NUMEROLOGO E AFINS',
  },
  {
    partnerCode: '128',
    description: 'ASTRONOMO',
  },
  {
    partnerCode: '168',
    description: 'ATLETA PROFISSIONAL E TECNICO EM DESPORTOS',
  },
  {
    partnerCode: '162',
    description: 'ATOR E DIRETOR DE ESPETACULOS PUBLICOS',
  },
  {
    partnerCode: '802',
    description: 'AUXILIAR DE ENFERMAGEM',
  },
  {
    partnerCode: '801',
    description: 'AUXILIAR DE ESCRITORIO E ASSEMELHADOS',
  },
  {
    partnerCode: '1010',
    description: 'AUXILIAR DE LABORATORIO E RAIOS X',
  },
  {
    partnerCode: '145',
    description: 'AUXILIAR DE NUTRICAO',
  },
  {
    partnerCode: '805',
    description: 'AUXILIAR DE SERVICOS GERAIS',
  },
  {
    partnerCode: '1052',
    description: 'AVICULTOR',
  },
  {
    partnerCode: '395',
    description: 'BANCARIO E ECONOMIARIO',
  },
  {
    partnerCode: '511',
    description: 'BARBEIRO, CABELEIREIRO, MANICURE E PEDICURE',
  },
  {
    partnerCode: '135',
    description: 'BIBLIOTECARIO OU ARQUIVISTA',
  },
  {
    partnerCode: '1023',
    description: 'BIOFISICO',
  },
  {
    partnerCode: '116',
    description: 'BIOLOGO OU BIOMEDICO',
  },
  {
    partnerCode: '1024',
    description: 'BIOQUIMICO',
  },
  {
    partnerCode: '1101',
    description: 'BOLSISTA MESTRADO/DOUTORADO',
  },
  {
    partnerCode: '1104',
    description: 'BOLSISTA OUTROS',
  },
  {
    partnerCode: '1008',
    description: 'BOMBEIRO E INSTALADOR DE GAS, AGUA, ESGOTO E ASSEMELHADOS',
  },
  {
    partnerCode: '549',
    description: 'BORRACHEIRO',
  },
  {
    partnerCode: '163',
    description: 'CANTOR E COMPOSITOR',
  },
  {
    partnerCode: '1115',
    description: 'CAPITALISTA',
  },
  {
    partnerCode: '907',
    description: 'CAPITALISTA/RENTISTA/LOCADOR',
  },
  {
    partnerCode: '1025',
    description: 'CARTEIRO',
  },
  {
    partnerCode: '1032',
    description: 'CENOGRAFO',
  },
  {
    partnerCode: '1054',
    description: 'CERAMISTA',
  },
  {
    partnerCode: '1026',
    description: 'CHAVEIRO',
  },
  {
    partnerCode: '537',
    description: 'COBRADOR DE TRANSPORTE COLETIVO',
  },
  {
    partnerCode: '419',
    description: 'COMERCIARIO',
  },
  {
    partnerCode: '136',
    description: 'COMUNICOLOGO',
  },
  {
    partnerCode: '1118',
    description: 'CONSELHEIRO TUTELAR',
  },
  {
    partnerCode: '1097',
    description: 'CONSULTOR LEGISLATIVO',
  },
  {
    partnerCode: '124',
    description: 'CONTADOR',
  },
  {
    partnerCode: '165',
    description: 'COREOGRAFO E BAILARINO',
  },
  {
    partnerCode: '1098',
    description: 'CORREGEDOR',
  },
  {
    partnerCode: '403',
    description: 'CORRETOR',
  },
  {
    partnerCode: '740',
    description: 'COSTUREIRO',
  },
  {
    partnerCode: '529',
    description: 'COZINHEIRO, COPEIRO, GARCOM, BARMEN E CAMAREIRO',
  },
  {
    partnerCode: '193',
    description: 'DECORADOR',
  },
  {
    partnerCode: '1107',
    description: 'DEFENSOR PUBLICO',
  },
  {
    partnerCode: '1003',
    description: 'DELEGADO DE POLICIA',
  },
  {
    partnerCode: '1088',
    description: 'DEPUTADO ESTADUAL/DISTRITAL',
  },
  {
    partnerCode: '1087',
    description: 'DEPUTADO FEDERAL',
  },
  {
    partnerCode: '205',
    description: 'DESEMBARGADOR',
  },
  {
    partnerCode: '1120',
    description: 'DESENHISTA',
  },
  {
    partnerCode: '192',
    description: 'DESENHISTA COMERCIAL',
  },
  {
    partnerCode: '105',
    description: 'DESENHISTA INDUSTRIAL',
  },
  {
    partnerCode: '158',
    description: 'DESENHISTA TECNICO',
  },
  {
    partnerCode: '593',
    description: 'DESPACHANTE, INCLUSIVE O ADUANEIRO',
  },
  {
    partnerCode: '1081',
    description: 'DETETIVE PARTICULAR',
  },
  {
    partnerCode: '212',
    description: 'DIPLOMATA',
  },
  {
    partnerCode: '1110',
    description: 'DISCOTECARIO, VIDEOTECARIO E AFINS',
  },
  {
    partnerCode: '932',
    description: 'DO LAR',
  },
  {
    partnerCode: '1027',
    description: 'ECOLOGISTA',
  },
  {
    partnerCode: '121',
    description: 'ECONOMISTA',
  },
  {
    partnerCode: '542',
    description: 'ELETRICISTA E ASSEMELHADOS',
  },
  {
    partnerCode: '1028',
    description: 'ELETRICITARIO',
  },
  {
    partnerCode: '999',
    description: 'em branco',
  },
  {
    partnerCode: '811',
    description: 'EMPREGADO DOMESTICO',
  },
  {
    partnerCode: '906',
    description: 'EMPRESARIO',
  },
  {
    partnerCode: '161',
    description: 'EMPRESARIO E PRODUTOR DE ESPETACULOS PUBLICOS',
  },
  {
    partnerCode: '113',
    description: 'ENFERMEIRO',
  },
  {
    partnerCode: '101',
    description: 'ENGENHEIRO',
  },
  {
    partnerCode: '397',
    description: 'ESCREVENTE/AUXILIAR DE CARTORIO',
  },
  {
    partnerCode: '1029',
    description: 'ESCRITOR',
  },
  {
    partnerCode: '191',
    description: 'ESCULTOR/PINTOR OU ASSEMELHADO',
  },
  {
    partnerCode: '1117',
    description: 'ESPOLIO',
  },
  {
    partnerCode: '931',
    description: 'ESTAGIARIO E ASSEMELHADOS',
  },
  {
    partnerCode: '122',
    description: 'ESTATISTICO',
  },
  {
    partnerCode: '1080',
    description: 'ESTETICISTA',
  },
  {
    partnerCode: '1011',
    description: 'ESTIVADOR, CARREGADOR, EMBALADOR E ASSEMELHADOS',
  },
  {
    partnerCode: '1002',
    description: 'ESTUDANTE',
  },
  {
    partnerCode: '117',
    description: 'FARMACEUTICO',
  },
  {
    partnerCode: '1072',
    description: 'FAXINEIRO',
  },
  {
    partnerCode: '413',
    description: 'FEIRANTE',
  },
  {
    partnerCode: '755',
    description: 'FERRAMENTEIRO',
  },
  {
    partnerCode: '1074',
    description: 'FERROVIARIO',
  },
  {
    partnerCode: '1030',
    description: 'FILOLOGO',
  },
  {
    partnerCode: '1031',
    description: 'FILOSOFO',
  },
  {
    partnerCode: '539',
    description: 'FISCAL, AUDITOR E ASSEMELHADOS',
  },
  {
    partnerCode: '106',
    description: 'FISICO',
  },
  {
    partnerCode: '114',
    description: 'FISIOTERAPEUTA OU TERAPEUTA OCUPACIONAL',
  },
  {
    partnerCode: '1033',
    description: 'FLORICULTOR',
  },
  {
    partnerCode: '118',
    description: 'FONOAUDIOLOGO',
  },
  {
    partnerCode: '1000',
    description: 'FOTOGRAFO',
  },
  {
    partnerCode: '806',
    description: 'FRENTISTA',
  },
  {
    partnerCode: '543',
    description: 'FUNILEIRO',
  },
  {
    partnerCode: '742',
    description: 'GARI OU VARREDOR DE LOGRADOUROS PUBLICOS',
  },
  {
    partnerCode: '605',
    description: 'GARIMPEIRO',
  },
  {
    partnerCode: '127',
    description: 'GEOGRAFO',
  },
  {
    partnerCode: '107',
    description: 'GEOLOGO',
  },
  {
    partnerCode: '1086',
    description: 'GOVERNADOR DE ESTADO/DISTRITO FEDERAL',
  },
  {
    partnerCode: '1055',
    description: 'HISTORIADOR',
  },
  {
    partnerCode: '903',
    description: 'INDUSTRIARIO',
  },
  {
    partnerCode: '1071',
    description: 'INSTRUTOR',
  },
  {
    partnerCode: '810',
    description: 'JARDINEIRO',
  },
  {
    partnerCode: '717',
    description: 'JOALHEIRO E OURIVES',
  },
  {
    partnerCode: '412',
    description: 'JORNALEIRO',
  },
  {
    partnerCode: '171',
    description: 'JORNALISTA',
  },
  {
    partnerCode: '1007',
    description: 'LANTERNEIRO E PINTOR DE VEICULOS',
  },
  {
    partnerCode: '404',
    description: 'LEILOEIRO, AVALIADOR E ASSEMELHADOS',
  },
  {
    partnerCode: '166',
    description: 'LOCUTOR E COMENTARISTA DE RADIO E TELEVISAO E RADIALISTA',
  },
  {
    partnerCode: '1070',
    description: 'MAGISTRADO',
  },
  {
    partnerCode: '535',
    description: 'MAQUINISTA E FOGUISTA DE EMBARCACOES,LOCOMOTIVAS E ASSEMELHA',
  },
  {
    partnerCode: '774',
    description: 'MARCENEIRO E TRAB.ARTEFATOS MADEIRA',
  },
  {
    partnerCode: '534',
    description: 'MARINHEIRO',
  },
  {
    partnerCode: '899',
    description: 'MASSAGISTA',
  },
  {
    partnerCode: '123',
    description: 'MATEMATICO E ATUARIO',
  },
  {
    partnerCode: '762',
    description: 'MECANICO DE MANUTENCAO DE MAQUINAS INDUSTRIAIS',
  },
  {
    partnerCode: '541',
    description: 'MECANICO DE MANUTENCAO DE VEICULOS AUTOMOTORES',
  },
  {
    partnerCode: '111',
    description: 'MEDICO',
  },
  {
    partnerCode: '1102',
    description: 'MENOR SEM ATIVIDADE',
  },
  {
    partnerCode: '1099',
    description: 'MERGULHADOR',
  },
  {
    partnerCode: '701',
    description: 'MESTRE E CONTRAMESTRE',
  },
  {
    partnerCode: '719',
    description: 'METALURGICO E SIDERURGICO',
  },
  {
    partnerCode: '1048',
    description: 'METEOROLOGISTA',
  },
  {
    partnerCode: '1114',
    description: 'MILITAR DAS FORCAS ARMADAS - AERONAUTICA',
  },
  {
    partnerCode: '1112',
    description: 'MILITAR DAS FORCAS ARMADAS - EXERCITO',
  },
  {
    partnerCode: '1113',
    description: 'MILITAR DAS FORCAS ARMADAS - MARINHA',
  },
  {
    partnerCode: '294',
    description: 'MILITAR EM GERAL',
  },
  {
    partnerCode: '202',
    description: 'MINISTRO DE ESTADO',
  },
  {
    partnerCode: '1001',
    description: 'MISSIONARIO',
  },
  {
    partnerCode: '1083',
    description: 'MODELISTA E/OU CORTADOR - VESTUARIO',
  },
  {
    partnerCode: '492',
    description: 'MODELO DE MODAS',
  },
  {
    partnerCode: '1035',
    description: 'MODISTA',
  },
  {
    partnerCode: '809',
    description: 'MOTOCICLISTA (TRANSP. DE MERCADORIAS) E/OU ASSEMELHADOS',
  },
  {
    partnerCode: '1121',
    description: 'MOTORISTA',
  },
  {
    partnerCode: '532',
    description: 'MOTORISTA DE VEICULOS DE TRANSPORTE DE CARGA',
  },
  {
    partnerCode: '531',
    description: 'MOTORISTA DE VEICULOS DE TRANSPORTE DE PASSAGEIROS',
  },
  {
    partnerCode: '769',
    description: 'MOTORISTA/OPERADOR DE MAQUINAS PESADAS',
  },
  {
    partnerCode: '164',
    description: 'MUSICO',
  },
  {
    partnerCode: '1049',
    description: 'NUTRICIONISTA',
  },
  {
    partnerCode: '1036',
    description: 'OCEANOGRAFO',
  },
  {
    partnerCode: '115',
    description: 'ODONTOLOGO',
  },
  {
    partnerCode: '216',
    description: 'OFICIAL DAS FORCAS ARMADAS E FORCAS AUXILIARES',
  },
  {
    partnerCode: '1095',
    description: 'OFICIAL DE JUSTICA',
  },
  {
    partnerCode: '1037',
    description: 'OLEIRO',
  },
  {
    partnerCode: '386',
    description: 'OPERADOR DE COMPUTADORES',
  },
  {
    partnerCode: '1078',
    description: 'OPERADOR DE MAQUINAS AGROPECUARIAS',
  },
  {
    partnerCode: '1109',
    description: 'OPERADOR DE TELEMARKETING',
  },
  {
    partnerCode: '1056',
    description: 'OPTICO',
  },
  {
    partnerCode: '1057',
    description: 'ORNITOLOGO',
  },
  {
    partnerCode: '1038',
    description: 'PADEIRO E CONFEITEIRO',
  },
  {
    partnerCode: '925',
    description: 'PASTOR',
  },
  {
    partnerCode: '602',
    description: 'PECUARISTA',
  },
  {
    partnerCode: '144',
    description: 'PEDAGOGO',
  },
  {
    partnerCode: '604',
    description: 'PESCADOR',
  },
  {
    partnerCode: '1067',
    description: 'PESCADOR COM FINS COMERCIAIS',
  },
  {
    partnerCode: '1063',
    description: 'PESQ/PRODUC DE MUDAS OU SEMENT FISCALIZADAS/CERTIFICADAS',
  },
  {
    partnerCode: '1064',
    description: 'PESQ/PRODUC DE SEMEM PARA INSEMINACAO ARTIFICIAL',
  },
  {
    partnerCode: '181',
    description: 'PILOTO DE AERONAVES',
  },
  {
    partnerCode: '1039',
    description: 'POLICIAL CIVIL',
  },
  {
    partnerCode: '501',
    description: 'PORTEIRO DE EDIFICIO, ASCENSORISTA E GARAGISTA',
  },
  {
    partnerCode: '1073',
    description: 'PORTUARIO',
  },
  {
    partnerCode: '1090',
    description: 'PREFEITO MUNICIPAL',
  },
  {
    partnerCode: '1084',
    description: 'PRESIDENTE DA REPUBLICA',
  },
  {
    partnerCode: '1066',
    description: 'PREST SERV DE INSEMINACAO ARTIFICIAL, EM IMOVEL RURAL',
  },
  {
    partnerCode: '1065',
    description: 'PREST SERV MECANIZAD,NATURAL,RURAL INCLUSIVE PROTECAO SOLO',
  },
  {
    partnerCode: '211',
    description: 'PROCURADOR DA JUSTICA E ASSEMELHADOS',
  },
  {
    partnerCode: '143',
    description: 'PROFESSOR DE ENSINO FUNDAMENTAL E MEDIO',
  },
  {
    partnerCode: '142',
    description: 'PROFESSOR DE ENSINO SUPERIOR',
  },
  {
    partnerCode: '1119',
    description: 'PROFESSOR/DEMAIS',
  },
  {
    partnerCode: '138',
    description: 'PROFISSIONAL DE LETRAS E ARTES',
  },
  {
    partnerCode: '388',
    description: 'PROGRAMADOR DE SISTEMA',
  },
  {
    partnerCode: '1089',
    description: 'PROMOTOR DE JUSTICA',
  },
  {
    partnerCode: '499',
    description: 'PROMOTOR DE VENDAS E DEMONSTRADOR',
  },
  {
    partnerCode: '592',
    description: 'PROTETICO',
  },
  {
    partnerCode: '132',
    description: 'PSICOLOGO E PSICANALISTA',
  },
  {
    partnerCode: '172',
    description: 'PUBLICITARIO',
  },
  {
    partnerCode: '104',
    description: 'QUIMICO',
  },
  {
    partnerCode: '1040',
    description: 'RADIOTECNICO',
  },
  {
    partnerCode: '1053',
    description: 'REDATOR',
  },
  {
    partnerCode: '137',
    description: 'RELACOES PUBLICAS',
  },
  {
    partnerCode: '1041',
    description: 'RELOJOEIRO',
  },
  {
    partnerCode: '1116',
    description: 'RENTISTA OU LOCADOR',
  },
  {
    partnerCode: '402',
    description: 'REPRESENTANTE COMERCIAL',
  },
  {
    partnerCode: '1058',
    description: 'RODOVIARIO',
  },
  {
    partnerCode: '1042',
    description: 'SACERDOTE',
  },
  {
    partnerCode: '1077',
    description: 'SALVA-VIDAS OU BOMBEIRO PARTICULAR/AUTONOMO',
  },
  {
    partnerCode: '596',
    description: 'SAPATEIRO E DEMAIS TRAB DE CALCADOS E ARTEFATOS DE COURO',
  },
  {
    partnerCode: '1093',
    description: 'SECRETARIO ESTADUAL',
  },
  {
    partnerCode: '1092',
    description: 'SECRETARIO FEDERAL',
  },
  {
    partnerCode: '1094',
    description: 'SECRETARIO MUNICIPAL',
  },
  {
    partnerCode: '393',
    description: 'SECRETARIO/ESTENOGRAFO/DATIL/RECEP/TELEF TRAB ASSEMELHADOS',
  },
  {
    partnerCode: '396',
    description: 'SECURITARIO',
  },
  {
    partnerCode: '807',
    description: 'SEGURANCA/VIGILANTE',
  },
  {
    partnerCode: '201',
    description: 'SENADOR DA REPUBLICA',
  },
  {
    partnerCode: '758',
    description: 'SERRALHEIRO',
  },
  {
    partnerCode: '293',
    description: 'SERVENTUARIO DE JUSTICA',
  },
  {
    partnerCode: '297',
    description: 'SERVIDOR PUBLICO ESTADUAL',
  },
  {
    partnerCode: '296',
    description: 'SERVIDOR PUBLICO FEDERAL',
  },
  {
    partnerCode: '298',
    description: 'SERVIDOR PUBLICO MUNICIPAL',
  },
  {
    partnerCode: '133',
    description: 'SOCIOLOGO',
  },
  {
    partnerCode: '1043',
    description: 'SUCATEIRO',
  },
  {
    partnerCode: '760',
    description: 'SUPERVISOR, INSPETOR E AGENTE DE COMPRAS E VENDAS',
  },
  {
    partnerCode: '1005',
    description: 'TABELIAO/OFICIAL DE REGISTRO',
  },
  {
    partnerCode: '1059',
    description: 'TAPECEIRO',
  },
  {
    partnerCode: '1044',
    description: 'TAQUIGRAFO',
  },
  {
    partnerCode: '536',
    description: 'TAXISTA',
  },
  {
    partnerCode: '153',
    description: 'TECNICO AGRICOLA',
  },
  {
    partnerCode: '152',
    description: 'TECNICO DE BIOLOGIA',
  },
  {
    partnerCode: '151',
    description: 'TECNICO DE CONTABILIDADE E DE ESTATISTICA',
  },
  {
    partnerCode: '1082',
    description: 'TECNICO DE EDIFICACOES',
  },
  {
    partnerCode: '156',
    description: 'TECNICO DE ELETRICIDADE, ELETRONICA E TELECOMUNICACOES',
  },
  {
    partnerCode: '1106',
    description: 'TECNICO DE ENFERMAGEM',
  },
  {
    partnerCode: '1076',
    description: 'TECNICO DE INFORMATICA',
  },
  {
    partnerCode: '157',
    description: 'TECNICO DE LABORATORIO E RAIOS X',
  },
  {
    partnerCode: '155',
    description: 'TECNICO DE MECANICA',
  },
  {
    partnerCode: '154',
    description: 'TECNICO DE QUIMICA',
  },
  {
    partnerCode: '167',
    description: 'TECNICO EM AUDIO E CENOGRAFIA',
  },
  {
    partnerCode: '1075',
    description: 'TECNICO EM SEGURANCA DO TRABALHO',
  },
  {
    partnerCode: '761',
    description: 'TECNICO INSTRUMENTISTA',
  },
  {
    partnerCode: '108',
    description: 'TECNOLOGO',
  },
  {
    partnerCode: '1060',
    description: 'TINTUREIRO',
  },
  {
    partnerCode: '759',
    description: 'TIPOGRAFO E DEMAIS TRABALHADORES DE ARTES GRAFICAS',
  },
  {
    partnerCode: '1061',
    description: 'TOPOGRAFO',
  },
  {
    partnerCode: '765',
    description: 'TORNEIRO MECANICO',
  },
  {
    partnerCode: '392',
    description: 'TRAB DE SERVICOS DE CONTABILIDADE, CAIXA E ASSEMELHADOS',
  },
  {
    partnerCode: '771',
    description: 'TRABALHADOR DE CONSTRUCAO CIVIL',
  },
  {
    partnerCode: '1034',
    description: 'TRABALHADOR DE EXTRACAO DE MINERIOS',
  },
  {
    partnerCode: '1105',
    description: 'TRABALHADOR DE EXTRACAO VEGETAL',
  },
  {
    partnerCode: '1015',
    description: 'TRABALHADOR DE FABRICACAO DE PAPEL E PAPELAO',
  },
  {
    partnerCode: '1016',
    description: 'TRABALHADOR DE FABRICACAO DE PRODUTOS DE BORRACHA E PLASTICO',
  },
  {
    partnerCode: '1013',
    description: 'TRABALHADOR DE FABRICACAO DE ROUPAS',
  },
  {
    partnerCode: '1014',
    description: 'TRABALHADOR DE FABRICACAO E PREPARACAO DE ALIMENTOS E BEBIDA',
  },
  {
    partnerCode: '1012',
    description: 'TRABALHADOR DE INSTALACOES DE PROCESSAMENTO QUIMICO',
  },
  {
    partnerCode: '756',
    description: 'TRABALHADOR DE USINAGEM DE METAIS',
  },
  {
    partnerCode: '603',
    description: 'TRABALHADOR FLORESTAL',
  },
  {
    partnerCode: '998',
    description: 'TRABALHADOR INFORMAL',
  },
  {
    partnerCode: '812',
    description: 'TRABALHADOR RURAL',
  },
  {
    partnerCode: '1045',
    description: 'TRADUTOR',
  },
  {
    partnerCode: '411',
    description: 'VENDEDOR DE COMERCIO VAREJISTA E ATACADISTA',
  },
  {
    partnerCode: '1006',
    description: 'VENDEDOR PRACISTA E CAIXEIRO VIAJANTE',
  },
  {
    partnerCode: '1096',
    description: 'VEREADOR',
  },
  {
    partnerCode: '112',
    description: 'VETERINARIO OU ZOOTECNISTA',
  },
  {
    partnerCode: '1103',
    description: 'VICE-GOVERNADOR DE ESTADO/DISTRITO FEDERAL',
  },
  {
    partnerCode: '1091',
    description: 'VICE-PREFEITO MUNICIPAL',
  },
  {
    partnerCode: '1085',
    description: 'VICE-PRESIDENTE DA REPUBLICA',
  },
  {
    partnerCode: '1046',
    description: 'VIDRACEIRO',
  },
  {
    partnerCode: '502',
    description: 'ZELADOR DE EDIFICIO',
  },
];

const fakeProfessionTypeData = [
  {
    partnerCode: '7',
    description: 'APOSENTADO OU PENSIONISTA',
  },
  {
    partnerCode: '1',
    description: 'ASSALARIADO',
  },
  {
    partnerCode: '3',
    description: 'AUTONOMO',
  },
  {
    partnerCode: '2',
    description: 'EMPRESARIO',
  },
  {
    partnerCode: '6',
    description: 'FUNCIONARIO PUBLICO',
  },
  {
    partnerCode: '8',
    description: 'INVESTIDOR',
  },
  {
    partnerCode: '4',
    description: 'LIBERAL',
  },
];

export const resolveFakeOccupationData = () => Promise.resolve(fakeOccupationData);
export const resolveFakeProfessionTypeData = () => Promise.resolve(fakeProfessionTypeData);
