/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ResponseError = {
    error?: ResponseError.error;
    message?: string;
}

export namespace ResponseError {

    export enum error {
        ORDER_PENDING_ALREADY_EXISTS = 'ORDER_PENDING_ALREADY_EXISTS',
        CONNECTION_REFUSED = 'CONNECTION_REFUSED',
        ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
        ORDER_ITEM_NOT_FOUND = 'ORDER_ITEM_NOT_FOUND',
        BAD_REQUEST = 'BAD_REQUEST',
        ONLY_NUMBER = 'ONLY_NUMBER',
        INVALID_NUMBER_SIZE = 'INVALID_NUMBER_SIZE',
        INVALID_DOCUMENT = 'INVALID_DOCUMENT',
        RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
        INVALID_FULL_NAME = 'INVALID_FULL_NAME',
        INVALID_REPORT_DOCUMENT = 'INVALID_REPORT_DOCUMENT',
        INVALID_REPORT_UUID = 'INVALID_REPORT_UUID',
        INVALID_REPORT_YEAR = 'INVALID_REPORT_YEAR',
        INVALID_STORE_DOCUMENT = 'INVALID_STORE_DOCUMENT',
        INVALID_STORE_DOCUMENT_SIZE = 'INVALID_STORE_DOCUMENT_SIZE',
        INVALID_STORE_DOCUMENT_NOT_UPDATE = 'INVALID_STORE_DOCUMENT_NOT_UPDATE',
        INVALID_PASSWORD = 'INVALID_PASSWORD',
        INVALID_NEW_PASSWORD = 'INVALID_NEW_PASSWORD',
        INVALID_PIN = 'INVALID_PIN',
        INVALID_DEVICE_DNA = 'INVALID_DEVICE_DNA',
        INVALID_CREDENTIAL = 'INVALID_CREDENTIAL',
        CUSTOMER_DOCUMENT_ALREADY_EXISTS = 'CUSTOMER_DOCUMENT_ALREADY_EXISTS',
        CUSTOMER_SMS_ATTEMPT_EXCEEDED = 'CUSTOMER_SMS_ATTEMPT_EXCEEDED',
        CUSTOMER_SMS_AUTHENTICATION_ERROR = 'CUSTOMER_SMS_AUTHENTICATION_ERROR',
        CUSTOMER_EMAIL_ALREADY_EXISTS = 'CUSTOMER_EMAIL_ALREADY_EXISTS',
        CUSTOMER_OWN_ID_ALREADY_EXISTS = 'CUSTOMER_OWN_ID_ALREADY_EXISTS',
        CUSTOMER_NOT_LOGGED = 'CUSTOMER_NOT_LOGGED',
        DEVICE_MOBILE_NUMBER_NOT_AVAILABLE = 'DEVICE_MOBILE_NUMBER_NOT_AVAILABLE',
        CUSTOMER_AUTHENTICATION_ERROR = 'CUSTOMER_AUTHENTICATION_ERROR',
        CUSTOMER_LOGIN_ATTEMPTS_EXCEEDED = 'CUSTOMER_LOGIN_ATTEMPTS_EXCEEDED',
        CUSTOMER_LOGIN_ATTEMPTS_WARNING = 'CUSTOMER_LOGIN_ATTEMPTS_WARNING',
        INVALID_FANTASY_NAME = 'INVALID_FANTASY_NAME',
        INVALID_ORDER_VALUE = 'INVALID_ORDER_VALUE',
        INVALID_ITEMS = 'INVALID_ITEMS',
        INVALID_SKU = 'INVALID_SKU',
        INVALID_ACTION_TYPE = 'INVALID_ACTION_TYPE',
        INVALID_SKU_SIZE = 'INVALID_SKU_SIZE',
        INVALID_ITEMS_NAME_SIZE = 'INVALID_ITEMS_NAME_SIZE',
        INVALID_ITEM_NAME = 'INVALID_ITEM_NAME',
        INVALID_ITEM_VALUE = 'INVALID_ITEM_VALUE',
        INVALID_ITEM_PRICE = 'INVALID_ITEM_PRICE',
        INVALID_ITEM_QUANITY = 'INVALID_ITEM_QUANITY',
        CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
        CUSTOMER_BLOCKED = 'CUSTOMER_BLOCKED',
        CUSTOMER_ALREADY_INACTIVE = 'CUSTOMER_ALREADY_INACTIVE',
        CUSTOMER_ALREADY_ACTIVE = 'CUSTOMER_ALREADY_ACTIVE',
        CUSTOMER_ALREADY_EXISTS = 'CUSTOMER_ALREADY_EXISTS',
        CUSTOMER_ACCOUNT_MANUAL_APPROVED_EXCEPTION = 'CUSTOMER_ACCOUNT_MANUAL_APPROVED_EXCEPTION',
        UNABLE_TO_CREATE_WALLET = 'UNABLE_TO_CREATE_WALLET',
        INVALID_TOKEN = 'INVALID_TOKEN',
        ORDER_CANCELATION_ERROR = 'ORDER_CANCELATION_ERROR',
        ORDER_ALREADY_CANCELED = 'ORDER_ALREADY_CANCELED',
        ORDER_ALREADY_REVERSED = 'ORDER_ALREADY_REVERSED',
        UNABLE_TO_REVERSE_ORDER = 'UNABLE_TO_REVERSE_ORDER',
        STORE_NOT_FOUND = 'STORE_NOT_FOUND',
        INVALID_OS_NAME = 'INVALID_OS_NAME',
        INVALID_OS_VERSION = 'INVALID_OS_VERSION',
        INVALID_DEVICE_MODEL = 'INVALID_DEVICE_MODEL',
        INVALID_NOTIFICATION_TOKEN = 'INVALID_NOTIFICATION_TOKEN',
        INVALID_DEVICE = 'INVALID_DEVICE',
        PUSH_NOTIFICATION_ERROR = 'PUSH_NOTIFICATION_ERROR',
        INVALID_ORDER_ID = 'INVALID_ORDER_ID',
        INVALID_ORDER_UUID = 'INVALID_ORDER_UUID',
        INVALID_ORDER_STATUS = 'INVALID_ORDER_STATUS',
        INVALID_CALL_ID = 'INVALID_CALL_ID',
        ACCESS_CODE_NOT_FOUND = 'ACCESS_CODE_NOT_FOUND',
        UNABLE_TO_CONFIRM_ORDER = 'UNABLE_TO_CONFIRM_ORDER',
        UNABLE_TO_CANCEL_ORDER = 'UNABLE_TO_CANCEL_ORDER',
        UNABLE_TO_CREATE_ORDER = 'UNABLE_TO_CREATE_ORDER',
        UNABLE_TO_EXPIRE_ORDER = 'UNABLE_TO_EXPIRE_ORDER',
        UNABLE_TO_GENERATE_PAYMENT_INSTRUMENT = 'UNABLE_TO_GENERATE_PAYMENT_INSTRUMENT',
        INVALID_PAGE_NUMBER = 'INVALID_PAGE_NUMBER',
        INVALID_ORDERS_QUANTITY = 'INVALID_ORDERS_QUANTITY',
        INVALID_ENC_KEY = 'INVALID_ENC_KEY',
        INVALID_ENC_PAYMENT_DATA = 'INVALID_ENC_PAYMENT_DATA',
        INVALID_CVV = 'INVALID_CVV',
        INVALID_CARD_BRAND = 'INVALID_CARD_BRAND',
        INVALID_CATEGORY = 'INVALID_CATEGORY',
        INVALID_DATE = 'INVALID_DATE',
        INVALID_DATE_GREATER_CURRENT = 'INVALID_DATE_GREATER_CURRENT',
        INVALID_TIME = 'INVALID_TIME',
        INVALID_DATE_TIME = 'INVALID_DATE_TIME',
        INVALID_DATE_EXPIRED = 'INVALID_DATE_EXPIRED',
        INVALID_DATE_FROM = 'INVALID_DATE_FROM',
        INVALID_DATE_TO = 'INVALID_DATE_TO',
        INVALID_DATE_RANGE = 'INVALID_DATE_RANGE',
        INVALID_TEXT_SIZE = 'INVALID_TEXT_SIZE',
        INVALID_CONFIRMATION_STATUS = 'INVALID_CONFIRMATION_STATUS',
        INVALID_NUMBER_OF_DAYS = 'INVALID_NUMBER_OF_DAYS',
        AT_LEAST_ONE_PARAMETER_IS_REQUIRED = 'AT_LEAST_ONE_PARAMETER_IS_REQUIRED',
        EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
        USER_EMAIL_ALREADY_EXISTS = 'USER_EMAIL_ALREADY_EXISTS',
        CATEGORY_ALREADY_EXISTS = 'CATEGORY_ALREADY_EXISTS',
        PAYMENT_ALREADY_EXISTS = 'PAYMENT_ALREADY_EXISTS',
        CATEGORY_NOT_FOUND = 'CATEGORY_NOT_FOUND',
        BRAND_ALREADY_EXISTS = 'BRAND_ALREADY_EXISTS',
        BRAND_NOT_FOUND = 'BRAND_NOT_FOUND',
        PARKING_ALREADY_EXISTS = 'PARKING_ALREADY_EXISTS',
        PARKING_NOT_FOUND = 'PARKING_NOT_FOUND',
        STORE_INTEGRATOR_NOT_FOUND = 'STORE_INTEGRATOR_NOT_FOUND',
        BILLING_ADDRESS_NOT_FOUND = 'BILLING_ADDRESS_NOT_FOUND',
        NO_DEBTS_FOUND = 'NO_DEBTS_FOUND',
        INVALID_PARKING_BAR_CODE = 'INVALID_PARKING_BAR_CODE',
        WRONG_USER_OR_PASSWORD = 'WRONG_USER_OR_PASSWORD',
        USER_WITH_NO_AUTHORITIES = 'USER_WITH_NO_AUTHORITIES',
        USER_NOT_FOUND = 'USER_NOT_FOUND',
        USER_INACTIVE = 'USER_INACTIVE',
        USER_RESEND_PASSWORD_NOT_ALLOWED = 'USER_RESEND_PASSWORD_NOT_ALLOWED',
        REQUIRED_USERNAME = 'REQUIRED_USERNAME',
        REQUIRED_USER_ID = 'REQUIRED_USER_ID',
        SESSION_NOT_FOUND = 'SESSION_NOT_FOUND',
        GROUP_NOT_FOUND = 'GROUP_NOT_FOUND',
        NULL_PARAMETERS_NOT_ACCEPTABLE = 'NULL_PARAMETERS_NOT_ACCEPTABLE',
        INVALID_TYPE = 'INVALID_TYPE',
        INVALID_TYPE_SIZE = 'INVALID_TYPE_SIZE',
        INVALID_NAME = 'INVALID_NAME',
        INVALID_NAME_NOT_UPDATE = 'INVALID_NAME_NOT_UPDATE',
        INVALID_NAME_SIZE = 'INVALID_NAME_SIZE',
        INVALID_FIRST_NAME = 'INVALID_FIRST_NAME',
        INVALID_FIRST_NAME_SIZE = 'INVALID_FIRST_NAME_SIZE',
        INVALID_LAST_NAME = 'INVALID_LAST_NAME',
        INVALID_LAST_NAME_SIZE = 'INVALID_LAST_NAME_SIZE',
        INVALID_EMAIL = 'INVALID_EMAIL',
        INVALID_EMAIL_NOT_UPDATE = 'INVALID_EMAIL_NOT_UPDATE',
        INVALID_PHONE = 'INVALID_PHONE',
        INVALID_PHONE_UUID = 'INVALID_PHONE_UUID',
        INVALID_PHONE_UUID_SIZE = 'INVALID_PHONE_UUID_SIZE',
        INVALID_EMAIL_SIZE = 'INVALID_EMAIL_SIZE',
        INVALID_COUNTRY_CODE = 'INVALID_COUNTRY_CODE',
        INVALID_COUNTRY_CODE_SIZE = 'INVALID_COUNTRY_CODE_SIZE',
        INVALID_AREA_CODE = 'INVALID_AREA_CODE',
        INVALID_AREA_CODE_SIZE = 'INVALID_AREA_CODE_SIZE',
        INVALID_ACCESS_CODE = 'INVALID_ACCESS_CODE',
        INVALID_MOBILE_NUMBER = 'INVALID_MOBILE_NUMBER',
        INVALID_MOBILE_NUMBER_SIZE = 'INVALID_MOBILE_NUMBER_SIZE',
        INVALID_NUMBER_PHONE = 'INVALID_NUMBER_PHONE',
        INVALID_NUMBER_PHONE_SIZE = 'INVALID_NUMBER_PHONE_SIZE',
        INVALID_UUID_PHONE = 'INVALID_UUID_PHONE',
        INVALID_UUID_PHONE_SIZE = 'INVALID_UUID_PHONE_SIZE',
        MANDATORY_PHONE_TYPE_MOBILE = 'MANDATORY_PHONE_TYPE_MOBILE',
        PERMISSION_NOT_FOUND = 'PERMISSION_NOT_FOUND',
        USER_ACCOUNT_ALREADY_EXISTS = 'USER_ACCOUNT_ALREADY_EXISTS',
        PARTNER_ALREADY_EXISTS = 'PARTNER_ALREADY_EXISTS',
        PARTNER_PREFIX_ALREADY_EXISTS = 'PARTNER_PREFIX_ALREADY_EXISTS',
        INVALID_ADDRESS_SIZE = 'INVALID_ADDRESS_SIZE',
        INVALID_ADDRESS = 'INVALID_ADDRESS',
        INVALID_ZIPCODE_SIZE = 'INVALID_ZIPCODE_SIZE',
        INVALID_ZIPCODE = 'INVALID_ZIPCODE',
        INVALID_STREET_SIZE = 'INVALID_STREET_SIZE',
        INVALID_STREET = 'INVALID_STREET',
        INVALID_NEIGHBORHOOD_SIZE = 'INVALID_NEIGHBORHOOD_SIZE',
        INVALID_NEIGHBORHOOD = 'INVALID_NEIGHBORHOOD',
        INVALID_STREET_NUMBER_SIZE = 'INVALID_STREET_NUMBER_SIZE',
        INVALID_STREET_NUMBER = 'INVALID_STREET_NUMBER',
        INVALID_DISTRICT_SIZE = 'INVALID_DISTRICT_SIZE',
        INVALID_DISTRICT = 'INVALID_DISTRICT',
        INVALID_CITY_SIZE = 'INVALID_CITY_SIZE',
        INVALID_CITY = 'INVALID_CITY',
        INVALID_STATE_SIZE = 'INVALID_STATE_SIZE',
        INVALID_STATE = 'INVALID_STATE',
        INVALID_COUNTRY_SIZE = 'INVALID_COUNTRY_SIZE',
        INVALID_COUNTRY = 'INVALID_COUNTRY',
        INVALID_LATITUDE_SIZE = 'INVALID_LATITUDE_SIZE',
        INVALID_LONGITUDE_SIZE = 'INVALID_LONGITUDE_SIZE',
        INVALID_LATITUDE = 'INVALID_LATITUDE',
        INVALID_LONGITUDE = 'INVALID_LONGITUDE',
        INVALID_BIRTHDATE = 'INVALID_BIRTHDATE',
        INVALID_MONTH = 'INVALID_MONTH',
        INVALID_OF_AGE = 'INVALID_OF_AGE',
        INVALID_SLUG = 'INVALID_SLUG',
        INVALID_COLOR = 'INVALID_COLOR',
        INVALID_POSITION = 'INVALID_POSITION',
        INVALID_ICON = 'INVALID_ICON',
        INVALID_IMAGE = 'INVALID_IMAGE',
        REQUIRED_PASSWORD = 'REQUIRED_PASSWORD',
        INTERNAL_ERROR = 'INTERNAL_ERROR',
        INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
        GENERATED_PAYMENT_INSTRUMENT_NOT_FOUND = 'GENERATED_PAYMENT_INSTRUMENT_NOT_FOUND',
        INVALID_THUMB_URL = 'INVALID_THUMB_URL',
        PAYMENT_INTEGRITY_VIOLATION = 'PAYMENT_INTEGRITY_VIOLATION',
        KEY_MANAGEMENT_ENCRYPTION_ERROR = 'KEY_MANAGEMENT_ENCRYPTION_ERROR',
        PAYMENT_NOT_FOUND = 'PAYMENT_NOT_FOUND',
        PAYMENT_INTERNAL_SERVER_ERROR = 'PAYMENT_INTERNAL_SERVER_ERROR',
        INVALID_PAYMENT_STATUS_REVERSAL = 'INVALID_PAYMENT_STATUS_REVERSAL',
        PAYMENT_PRE_AUTHORIZED_NOT_FOUND = 'PAYMENT_PRE_AUTHORIZED_NOT_FOUND',
        INVALID_SALES_CHANNEL = 'INVALID_SALES_CHANNEL',
        INVALID_PAYMENT_COMBINATION = 'INVALID_PAYMENT_COMBINATION',
        INVALID_PAYMENT_COMBINATION_ID = 'INVALID_PAYMENT_COMBINATION_ID',
        INVALID_ACCEPT_TERMS = 'INVALID_ACCEPT_TERMS',
        FUNDING_INSTRUMENT_ID_NOT_NULL = 'FUNDING_INSTRUMENT_ID_NOT_NULL',
        STORE_REQUEST_NOT_NULL = 'STORE_REQUEST_NOT_NULL',
        INVALID_STORE_IDS_NOT_NULL = 'INVALID_STORE_IDS_NOT_NULL',
        STORE_ID_NOT_NULL = 'STORE_ID_NOT_NULL',
        STORE_ID_NOT_AUTHORIZED = 'STORE_ID_NOT_AUTHORIZED',
        STORE_ID_SIZE = 'STORE_ID_SIZE',
        STORE_ACCOUNT_ALREADY_EXISTS = 'STORE_ACCOUNT_ALREADY_EXISTS',
        STORE_ACCOUNT_NOT_FOUND = 'STORE_ACCOUNT_NOT_FOUND',
        CUSTOMER_NOT_NULL = 'CUSTOMER_NOT_NULL',
        ORDER_CODE_NOT_EMPTY = 'ORDER_CODE_NOT_EMPTY',
        CUSTOMER_UUID_NOT_BLANCK = 'CUSTOMER_UUID_NOT_BLANCK',
        DOCUMENT_NOT_BLANK = 'DOCUMENT_NOT_BLANK',
        CUSTOMER_FULLNAME_NOT_BLANCK = 'CUSTOMER_FULLNAME_NOT_BLANCK',
        CUSTOMER_EMAIL_NOT_BLANCK = 'CUSTOMER_EMAIL_NOT_BLANCK',
        CUSTOMER_BIRTH_DATE_NOT_BLANCK = 'CUSTOMER_BIRTH_DATE_NOT_BLANCK',
        UNAUTHORIZED = 'UNAUTHORIZED',
        STORE_AUTHENTICATION_INTEGRITY_VIOLATION = 'STORE_AUTHENTICATION_INTEGRITY_VIOLATION',
        STORE_AUTHENTICATION_NOT_FOUND = 'STORE_AUTHENTICATION_NOT_FOUND',
        PAYMENT_METHOD_NOT_FOUND_EXCEPTION = 'PAYMENT_METHOD_NOT_FOUND_EXCEPTION',
        UNABLE_TO_CREATE_STORE_ACCOUNT = 'UNABLE_TO_CREATE_STORE_ACCOUNT',
        UNABLE_TO_VERIFY_STORE_ACCOUNT = 'UNABLE_TO_VERIFY_STORE_ACCOUNT',
        UNABLE_TO_APPROVE_STORE_ACCOUNT = 'UNABLE_TO_APPROVE_STORE_ACCOUNT',
        UNABLE_TO_ENRICH_STORE_ACCOUNT = 'UNABLE_TO_ENRICH_STORE_ACCOUNT',
        ZENDESK_CREATE_USER_ERROR = 'ZENDESK_CREATE_USER_ERROR',
        STORE_EMAIL_ALREADY_EXISTS = 'STORE_EMAIL_ALREADY_EXISTS',
        STORE_DOCUMENT_ALREADY_EXISTS = 'STORE_DOCUMENT_ALREADY_EXISTS',
        PAYMENT_REFUND_INTERNAL_SERVER_ERROR = 'PAYMENT_REFUND_INTERNAL_SERVER_ERROR',
        BANK_NOT_FOUND = 'BANK_NOT_FOUND',
        FIN_NOT_FOUND = 'FIN_NOT_FOUND',
        STATUS_NOT_FOUND = 'STATUS_NOT_FOUND',
        PHONE_NOT_FOUND = 'PHONE_NOT_FOUND',
        ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND',
        UNABLE_TO_FIND_ADDRESS = 'UNABLE_TO_FIND_ADDRESS',
        INVALID_STORE = 'INVALID_STORE',
        INVALID_STORE_IDS = 'INVALID_STORE_IDS',
        INVALID_YEAR_MOTH = 'INVALID_YEAR_MOTH',
        INVALID_TYPE_AMOUNT = 'INVALID_TYPE_AMOUNT',
        INVALID_TYPE_PERIOD = 'INVALID_TYPE_PERIOD',
        INVALID_TYPE_OFFSET = 'INVALID_TYPE_OFFSET',
        INVALID_TYPE_LIMIT = 'INVALID_TYPE_LIMIT',
        INVALID_PHOTO = 'INVALID_PHOTO',
        TRANSFER_MAX_REQUEST_MESSAGE = 'TRANSFER_MAX_REQUEST_MESSAGE',
        INVALID_ENUM_PAYMENT_STATUS = 'INVALID_ENUM_PAYMENT_STATUS',
        TRANSFER_MAX_REQUEST_USER = 'TRANSFER_MAX_REQUEST_USER',
        INVALID_TRANSFER_MAX_REQUEST_USER = 'INVALID_TRANSFER_MAX_REQUEST_USER',
        INVALID_TRANSFER_MAX_REQUEST_UUIDS = 'INVALID_TRANSFER_MAX_REQUEST_UUIDS',
        SHOPPING_NOT_FOUND = 'SHOPPING_NOT_FOUND',
        BANK_NUMBER_ALREADY_EXISTS = 'BANK_NUMBER_ALREADY_EXISTS',
        FIN_NUMBER_ALREADY_EXISTS = 'FIN_NUMBER_ALREADY_EXISTS',
        INVALID_BANK = 'INVALID_BANK',
        INVALID_FIN = 'INVALID_FIN',
        INVALID_GROUP = 'INVALID_GROUP',
        GROUP_NAME_ALREADY_EXISTS = 'GROUP_NAME_ALREADY_EXISTS',
        PERMISSION_NAME_ALREADY_EXISTS = 'PERMISSION_NAME_ALREADY_EXISTS',
        INVALID_QUANTITY = 'INVALID_QUANTITY',
        INVALID_PAGE = 'INVALID_PAGE',
        INVALID_INSTALLMENTS_AMOUNT = 'INVALID_INSTALLMENTS_AMOUNT',
        INVALID_START_DATE = 'INVALID_START_DATE',
        INVALID_END_DATE = 'INVALID_END_DATE',
        INVALID_VALUE = 'INVALID_VALUE',
        UNABLE_TO_RETRIEVE_CUSTOMER_BALANCE = 'UNABLE_TO_RETRIEVE_CUSTOMER_BALANCE',
        WALLET_NOT_FOUND = 'WALLET_NOT_FOUND',
        UNABLE_TO_RETRIEVE_OFFER_SERVICES = 'UNABLE_TO_RETRIEVE_OFFER_SERVICES',
        CUSTOMER_PASSWORD_ERROR = 'CUSTOMER_PASSWORD_ERROR',
        CUSTOMER_PASSWORD_VERIFICATION_ERROR = 'CUSTOMER_PASSWORD_VERIFICATION_ERROR',
        OPTIN_VALIDATION_ERROR = 'OPTIN_VALIDATION_ERROR',
        INVALID_FINGERPRINT_KEY = 'INVALID_FINGERPRINT_KEY',
        UNABLE_TO_SUBSCRIBE_MEMBER_ON_LIST = 'UNABLE_TO_SUBSCRIBE_MEMBER_ON_LIST',
        INVALID_STORE_OWNER_FIRST_NAME_NOT_UPDATE = 'INVALID_STORE_OWNER_FIRST_NAME_NOT_UPDATE',
        INVALID_STORE_OWNER_LAST_NAME_NOT_UPDATE = 'INVALID_STORE_OWNER_LAST_NAME_NOT_UPDATE',
        INVALID_STORE_OWNER_BIRTH_DATE_NOT_UPDATE = 'INVALID_STORE_OWNER_BIRTH_DATE_NOT_UPDATE',
        INVALID_STORE_OWNER_DOCUMENT_NOT_UPDATE = 'INVALID_STORE_OWNER_DOCUMENT_NOT_UPDATE',
        INVALID_STORE_OWNER_NOT_UPDATE = 'INVALID_STORE_OWNER_NOT_UPDATE',
        STORE_CREDENTIAL_EXCEPTION = 'STORE_CREDENTIAL_EXCEPTION',
        INVALID_SHOPPING_ID = 'INVALID_SHOPPING_ID',
        INVALID_UUID = 'INVALID_UUID',
        INVALID_BAR_CODE = 'INVALID_BAR_CODE',
        INTERRUPTED_EXCEPTION = 'INTERRUPTED_EXCEPTION',
        EXCECUTION_EXCEPTION = 'EXCECUTION_EXCEPTION',
        INVALID_STORE_TYPE = 'INVALID_STORE_TYPE',
        INVALID_ORDER_TYPE_STRATEGY = 'INVALID_ORDER_TYPE_STRATEGY',
        UNABLE_TO_CONFIRM_SERVICES_ORDER = 'UNABLE_TO_CONFIRM_SERVICES_ORDER',
        UNABLE_TO_REVERSAL_SERVICES_ORDER = 'UNABLE_TO_REVERSAL_SERVICES_ORDER',
        UNABLE_TO_CANCEL_SERVICES_ORDER = 'UNABLE_TO_CANCEL_SERVICES_ORDER',
        UNABLE_TO_REVERSAL_CANCELLATIOIN_SERVICES_ORDER = 'UNABLE_TO_REVERSAL_CANCELLATIOIN_SERVICES_ORDER',
        INVALID_DAY_OF_WEEK = 'INVALID_DAY_OF_WEEK',
        RECHARGE_CARRIERS_NOT_FOUND = 'RECHARGE_CARRIERS_NOT_FOUND',
        RECHARGE_CARRIER_AMOUNTS_NOT_FOUND = 'RECHARGE_CARRIER_AMOUNTS_NOT_FOUND',
        GAME_PLATFORM_NOT_FOUND = 'GAME_PLATFORM_NOT_FOUND',
        GAME_PLATFORM_AMOUNT = 'GAME_PLATFORM_AMOUNT',
        PAYMENT_INVALID_BAR_CODE = 'PAYMENT_INVALID_BAR_CODE',
        PAYMENT_ALREADY_PAYED = 'PAYMENT_ALREADY_PAYED',
        INVALID_NUMBER = 'INVALID_NUMBER',
        INVALID_COMPLEMENT = 'INVALID_COMPLEMENT',
        INVALID_COMPLEMENT_SIZE = 'INVALID_COMPLEMENT_SIZE',
        INVALID_STORE_ABOUT = 'INVALID_STORE_ABOUT',
        INVALID_STORE_ABOUT_SIZE = 'INVALID_STORE_ABOUT_SIZE',
        INVALID_RULES = 'INVALID_RULES',
        INVALID_RULES_SIZE = 'INVALID_RULES_SIZE',
        DEVICE_ALREADY_CONFIRMED = 'DEVICE_ALREADY_CONFIRMED',
        INVALID_FIELDS = 'INVALID_FIELDS',
        DEVICE_ATTEMPTS_EXCEEDED = 'DEVICE_ATTEMPTS_EXCEEDED',
        INVALID_REDIRECT_URL = 'INVALID_REDIRECT_URL',
        INVALID_EXTERNAL_ORDER_ID = 'INVALID_EXTERNAL_ORDER_ID',
        INVALID_CURRENCY = 'INVALID_CURRENCY',
        PURCHASE_ORDER_NOT_FOUND = 'PURCHASE_ORDER_NOT_FOUND',
        UNABLE_TO_UPDATE_PURCHASE_ORDER = 'UNABLE_TO_UPDATE_PURCHASE_ORDER',
        PHONE_NOT_NULL = 'PHONE_NOT_NULL',
        TOKEN_NOT_BLANK = 'TOKEN_NOT_BLANK',
        INVALID_ORDERBY_TYPE = 'INVALID_ORDERBY_TYPE',
        VOUCHER_CODE_NOT_FOUND = 'VOUCHER_CODE_NOT_FOUND',
        VOUCHER_CODE_EXPIRED = 'VOUCHER_CODE_EXPIRED',
        INVALID_FILE = 'INVALID_FILE',
        INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
        INVALID_FILE_EXCEEDED_SIZE = 'INVALID_FILE_EXCEEDED_SIZE',
        INVALID_SMS_TOKEN = 'INVALID_SMS_TOKEN',
        INVALID_STORE_ID = 'INVALID_STORE_ID',
        DUPLICATED_VOUCHER_VALUES = 'DUPLICATED_VOUCHER_VALUES',
        CASHBACK_BATCH_NO_PURCHASES = 'CASHBACK_BATCH_NO_PURCHASES',
        CASHBACK_BATCH_EXCEPTION = 'CASHBACK_BATCH_EXCEPTION',
        CASHBACK_DOWNLOAD_BATCH_ERROR = 'CASHBACK_DOWNLOAD_BATCH_ERROR',
        CASHBACK_BATCH_NOT_FOUND = 'CASHBACK_BATCH_NOT_FOUND',
        CASHBACK_BATCH_INVALID_STATUS_FOR_BANK_SLIP_GEN = 'CASHBACK_BATCH_INVALID_STATUS_FOR_BANK_SLIP_GEN',
        TRANSACTION_NOT_FOUND = 'TRANSACTION_NOT_FOUND',
        EXTERNAL_TRANSACTION_ID_NOT_BLANK_INFORMATION = 'EXTERNAL_TRANSACTION_ID_NOT_BLANK_INFORMATION',
        STATUS_NOT_BLANK_INFORMATION = 'STATUS_NOT_BLANK_INFORMATION',
        METHOD_TYPE_NOT_NULL_INFORMATION = 'METHOD_TYPE_NOT_NULL_INFORMATION',
        EVENT_NOT_BLANK_INFORMATION = 'EVENT_NOT_BLANK_INFORMATION',
        EVENT_CREATED_AT_NOT_BLANK_INFORMATION = 'EVENT_CREATED_AT_NOT_BLANK_INFORMATION',
        TRANSACTION_TYPE_NOT_NULL_INFORMATION = 'TRANSACTION_TYPE_NOT_NULL_INFORMATION',
        CUSTOMER_ACCOUNT_NOT_FOUND_EXCEPTION = 'CUSTOMER_ACCOUNT_NOT_FOUND_EXCEPTION',
        TRANSFER_FUND_DTO_NOT_NULL = 'TRANSFER_FUND_DTO_NOT_NULL',
        TRANSFER_FUND_ORIGIN_NOT_NULL = 'TRANSFER_FUND_ORIGIN_NOT_NULL',
        TRANSFER_FUND_NOT_FOUND = 'TRANSFER_FUND_NOT_FOUND',
        TRANSFER_FUND_STRATEGY = 'TRANSFER_FUND_STRATEGY',
        ORIGIN_ID_NOT_BLANK = 'ORIGIN_ID_NOT_BLANK',
        RECEIVERS_NOT_EMPTY = 'RECEIVERS_NOT_EMPTY',
        CUSTOMER_ID_NOT_BLANK = 'CUSTOMER_ID_NOT_BLANK',
        RECEIVER_VALUE_NOT_NULL = 'RECEIVER_VALUE_NOT_NULL',
        INVALID_BACKGROUND_URL = 'INVALID_BACKGROUND_URL',
        INVALID_RECEIVER_VALUE = 'INVALID_RECEIVER_VALUE',
        INVALID_MEDIA_TYPE = 'INVALID_MEDIA_TYPE',
        PAYMENT_READ_TIME_OUT_ERROR = 'PAYMENT_READ_TIME_OUT_ERROR',
        KMV_ADAPTER_TIME_OUT_ERROR = 'KMV_ADAPTER_TIME_OUT_ERROR',
        NSU_EXTERNAL_NOT_FOUND_ERROR = 'NSU_EXTERNAL_NOT_FOUND_ERROR',
        NSU_EXTERNAL_EXISTS_ERROR = 'NSU_EXTERNAL_EXISTS_ERROR',
        NSU_EXTERNAL_SIZE_MAX = 'NSU_EXTERNAL_SIZE_MAX',
        PAYMENT_EXIST_PENDENCY = 'PAYMENT_EXIST_PENDENCY',
        PAYMENT_UNAUTHORIZED_RECEIPT = 'PAYMENT_UNAUTHORIZED_RECEIPT',
        OWN_ID_ALREADY_EXIST = 'OWN_ID_ALREADY_EXIST',
        PDV_TOKEN_ALREADY_EXIST = 'PDV_TOKEN_ALREADY_EXIST',
        UNABLE_TO_UPDATE_CUSTOMER_ACCOUNT = 'UNABLE_TO_UPDATE_CUSTOMER_ACCOUNT',
        INVALID_CUSTOMER_ATTACHMENT_MAX = 'INVALID_CUSTOMER_ATTACHMENT_MAX',
        INVALID_FIRST_ACCESS_TOKEN = 'INVALID_FIRST_ACCESS_TOKEN',
        PASSWORD_MISMATCH = 'PASSWORD_MISMATCH',
        PASSWORD_MATCH = 'PASSWORD_MATCH',
        INVALID_PAYER_DOCUMENT_SIZE = 'INVALID_PAYER_DOCUMENT_SIZE',
        INVALID_CREDIT_CARD_CUSTOMER_ID_NOT_BLANK = 'INVALID_CREDIT_CARD_CUSTOMER_ID_NOT_BLANK',
        INVALID_CREDIT_CARD_NAME_ON_CARD_NOT_BLANK = 'INVALID_CREDIT_CARD_NAME_ON_CARD_NOT_BLANK',
        INVALID_CREDIT_CARD_DOCUMENT_NOT_BLANK = 'INVALID_CREDIT_CARD_DOCUMENT_NOT_BLANK',
        INVALID_CREDIT_CARD_NUMBER = 'INVALID_CREDIT_CARD_NUMBER',
        INVALID_CREDIT_CARD_NUMBER_NOT_BLANK = 'INVALID_CREDIT_CARD_NUMBER_NOT_BLANK',
        INVALID_CREDIT_CARD_BRAND_NOT_BLANK = 'INVALID_CREDIT_CARD_BRAND_NOT_BLANK',
        INVALID_CREDIT_CARD_KEY_NOT_BLANK = 'INVALID_CREDIT_CARD_KEY_NOT_BLANK',
        INVALID_CREDIT_CARD_KEY = 'INVALID_CREDIT_CARD_KEY',
        INVALID_CREDIT_CARD_KEY_SIZE = 'INVALID_CREDIT_CARD_KEY_SIZE',
        INVALID_CREDIT_CARD_EXPIRATION_DATE = 'INVALID_CREDIT_CARD_EXPIRATION_DATE',
        CREDIT_CARD_EXPIRATION_DATE_NOT_BLANK = 'CREDIT_CARD_EXPIRATION_DATE_NOT_BLANK',
        INVALID_CREDIT_CARD_BILLING_ADDRESS_NOT_NULL = 'INVALID_CREDIT_CARD_BILLING_ADDRESS_NOT_NULL',
        BILLING_ADDRESS_CUSTOMER_ID_NOT_BLANK = 'BILLING_ADDRESS_CUSTOMER_ID_NOT_BLANK',
        CREDIT_CARD_NOT_FOUND = 'CREDIT_CARD_NOT_FOUND',
        CREDIT_CARD_PERMISSION_DENIED = 'CREDIT_CARD_PERMISSION_DENIED',
        UNABLE_TO_EXCLUDE_PRINCIPAL_CREDIT_CARD = 'UNABLE_TO_EXCLUDE_PRINCIPAL_CREDIT_CARD',
        CREDIT_CARD_UNABLE_VALIDATION = 'CREDIT_CARD_UNABLE_VALIDATION',
        CREDIT_CARD_VALIDATION_MAX_ATTEMPTS_EXCEEDED = 'CREDIT_CARD_VALIDATION_MAX_ATTEMPTS_EXCEEDED',
        CREDIT_CARD_VALIDATION_INVALID_ATTEMPT = 'CREDIT_CARD_VALIDATION_INVALID_ATTEMPT',
        CREDIT_CARD_ALREADY_EXCLUDED = 'CREDIT_CARD_ALREADY_EXCLUDED',
        INVALID_CREDIT_CARD_TYPE = 'INVALID_CREDIT_CARD_TYPE',
        INVALID_CREDIT_CARD_TYPE_NOT_NULL = 'INVALID_CREDIT_CARD_TYPE_NOT_NULL',
        DEBIT_CARD_UNABLE_VALIDATION = 'DEBIT_CARD_UNABLE_VALIDATION',
        INVALID_CREDIT_CARD_NUMBER_EXCEEDED_SIZE = 'INVALID_CREDIT_CARD_NUMBER_EXCEEDED_SIZE',
        INVALID_CREDIT_CARD_BRAND_EXCEEDED_SIZE = 'INVALID_CREDIT_CARD_BRAND_EXCEEDED_SIZE',
        INVALID_CREDIT_CARD_BRAND = 'INVALID_CREDIT_CARD_BRAND',
        INVALID_CREDIT_CARD_NAME_EXCEEDED_SIZE = 'INVALID_CREDIT_CARD_NAME_EXCEEDED_SIZE',
        INVALID_CREDIT_CARD_DOCUMENT_EXCEEDED_SIZE = 'INVALID_CREDIT_CARD_DOCUMENT_EXCEEDED_SIZE',
        INVALID_CREDIT_CARD_NICKNAME_EXCEEDED_SIZE = 'INVALID_CREDIT_CARD_NICKNAME_EXCEEDED_SIZE',
        INVALID_BILLING_ADDRESS_NUMBER_EXCEEDED_SIZE = 'INVALID_BILLING_ADDRESS_NUMBER_EXCEEDED_SIZE',
        INVALID_CREDIT_CARD_TOKEN_EXCEEDED_SIZE = 'INVALID_CREDIT_CARD_TOKEN_EXCEEDED_SIZE',
        INVALID_BENEFIT = 'INVALID_BENEFIT',
        INVALID_BENEFIT_UUID = 'INVALID_BENEFIT_UUID',
        INVALID_BENEFIT_NAME = 'INVALID_BENEFIT_NAME',
        INVALID_BENEFIT_NAME_SIZE = 'INVALID_BENEFIT_NAME_SIZE',
        INVALID_BENEFIT_DESCRIPTION = 'INVALID_BENEFIT_DESCRIPTION',
        INVALID_BENEFIT_DESCRIPTION_SIZE = 'INVALID_BENEFIT_DESCRIPTION_SIZE',
        INVALID_BENEFIT_REWARD_TYPE = 'INVALID_BENEFIT_REWARD_TYPE',
        INVALID_BENEFIT_REWARD_TYPE_TO_BENEFIT_TYPE = 'INVALID_BENEFIT_REWARD_TYPE_TO_BENEFIT_TYPE',
        INVALID_BENEFIT_REWARD_CASHBACK = 'INVALID_BENEFIT_REWARD_CASHBACK',
        INVALID_BENEFIT_REWARD_OPERATION_TYPE = 'INVALID_BENEFIT_REWARD_OPERATION_TYPE',
        INVALID_BENEFIT_REWARD_AFTER_DAYS = 'INVALID_BENEFIT_REWARD_AFTER_DAYS',
        INVALID_BENEFIT_REWARD_AFTER_DAYS_MIN = 'INVALID_BENEFIT_REWARD_AFTER_DAYS_MIN',
        INVALID_BENEFIT_REWARD_AFTER_DAYS_MAX = 'INVALID_BENEFIT_REWARD_AFTER_DAYS_MAX',
        INVALID_BENEFIT_TYPE = 'INVALID_BENEFIT_TYPE',
        INVALID_TRANSFER_TYPE = 'INVALID_TRANSFER_TYPE',
        INVALID_OPERATION_TYPE = 'INVALID_OPERATION_TYPE',
        INVALID_MIN_PURCHASE_VALUE = 'INVALID_MIN_PURCHASE_VALUE',
        INVALID_MAX_PURCHASE_VALUE = 'INVALID_MAX_PURCHASE_VALUE',
        INVALID_KMV_QUANTITY = 'INVALID_KMV_QUANTITY',
        INVALID_KMV_PRODUCT_ID = 'INVALID_KMV_PRODUCT_ID',
        INVALID_BENEFIT_STATUS = 'INVALID_BENEFIT_STATUS',
        BENEFIT_RULE_NOT_FOUND = 'BENEFIT_RULE_NOT_FOUND',
        INVALID_BENEFIT_STATUS_CHANGE = 'INVALID_BENEFIT_STATUS_CHANGE',
        BENEFIT_NOT_IN_DRAFT_STATUS = 'BENEFIT_NOT_IN_DRAFT_STATUS',
        BENEFIT_RULE_INVALID_DATE = 'BENEFIT_RULE_INVALID_DATE',
        BENEFIT_RULE_INVALID_PAST_DATE = 'BENEFIT_RULE_INVALID_PAST_DATE',
        BENEFIT_PRODUCT_RULE_EMPTY = 'BENEFIT_PRODUCT_RULE_EMPTY',
        BENEFIT_LIMITER_CASHBACK_ABOVE_ALLOWED = 'BENEFIT_LIMITER_CASHBACK_ABOVE_ALLOWED',
        BENEFIT_LIMITER_CASHBACK_NOT_FOUND = 'BENEFIT_LIMITER_CASHBACK_NOT_FOUND',
        BENEFIT_LIST_NOT_NULL = 'BENEFIT_LIST_NOT_NULL',
        BENEFIT_RULE_ALREADY_EXISTING = 'BENEFIT_RULE_ALREADY_EXISTING',
        INVALID_STORE_OWNER_ID = 'INVALID_STORE_OWNER_ID',
        INVALID_STORE_OWNER_FIRST_NAME = 'INVALID_STORE_OWNER_FIRST_NAME',
        INVALID_STORE_OWNER_FIRST_NAME_SIZE = 'INVALID_STORE_OWNER_FIRST_NAME_SIZE',
        INVALID_STORE_OWNER_LAST_NAME = 'INVALID_STORE_OWNER_LAST_NAME',
        INVALID_STORE_OWNER_LAST_NAME_SIZE = 'INVALID_STORE_OWNER_LAST_NAME_SIZE',
        INVALID_STORE_OWNER_BIRTH_DATE = 'INVALID_STORE_OWNER_BIRTH_DATE',
        INVALID_STORE_OWNER_DOCUMENT = 'INVALID_STORE_OWNER_DOCUMENT',
        INVALID_STORE_OWNER_NOT_AUTHORIZED_UPDATE = 'INVALID_STORE_OWNER_NOT_AUTHORIZED_UPDATE',
        INVALID_STORE_FANTASY_NAME = 'INVALID_STORE_FANTASY_NAME',
        INVALID_STORE_FANTASY_NOT_UPDATE = 'INVALID_STORE_FANTASY_NOT_UPDATE',
        INVALID_STORE_FANTASY_NAME_SIZE = 'INVALID_STORE_FANTASY_NAME_SIZE',
        INVALID_EXPIRATION_DATE = 'INVALID_EXPIRATION_DATE',
        BANNER_NOT_FOUND = 'BANNER_NOT_FOUND',
        PASSWORD_AUTHENTICATION_MAXIMUM_ATTEMPTS = 'PASSWORD_AUTHENTICATION_MAXIMUM_ATTEMPTS',
        CUSTOMER_PASSWORD_VALIDATION_EXPIRED = 'CUSTOMER_PASSWORD_VALIDATION_EXPIRED',
        INVALID_MAX_NSU_SIZE = 'INVALID_MAX_NSU_SIZE',
        VOUCHER_VALUE_IS_NOT_NULL = 'VOUCHER_VALUE_IS_NOT_NULL',
        INVALID_MIN_TAXI_VOUCHER_VALUE = 'INVALID_MIN_TAXI_VOUCHER_VALUE',
        INVALID_MAX_TAXI_VOUCHER_VALUE = 'INVALID_MAX_TAXI_VOUCHER_VALUE',
        PIN_VOUCHER_UBER_ERROR = 'PIN_VOUCHER_UBER_ERROR',
        VOUCHER_VALUE_IS_NUMBER = 'VOUCHER_VALUE_IS_NUMBER',
        PIN_VOUCHER_UBER_ERROR_IN_CANCEL = 'PIN_VOUCHER_UBER_ERROR_IN_CANCEL',
        VOUCHER_UBER_NOT_FOUND = 'VOUCHER_UBER_NOT_FOUND',
        VOUCHER_UBER_NOT_UNAVAILABLE = 'VOUCHER_UBER_NOT_UNAVAILABLE',
        VOUCHER_UBER_ORDER_NOT_FOUND = 'VOUCHER_UBER_ORDER_NOT_FOUND',
        VOUCHER_UBER_ORDER_ALREADY_CANCELED = 'VOUCHER_UBER_ORDER_ALREADY_CANCELED',
        VOUCHER_UBER_UNABLE_TO_EXPIRE_CANCEL_ORDER = 'VOUCHER_UBER_UNABLE_TO_EXPIRE_CANCEL_ORDER',
        INVALID_PAYMENT_REFERENCE = 'INVALID_PAYMENT_REFERENCE',
        INVALID_PAYMENT_TRANSACTION_ID = 'INVALID_PAYMENT_TRANSACTION_ID',
        INVALID_PAYMENT_PAYMENT_ID = 'INVALID_PAYMENT_PAYMENT_ID',
        INVALID_PAYMENT_MERCHANT_NAME = 'INVALID_PAYMENT_MERCHANT_NAME',
        INVALID_PAYMENT_VALUE = 'INVALID_PAYMENT_VALUE',
        INVALID_PAYMENT_INSTALLMENTS = 'INVALID_PAYMENT_INSTALLMENTS',
        INVALID_PAYMENT_MINI_CART = 'INVALID_PAYMENT_MINI_CART',
        INVALID_MINI_CART_BUYER = 'INVALID_MINI_CART_BUYER',
        INVALID_BUYER_FIRST_NAME = 'INVALID_BUYER_FIRST_NAME',
        INVALID_BUYER_LAST_NAME = 'INVALID_BUYER_LAST_NAME',
        INVALID_BUYER_DOCUMENT_TYPE = 'INVALID_BUYER_DOCUMENT_TYPE',
        INVALID_BUYER_DOCUMENT = 'INVALID_BUYER_DOCUMENT',
        INVALID_SHIPPING_ADDRESS_COUNTRY = 'INVALID_SHIPPING_ADDRESS_COUNTRY',
        INVALID_SHIPPING_ADDRESS_STREET = 'INVALID_SHIPPING_ADDRESS_STREET',
        INVALID_SHIPPING_ADDRESS_NUMBER = 'INVALID_SHIPPING_ADDRESS_NUMBER',
        INVALID_SHIPPING_ADDRESS_NEIGHBORHOOD = 'INVALID_SHIPPING_ADDRESS_NEIGHBORHOOD',
        INVALID_SHIPPING_ADDRESS_POSTALCODE = 'INVALID_SHIPPING_ADDRESS_POSTALCODE',
        INVALID_SHIPPING_ADDRESS_CITY = 'INVALID_SHIPPING_ADDRESS_CITY',
        INVALID_SHIPPING_ADDRESS_STATE = 'INVALID_SHIPPING_ADDRESS_STATE',
        INVALID_BILLING_ADDRESS_COUNTRY = 'INVALID_BILLING_ADDRESS_COUNTRY',
        INVALID_BILLING_ADDRESS_STREET = 'INVALID_BILLING_ADDRESS_STREET',
        INVALID_BILLING_ADDRESS_NUMBER = 'INVALID_BILLING_ADDRESS_NUMBER',
        INVALID_BILLING_ADDRESS_NEIGHBORHOOD = 'INVALID_BILLING_ADDRESS_NEIGHBORHOOD',
        INVALID_BILLING_ADDRESS_POSTALCODE = 'INVALID_BILLING_ADDRESS_POSTALCODE',
        INVALID_BILLING_ADDRESS_CITY = 'INVALID_BILLING_ADDRESS_CITY',
        INVALID_BILLING_ADDRESS_STATE = 'INVALID_BILLING_ADDRESS_STATE',
        INVALID_PAYMENT_CALLBACK_URL = 'INVALID_PAYMENT_CALLBACK_URL',
        INSUFFICIENT_AUTHORITIES = 'INSUFFICIENT_AUTHORITIES',
        UNABLE_TO_RETRIEVE_BALANCE = 'UNABLE_TO_RETRIEVE_BALANCE',
        PIN_OR_FINGERPRINT_KEY_IS_REQUIRED = 'PIN_OR_FINGERPRINT_KEY_IS_REQUIRED',
        UNABLE_TO_GENERATE_QRCODE = 'UNABLE_TO_GENERATE_QRCODE',
        INVALID_INTEGRATOR = 'INVALID_INTEGRATOR',
        PRE_AUTHORIZATION_ORDER_ERROR = 'PRE_AUTHORIZATION_ORDER_ERROR',
        COMBINATION_ID_NOT_NULL_INFORMATION = 'COMBINATION_ID_NOT_NULL_INFORMATION',
        INSTALLMENT_NOT_NULL_INFORMATION = 'INSTALLMENT_NOT_NULL_INFORMATION',
        INVALID_STATUS_INSTALLMENT = 'INVALID_STATUS_INSTALLMENT',
        CONCILIATION_FILE_NOT_FOUND = 'CONCILIATION_FILE_NOT_FOUND',
        S3_DOWNLOAD_ERROR = 'S3_DOWNLOAD_ERROR',
        OPTIN_NOT_FOUND = 'OPTIN_NOT_FOUND',
        INVALID_ORDER_ITENS = 'INVALID_ORDER_ITENS',
        INVALID_ENUM_GENDER = 'INVALID_ENUM_GENDER',
        INVALID_ENUM_PHONE_TYPE = 'INVALID_ENUM_PHONE_TYPE',
        INVALID_ENUM_PHONE_TYPE_SIZE = 'INVALID_ENUM_PHONE_TYPE_SIZE',
        INVALID_CATEGORY_UUID_SIZE = 'INVALID_CATEGORY_UUID_SIZE',
        INVALID_CATEGORY_UUID = 'INVALID_CATEGORY_UUID',
        INVALID_CATEGORY_NAME_SIZE = 'INVALID_CATEGORY_NAME_SIZE',
        INVALID_CATEGORY_NAME = 'INVALID_CATEGORY_NAME',
        INVALID_CATEGORY_SLUG_SIZE = 'INVALID_CATEGORY_SLUG_SIZE',
        INVALID_CATEGORY_SLUG = 'INVALID_CATEGORY_SLUG',
        INVALID_CATEGORY_COLOR_SIZE = 'INVALID_CATEGORY_COLOR_SIZE',
        INVALID_CATEGORY_COLOR = 'INVALID_CATEGORY_COLOR',
        INVALID_CATEGORY_FONT_COLOR_SIZE = 'INVALID_CATEGORY_FONT_COLOR_SIZE',
        INVALID_CATEGORY_FONT_COLOR = 'INVALID_CATEGORY_FONT_COLOR',
        INVALID_CATEGORY_POSITION = 'INVALID_CATEGORY_POSITION',
        INVALID_CATEGORY_ICON = 'INVALID_CATEGORY_ICON',
        INVALID_CATEGORY_BACKGROUND = 'INVALID_CATEGORY_BACKGROUND',
        INVALID_CATEGORY_FEE = 'INVALID_CATEGORY_FEE',
        INVALID_MAX_CATEGORY_FEE = 'INVALID_MAX_CATEGORY_FEE',
        INVALID_BANK_UUID_SIZE = 'INVALID_BANK_UUID_SIZE',
        INVALID_BANK_UUID = 'INVALID_BANK_UUID',
        INVALID_BANK_NUMBER_FORMAT = 'INVALID_BANK_NUMBER_FORMAT',
        INVALID_BANK_NUMBER = 'INVALID_BANK_NUMBER',
        INVALID_BANK_NAME_SIZE = 'INVALID_BANK_NAME_SIZE',
        INVALID_BANK_NAME = 'INVALID_BANK_NAME',
        INVALID_BANK_INITIALS_SIZE = 'INVALID_BANK_INITIALS_SIZE',
        INVALID_BANK_INITIALS = 'INVALID_BANK_INITIALS',
        INVALID_BANK_ISPB = 'INVALID_BANK_ISPB',
        INVALID_FINCANCIAL_UUID_SIZE = 'INVALID_FINCANCIAL_UUID_SIZE',
        INVALID_FINANCIAL_UUID = 'INVALID_FINANCIAL_UUID',
        INVALID_FINANCIAL_NUMBER_SIZE = 'INVALID_FINANCIAL_NUMBER_SIZE',
        INVALID_FINANCIAL_NUMBER = 'INVALID_FINANCIAL_NUMBER',
        INVALID_FINANCIAL_NAME_SIZE = 'INVALID_FINANCIAL_NAME_SIZE',
        INVALID_FINANCIAL_NAME = 'INVALID_FINANCIAL_NAME',
        INVALID_FINANCIAL_ISPB_SIZE = 'INVALID_FINANCIAL_ISPB_SIZE',
        INVALID_FINANCIAL_ISPB = 'INVALID_FINANCIAL_ISPB',
        INVALID_FINANCIAL_SHORTNAME_SIZE = 'INVALID_FINANCIAL_SHORTNAME_SIZE',
        INVALID_FINANCIAL_SHORTNAME = 'INVALID_FINANCIAL_SHORTNAME',
        INVALID_FINANCIAL_LONGNAME_SIZE = 'INVALID_FINANCIAL_LONGNAME_SIZE',
        INVALID_FINANCIAL_LONGNAME = 'INVALID_FINANCIAL_LONGNAME',
        INVALID_FINANCIAL_MAINACCESS_SIZE = 'INVALID_FINANCIAL_MAINACCESS_SIZE',
        INVALID_FINANCIAL_OPERATIONDATE_SIZE = 'INVALID_FINANCIAL_OPERATIONDATE_SIZE',
        INVALID_FINANCIAL_MAINACCESS = 'INVALID_FINANCIAL_MAINACCESS',
        INVALID_PAYMENT_METHOD_NAME_SIZE = 'INVALID_PAYMENT_METHOD_NAME_SIZE',
        INVALID_PAYMENT_METHOD_NAME = 'INVALID_PAYMENT_METHOD_NAME',
        INVALID_PAYMENT_METHOD_COMMISSION_PERCENTAGE = 'INVALID_PAYMENT_METHOD_COMMISSION_PERCENTAGE',
        INVALID_PAYMENT_METHOD_INSTALLMENT_COUNT = 'INVALID_PAYMENT_METHOD_INSTALLMENT_COUNT',
        INVALID_PAYMENT_METHOD_MINIMUM_INSTALLMENT = 'INVALID_PAYMENT_METHOD_MINIMUM_INSTALLMENT',
        INVALID_PAYMENT_METHOD_UUID_SIZE = 'INVALID_PAYMENT_METHOD_UUID_SIZE',
        INVALID_PAYMENT_METHOD_UUID = 'INVALID_PAYMENT_METHOD_UUID',
        INVALID_PAYMENT_METHOD_PHOTO_SIZE = 'INVALID_PAYMENT_METHOD_PHOTO_SIZE',
        INVALID_PAYMENT_METHOD_PHOTO = 'INVALID_PAYMENT_METHOD_PHOTO',
        INVALID_PAYMENT_METHOD_DESCRIPTION_SIZE = 'INVALID_PAYMENT_METHOD_DESCRIPTION_SIZE',
        INVALID_PAYMENT_METHOD_DESCRIPTION = 'INVALID_PAYMENT_METHOD_DESCRIPTION',
        INVALID_PAYMENT_METHOD_ACTIVE = 'INVALID_PAYMENT_METHOD_ACTIVE',
        INVALID_PAYMENT_METHOD_SPLIT = 'INVALID_PAYMENT_METHOD_SPLIT',
        INVALID_PAYMENT_METHOD_SPLIT_ID = 'INVALID_PAYMENT_METHOD_SPLIT_ID',
        INVALID_PAYMENT_METHOD_SPLIT_WEEKDAY_SIZE = 'INVALID_PAYMENT_METHOD_SPLIT_WEEKDAY_SIZE',
        INVALID_PAYMENT_METHOD_SPLIT_WEEKDAY = 'INVALID_PAYMENT_METHOD_SPLIT_WEEKDAY',
        INVALID_PAYMENT_METHOD_SPLIT_WEEKDAY_START_SIZE = 'INVALID_PAYMENT_METHOD_SPLIT_WEEKDAY_START_SIZE',
        INVALID_PAYMENT_METHOD_SPLIT_WEEKDAY_START = 'INVALID_PAYMENT_METHOD_SPLIT_WEEKDAY_START',
        INVALID_PAYMENT_METHOD_SPLIT_WEEKDAY_END_SIZE = 'INVALID_PAYMENT_METHOD_SPLIT_WEEKDAY_END_SIZE',
        INVALID_PAYMENT_METHOD_SPLIT_WEEKDAY_END = 'INVALID_PAYMENT_METHOD_SPLIT_WEEKDAY_END',
        PAYMENT_METHOD_SPLIT_NOT_FOUND = 'PAYMENT_METHOD_SPLIT_NOT_FOUND',
        INVALID_OPTIN_UUID_SIZE = 'INVALID_OPTIN_UUID_SIZE',
        INVALID_OPTIN_UUID = 'INVALID_OPTIN_UUID',
        INVALID_OPTIN_TEXT_SIZE = 'INVALID_OPTIN_TEXT_SIZE',
        INVALID_OPTIN_TEXT = 'INVALID_OPTIN_TEXT',
        INVALID_OPTIN_ACTIVE = 'INVALID_OPTIN_ACTIVE',
        INVALID_OPTIN_TYPE_SIZE = 'INVALID_OPTIN_TYPE_SIZE',
        INVALID_OPTIN_TYPE = 'INVALID_OPTIN_TYPE',
        INVALID_OPTIN_TYPE_ENUM = 'INVALID_OPTIN_TYPE_ENUM',
        INVALID_CUSTOMER_OPTIN_ID_SIZE = 'INVALID_CUSTOMER_OPTIN_ID_SIZE',
        INVALID_CUSTOMER_OPTIN_ID = 'INVALID_CUSTOMER_OPTIN_ID',
        INVALID_CUSTOMER_OPTIN_ACCEPTANCE = 'INVALID_CUSTOMER_OPTIN_ACCEPTANCE',
        INVALID_CUSTOMER_OPTIN_DEVICE_SIZE = 'INVALID_CUSTOMER_OPTIN_DEVICE_SIZE',
        INVALID_CUSTOMER_OPTIN_DEVICE = 'INVALID_CUSTOMER_OPTIN_DEVICE',
        INVALID_CUSTOMER_OPTIN_IP_SIZE = 'INVALID_CUSTOMER_OPTIN_IP_SIZE',
        INVALID_CUSTOMER_OPTIN_IP = 'INVALID_CUSTOMER_OPTIN_IP',
        INVALID_CUSTOMER_CAR_LICENCE_PLATE_SIZE = 'INVALID_CUSTOMER_CAR_LICENCE_PLATE_SIZE',
        INVALID_CUSTOMER_CAR_TYPE_SIZE = 'INVALID_CUSTOMER_CAR_TYPE_SIZE',
        INVALID_CUSTOMER_CAR_FACTORY_SIZE = 'INVALID_CUSTOMER_CAR_FACTORY_SIZE',
        INVALID_CUSTOMER_CAR_MODEL_SIZE = 'INVALID_CUSTOMER_CAR_MODEL_SIZE',
        INVALID_CUSTOMER_CAR_YEAR_SIZE = 'INVALID_CUSTOMER_CAR_YEAR_SIZE',
        INVALID_CUSTOMER_CAR_ENGINE_SIZE = 'INVALID_CUSTOMER_CAR_ENGINE_SIZE',
        INVALID_CUSTOMER_CAR_FUEL_SIZE = 'INVALID_CUSTOMER_CAR_FUEL_SIZE',
        INVALID_CUSTOMER_CAR_RENAVAM_SIZE = 'INVALID_CUSTOMER_CAR_RENAVAM_SIZE',
        INVALID_CUSTOMER_UUID_SIZE = 'INVALID_CUSTOMER_UUID_SIZE',
        INVALID_CUSTOMER_UUID = 'INVALID_CUSTOMER_UUID',
        INVALID_CUSTOMER_OWNID_SIZE = 'INVALID_CUSTOMER_OWNID_SIZE',
        INVALID_CUSTOMER_OWNID = 'INVALID_CUSTOMER_OWNID',
        INVALID_CUSTOMER_DOCUMENT_SIZE = 'INVALID_CUSTOMER_DOCUMENT_SIZE',
        INVALID_CUSTOMER_DOCUMENT = 'INVALID_CUSTOMER_DOCUMENT',
        INVALID_CUSTOMER_NAME = 'INVALID_CUSTOMER_NAME',
        INVALID_CUSTOMER_FIRST_NAME_SIZE = 'INVALID_CUSTOMER_FIRST_NAME_SIZE',
        INVALID_CUSTOMER_FIRST_NAME = 'INVALID_CUSTOMER_FIRST_NAME',
        INVALID_CUSTOMER_LAST_NAME_SIZE = 'INVALID_CUSTOMER_LAST_NAME_SIZE',
        INVALID_CUSTOMER_LAST_NAME = 'INVALID_CUSTOMER_LAST_NAME',
        INVALID_CUSTOMER_EMAIL_SIZE = 'INVALID_CUSTOMER_EMAIL_SIZE',
        INVALID_CUSTOMER_EMAIL = 'INVALID_CUSTOMER_EMAIL',
        INVALID_CUSTOMER_BIRTH_DATE_SIZE = 'INVALID_CUSTOMER_BIRTH_DATE_SIZE',
        INVALID_CUSTOMER_BIRTH_DATE = 'INVALID_CUSTOMER_BIRTH_DATE',
        INVALID_CUSTOMER_GENDER_SIZE = 'INVALID_CUSTOMER_GENDER_SIZE',
        INVALID_CUSTOMER_GENDER = 'INVALID_CUSTOMER_GENDER',
        INVALID_CUSTOMER_ENUM_GENDER = 'INVALID_CUSTOMER_ENUM_GENDER',
        INVALID_QRCODE_ENUM_FILE_FORMAT = 'INVALID_QRCODE_ENUM_FILE_FORMAT',
        INVALID_CUSTOMER_MOTHER_NAME_SIZE = 'INVALID_CUSTOMER_MOTHER_NAME_SIZE',
        INVALID_CUSTOMER_MOTHER_NAME = 'INVALID_CUSTOMER_MOTHER_NAME',
        INVALID_CUSTOMER_PHONE = 'INVALID_CUSTOMER_PHONE',
        INVALID_CUSTOMER_ADDRESS = 'INVALID_CUSTOMER_ADDRESS',
        INVALID_CUSTOMER_OPTIN = 'INVALID_CUSTOMER_OPTIN',
        INVALID_CUSTOMER_ORIGINAL_PLATFORM_SIZE = 'INVALID_CUSTOMER_ORIGINAL_PLATFORM_SIZE',
        INVALID_CUSTOMER_ORIGINAL_PLATFORM = 'INVALID_CUSTOMER_ORIGINAL_PLATFORM',
        PHONE_ALREADY_REGISTERED_FOR_THIS_CUSTOMER = 'PHONE_ALREADY_REGISTERED_FOR_THIS_CUSTOMER',
        PHONE_ALREADY_REGISTERED_FOR_OTHER_CUSTOMER = 'PHONE_ALREADY_REGISTERED_FOR_OTHER_CUSTOMER',
        INVALID_FAVORITE_TEAM_SIZE = 'INVALID_FAVORITE_TEAM_SIZE',
        CUSTOMER_OWN_ACCOUNT_TRANSFER = 'CUSTOMER_OWN_ACCOUNT_TRANSFER',
        INVALID_USER_LOGIN = 'INVALID_USER_LOGIN',
        INVALID_USER_UUID_SIZE = 'INVALID_USER_UUID_SIZE',
        INVALID_USER_UUID = 'INVALID_USER_UUID',
        INVALID_USER_EMAIL_SIZE = 'INVALID_USER_EMAIL_SIZE',
        INVALID_USER_EMAIL = 'INVALID_USER_EMAIL',
        INVALID_USER_NAME_SIZE = 'INVALID_USER_NAME_SIZE',
        INVALID_USER_NAME = 'INVALID_USER_NAME',
        INVALID_USER_DOCUMENT_SIZE = 'INVALID_USER_DOCUMENT_SIZE',
        INVALID_USER_DOCUMENT = 'INVALID_USER_DOCUMENT',
        INVALID_USER_DOCUMENT_ONLY_NUMBER = 'INVALID_USER_DOCUMENT_ONLY_NUMBER',
        INVALID_USER_PASSWORD_SIZE = 'INVALID_USER_PASSWORD_SIZE',
        INVALID_USER_PASSWORD = 'INVALID_USER_PASSWORD',
        INVALID_USER_TYPE_SIZE = 'INVALID_USER_TYPE_SIZE',
        INVALID_USER_TYPE = 'INVALID_USER_TYPE',
        INVALID_PERMISSION_NAME_SIZE = 'INVALID_PERMISSION_NAME_SIZE',
        INVALID_PERMISSION_NAME = 'INVALID_PERMISSION_NAME',
        INVALID_PERMISSION_DESCRIPTION_SIZE = 'INVALID_PERMISSION_DESCRIPTION_SIZE',
        INVALID_PERMISSION_DESCRIPTION = 'INVALID_PERMISSION_DESCRIPTION',
        INVALID_GROUP_NAME_SIZE = 'INVALID_GROUP_NAME_SIZE',
        INVALID_GROUP_NAME = 'INVALID_GROUP_NAME',
        INVALID_GROUP_DESCRIPTION_SIZE = 'INVALID_GROUP_DESCRIPTION_SIZE',
        INVALID_GROUP_DESCRIPTION = 'INVALID_GROUP_DESCRIPTION',
        INVALID_GROUP_EMAIL = 'INVALID_GROUP_EMAIL',
        INVALID_MODULE = 'INVALID_MODULE',
        INVALID_SIZE = 'INVALID_SIZE',
        INVALID_FORMAT = 'INVALID_FORMAT',
        OUT_OF_RANGE_OF_WIDTH = 'OUT_OF_RANGE_OF_WIDTH',
        OUT_OF_RANGE_OF_HEIGHT = 'OUT_OF_RANGE_OF_HEIGHT',
        CUSTOMER_DOCUMENT_NOT_BLANK = 'CUSTOMER_DOCUMENT_NOT_BLANK',
        STORE_DOCUMENT_NOT_BLANK = 'STORE_DOCUMENT_NOT_BLANK',
        STORE_PAYMENT_NOT_SUPORTED = 'STORE_PAYMENT_NOT_SUPORTED',
        INVALID_ORDER_LOCATION = 'INVALID_ORDER_LOCATION',
        INVALID_ORDER_LATITUDE = 'INVALID_ORDER_LATITUDE',
        INVALID_ORDER_LONGITUDE = 'INVALID_ORDER_LONGITUDE',
        ORDER_OWNID_NOT_BLANK = 'ORDER_OWNID_NOT_BLANK',
        INVALID_ORDER_OWNID_SIZE = 'INVALID_ORDER_OWNID_SIZE',
        INVALID_ORDER_BENEFIT_ACTION = 'INVALID_ORDER_BENEFIT_ACTION',
        INVALID_ORDER_BENEFIT_TYPE = 'INVALID_ORDER_BENEFIT_TYPE',
        INVALID_ORDER_BENEFIT_KMV = 'INVALID_ORDER_BENEFIT_KMV',
        INVALID_ORDER_BENEFIT_DESCRIPTION = 'INVALID_ORDER_BENEFIT_DESCRIPTION',
        INVALID_ORDER_BENEFIT_EXTERNALREFERENCEID = 'INVALID_ORDER_BENEFIT_EXTERNALREFERENCEID',
        INVALID_ORDER_BENEFIT_PERCENTAGE = 'INVALID_ORDER_BENEFIT_PERCENTAGE',
        INVALID_AMOUNT_TYPE = 'INVALID_AMOUNT_TYPE',
        INVALID_ORDER_BENEFIT = 'INVALID_ORDER_BENEFIT',
        INVALID_ORDER_BENEFIT_VALUE = 'INVALID_ORDER_BENEFIT_VALUE',
        INVALID_ORDER_PDV_TOKEN_SIZE = 'INVALID_ORDER_PDV_TOKEN_SIZE',
        INVALID_PAYMENT_INSTRUMENT = 'INVALID_PAYMENT_INSTRUMENT',
        INVALID_PAYMENT_INSTRUMENT_CASH_VALUE = 'INVALID_PAYMENT_INSTRUMENT_CASH_VALUE',
        INVALID_PAYMENT_INSTRUMENT_BALANCE_VALUE = 'INVALID_PAYMENT_INSTRUMENT_BALANCE_VALUE',
        INVALID_PAYMENT_INSTRUMENT_COUPON_VALUE = 'INVALID_PAYMENT_INSTRUMENT_COUPON_VALUE',
        INVALID_PAYMENT_INSTRUMENT_CARD_ID = 'INVALID_PAYMENT_INSTRUMENT_CARD_ID',
        INVALID_PAYMENT_INSTRUMENT_CARD_VALUE = 'INVALID_PAYMENT_INSTRUMENT_CARD_VALUE',
        INVALID_PAYMENT_INSTRUMENT_CARD_INSTALLMENT_COUNT = 'INVALID_PAYMENT_INSTRUMENT_CARD_INSTALLMENT_COUNT',
        INVALID_PAYMENT_INSTRUMENT_WITHOUT_TYPE = 'INVALID_PAYMENT_INSTRUMENT_WITHOUT_TYPE',
        INVALID_PAYMENT_INSTRUMENT_INVALID_TYPE = 'INVALID_PAYMENT_INSTRUMENT_INVALID_TYPE',
        INVALID_PAYMENT_INSTRUMENT_AUTHENTICATION_ID = 'INVALID_PAYMENT_INSTRUMENT_AUTHENTICATION_ID',
        INVALID_PAYMENT_INSTRUMENT_THREEDS_INVALID_EXCEPTION = 'INVALID_PAYMENT_INSTRUMENT_THREEDS_INVALID_EXCEPTION',
        INVALID_PAYMENT_INSTRUMENT_THREEDS_INVALID_BRAND_EXCEPTION = 'INVALID_PAYMENT_INSTRUMENT_THREEDS_INVALID_BRAND_EXCEPTION',
        INVALID_PAYMENT_INSTRUMENT_AUTENTHICATION_ID_SIZE_EXCEEDED_SIZE = 'INVALID_PAYMENT_INSTRUMENT_AUTENTHICATION_ID_SIZE_EXCEEDED_SIZE',
        INVALID_PAYMENT_INSTRUMENT_CASHBACK_PERCENTAGE = 'INVALID_PAYMENT_INSTRUMENT_CASHBACK_PERCENTAGE',
        INVALID_PAYMENT_INSTRUMENT_CASHBACK_VALUE = 'INVALID_PAYMENT_INSTRUMENT_CASHBACK_VALUE',
        INVALID_CASHBACK_VALUE = 'INVALID_CASHBACK_VALUE',
        PAYMENT_INSTRUMENT_NOT_SUPPORTED = 'PAYMENT_INSTRUMENT_NOT_SUPPORTED',
        INVALID_CREDIT_CARD_VALIDATION_VALUE_EXCEEDED_SIZE = 'INVALID_CREDIT_CARD_VALIDATION_VALUE_EXCEEDED_SIZE',
        INVALID_ACCOUNT_NUMBER = 'INVALID_ACCOUNT_NUMBER',
        INVALID_ACCOUNT_NUMBER_SIZE = 'INVALID_ACCOUNT_NUMBER_SIZE',
        INVALID_ACCOUNT_CHECK_NUMBER = 'INVALID_ACCOUNT_CHECK_NUMBER',
        INVALID_ACCOUNT_CHECK_NUMBER_SIZE = 'INVALID_ACCOUNT_CHECK_NUMBER_SIZE',
        INVALID_AGENCY = 'INVALID_AGENCY',
        INVALID_AGENCY_SIZE = 'INVALID_AGENCY_SIZE',
        INVALID_AGENCY_CHECK_NUMBER_SIZE = 'INVALID_AGENCY_CHECK_NUMBER_SIZE',
        INVALID_PHONES = 'INVALID_PHONES',
        INVALID_REDIRECT_URL_SIZE = 'INVALID_REDIRECT_URL_SIZE',
        INVALID_REDIRECT_URL_EMPTY = 'INVALID_REDIRECT_URL_EMPTY',
        INVALID_REDIRECT = 'INVALID_REDIRECT',
        INVALID_WAIT_DAYS = 'INVALID_WAIT_DAYS',
        INVALID_WAIT_DAYS_LIMIT = 'INVALID_WAIT_DAYS_LIMIT',
        UNSUPPORTED_MEDIA_TYPE = 'UNSUPPORTED_MEDIA_TYPE',
        INVALID_JSON_REQUEST = 'INVALID_JSON_REQUEST',
        CVS_FILE_GENERATION_ERROR = 'CVS_FILE_GENERATION_ERROR',
        INVALID_RECEIVABLE_UUID = 'INVALID_RECEIVABLE_UUID',
        INVALID_RECEIVABLEUUID = 'INVALID_RECEIVABLEUUID',
        RECEIVABLE_NOT_FOUND = 'RECEIVABLE_NOT_FOUND',
        RECEIVABLE_NOT_AVAILABLE = 'RECEIVABLE_NOT_AVAILABLE',
        RECEIVABLE_NOT_AVAILABLE_TO_TRANSFER = 'RECEIVABLE_NOT_AVAILABLE_TO_TRANSFER',
        INSUFFICIENT_PAYMENT_INSTRUMENTS_VALUE = 'INSUFFICIENT_PAYMENT_INSTRUMENTS_VALUE',
        INVALID_INSTALLMENT_COUNT = 'INVALID_INSTALLMENT_COUNT',
        PAYMENT_METHOD_NOT_ALLOWED_EXCEPTION = 'PAYMENT_METHOD_NOT_ALLOWED_EXCEPTION',
        INVALID_ORDER_OWNID = 'INVALID_ORDER_OWNID',
        HOLIDAY_NOT_FOUND = 'HOLIDAY_NOT_FOUND',
        INVALID_EXTRACT_OFFSET = 'INVALID_EXTRACT_OFFSET',
        INVALID_EXTRACT_LIMIT = 'INVALID_EXTRACT_LIMIT',
        INVALID_EXTRACT_PERIOD = 'INVALID_EXTRACT_PERIOD',
        INVALID_TYPE_DATE = 'INVALID_TYPE_DATE',
        INVALID_TYPE_STARTDATE = 'INVALID_TYPE_STARTDATE',
        INVALID_TYPE_START_DATE = 'INVALID_TYPE_START_DATE',
        INVALID_TYPE_ENDDATE = 'INVALID_TYPE_ENDDATE',
        INVALID_TYPE_END_DATE = 'INVALID_TYPE_END_DATE',
        INVALID_TYPE_PAGE = 'INVALID_TYPE_PAGE',
        INVALID_TYPE_QUANTITY = 'INVALID_TYPE_QUANTITY',
        INVALID_TYPE_YEARMONTH = 'INVALID_TYPE_YEARMONTH',
        INVALID_TYPE_YEAR_MONTH = 'INVALID_TYPE_YEAR_MONTH',
        P2P_AMOUNT_NOT_NULL = 'P2P_AMOUNT_NOT_NULL',
        P2P_MAX_AMOUNT_EXCEDED = 'P2P_MAX_AMOUNT_EXCEDED',
        P2P_MIN_AMOUNT = 'P2P_MIN_AMOUNT',
        P2P_DESCRIPTION_NOT_BLANK = 'P2P_DESCRIPTION_NOT_BLANK',
        P2P_MAX_DESCRIPTION_EXCEDED = 'P2P_MAX_DESCRIPTION_EXCEDED',
        P2P_SAME_USER = 'P2P_SAME_USER',
        CASH_IN_AMOUNT_NOT_NULL = 'CASH_IN_AMOUNT_NOT_NULL',
        CASH_IN_MIN_AMOUNT_LESS_THAN_EXPECTED = 'CASH_IN_MIN_AMOUNT_LESS_THAN_EXPECTED',
        CASH_IN_MAX_AMOUNT_EXCEDED = 'CASH_IN_MAX_AMOUNT_EXCEDED',
        PAYEE_NOT_NULL = 'PAYEE_NOT_NULL',
        PAYEE_ID_NOT_BLANK = 'PAYEE_ID_NOT_BLANK',
        MAX_PAYEE_ID_EXCEDED = 'MAX_PAYEE_ID_EXCEDED',
        GEOLOCATION_NOT_NULL = 'GEOLOCATION_NOT_NULL',
        GEOLOCATION_LAT_NOT_BLANK = 'GEOLOCATION_LAT_NOT_BLANK',
        MAX_GEOLACATION_LAT_EXCEDED = 'MAX_GEOLACATION_LAT_EXCEDED',
        GEOLOCATION_LNG_NOT_BLANK = 'GEOLOCATION_LNG_NOT_BLANK',
        MAX_GEOLACATION_LNG_EXCEDED = 'MAX_GEOLACATION_LNG_EXCEDED',
        DEVICE_NOT_NULL = 'DEVICE_NOT_NULL',
        DEVICE_APP_VERSION_NOT_BLANK = 'DEVICE_APP_VERSION_NOT_BLANK',
        MAX_DEVICE_APP_VERSION_EXCEDED = 'MAX_DEVICE_APP_VERSION_EXCEDED',
        DEVICE_DISPLAY_DENSITY_DPI_NOT_NULL = 'DEVICE_DISPLAY_DENSITY_DPI_NOT_NULL',
        MAX_DEVICE_DISPLAY_DENSITY_DPI_EXCEDED = 'MAX_DEVICE_DISPLAY_DENSITY_DPI_EXCEDED',
        DEVICE_DISPLAY_HEIGHT_NOT_NULL = 'DEVICE_DISPLAY_HEIGHT_NOT_NULL',
        MAX_DEVICE_DISPLAY_HEIGHT_EXCEDED = 'MAX_DEVICE_DISPLAY_HEIGHT_EXCEDED',
        DEVICE_DISPLAY_WIDTH_NOT_NULL = 'DEVICE_DISPLAY_WIDTH_NOT_NULL',
        MAX_DEVICE_DISPLAY_WIDTH_EXCEDED = 'MAX_DEVICE_DISPLAY_WIDTH_EXCEDED',
        DEVICE_MODEL_NOT_BLANK = 'DEVICE_MODEL_NOT_BLANK',
        MAX_DEVICE_MODEL_EXCEDED = 'MAX_DEVICE_MODEL_EXCEDED',
        DEVICE_OS_NAME_NOT_BLANK = 'DEVICE_OS_NAME_NOT_BLANK',
        MAX_DEVICE_OS_NAME_EXCEDED = 'MAX_DEVICE_OS_NAME_EXCEDED',
        DEVICE_OS_VERSION_NOT_BLANK = 'DEVICE_OS_VERSION_NOT_BLANK',
        MAX_DEVICE_OS_VERSION_EXCEDED = 'MAX_DEVICE_OS_VERSION_EXCEDED',
        DEVICE_IP_NOT_BLANK = 'DEVICE_IP_NOT_BLANK',
        MAX_DEVICE_IP_EXCEDED = 'MAX_DEVICE_IP_EXCEDED',
        INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
        P2P_NOT_FOUND = 'P2P_NOT_FOUND',
        CANCELLED_STATUS = 'CANCELLED_STATUS',
        ERROR_STATUS = 'ERROR_STATUS',
        EXCEDED_BILLET_PER_DAY = 'EXCEDED_BILLET_PER_DAY',
        BILLET_NOT_FOUND = 'BILLET_NOT_FOUND',
        UNABLE_TO_UPDATE_STORE_ACCOUNT = 'UNABLE_TO_UPDATE_STORE_ACCOUNT',
        STORE_HAS_BRANCH = 'STORE_HAS_BRANCH',
        TRANSACTION_ID_EXCEEDED = 'TRANSACTION_ID_EXCEEDED',
        TRANSACTION_ID_NOT_NULL = 'TRANSACTION_ID_NOT_NULL',
        ERROR_DESCRIPTION_NOT_NULL = 'ERROR_DESCRIPTION_NOT_NULL',
        ERROR_CODE_NOT_NULL = 'ERROR_CODE_NOT_NULL',
        TRANSFER_FAILED_DATE_NOT_NULL = 'TRANSFER_FAILED_DATE_NOT_NULL',
        INVALID_CHECKED = 'INVALID_CHECKED',
        INVALID_STORE_UUID = 'INVALID_STORE_UUID',
        TED_NOT_FOUND = 'TED_NOT_FOUND',
        TED_AMOUNT_NOT_NULL = 'TED_AMOUNT_NOT_NULL',
        TED_MAX_AMOUNT_EXCEDED = 'TED_MAX_AMOUNT_EXCEDED',
        TED_DESCRIPTION_NOT_BLANK = 'TED_DESCRIPTION_NOT_BLANK',
        TED_MAX_DESCRIPTION_EXCEDED = 'TED_MAX_DESCRIPTION_EXCEDED',
        ELETRONIC_TRANSFER_NOT_NULL = 'ELETRONIC_TRANSFER_NOT_NULL',
        TED_SCHEDULED_DATE_NOT_BLANK = 'TED_SCHEDULED_DATE_NOT_BLANK',
        BANK_ACCOUNT_NOT_NULL = 'BANK_ACCOUNT_NOT_NULL',
        BANK_ACCOUNT_NAME_NOT_BLANK = 'BANK_ACCOUNT_NAME_NOT_BLANK',
        BANK_ACCOUNT_NAME_EXCEDED = 'BANK_ACCOUNT_NAME_EXCEDED',
        BANK_ACCOUNT_DOCUMENT_NOT_BLANK = 'BANK_ACCOUNT_DOCUMENT_NOT_BLANK',
        BANK_ACCOUNT_DOCUMENT_EXCEDED = 'BANK_ACCOUNT_DOCUMENT_EXCEDED',
        BANK_ACCOUNT_NUMBER_NOT_BLANK = 'BANK_ACCOUNT_NUMBER_NOT_BLANK',
        BANK_ACCOUNT_NUMBER_EXCEDED = 'BANK_ACCOUNT_NUMBER_EXCEDED',
        BANK_ACCOUNT_AGENCY_NOT_BLANK = 'BANK_ACCOUNT_AGENCY_NOT_BLANK',
        BANK_ACCOUNT_AGENCY_EXCEDED = 'BANK_ACCOUNT_AGENCY_EXCEDED',
        BANK_ACCOUNT_AGENCY_CHECK_NOT_BLANK = 'BANK_ACCOUNT_AGENCY_CHECK_NOT_BLANK',
        BANK_ACCOUNT_AGENCY_CHECK_EXCEDED = 'BANK_ACCOUNT_AGENCY_CHECK_EXCEDED',
        BANK_ACCOUNT_ACCOUNT_NOT_BLANK = 'BANK_ACCOUNT_ACCOUNT_NOT_BLANK',
        BANK_ACCOUNT_ACCOUNT_EXCEDED = 'BANK_ACCOUNT_ACCOUNT_EXCEDED',
        BANK_ACCOUNT_ACCOUNT_CHECK_NOT_BLANK = 'BANK_ACCOUNT_ACCOUNT_CHECK_NOT_BLANK',
        BANK_ACCOUNT_ACCOUNT_CHECK_EXCEDED = 'BANK_ACCOUNT_ACCOUNT_CHECK_EXCEDED',
        BANK_ACCOUNT_DOCUMENT_INVALID = 'BANK_ACCOUNT_DOCUMENT_INVALID',
        BANK_ACCOUNT_NUMBER_INVALID = 'BANK_ACCOUNT_NUMBER_INVALID',
        BANK_ACCOUNT_AGENCY_INVALID = 'BANK_ACCOUNT_AGENCY_INVALID',
        BANK_ACCOUNT_AGENCY_CHECK_INVALID = 'BANK_ACCOUNT_AGENCY_CHECK_INVALID',
        BANK_ACCOUNT_ACCOUNT_INVALID = 'BANK_ACCOUNT_ACCOUNT_INVALID',
        BANK_ACCOUNT_ACCOUNT_CHECK_INVALID = 'BANK_ACCOUNT_ACCOUNT_CHECK_INVALID',
        BANK_ACCOUNT_SHORT_NAME_NOT_BLANK = 'BANK_ACCOUNT_SHORT_NAME_NOT_BLANK',
        BANK_ACCOUNT_SHORT_NAME_EXCEDED = 'BANK_ACCOUNT_SHORT_NAME_EXCEDED',
        BANK_ACCOUNT_LONG_NAME_NOT_BLANK = 'BANK_ACCOUNT_LONG_NAME_NOT_BLANK',
        BANK_ACCOUNT_LONG_NAME_EXCEDED = 'BANK_ACCOUNT_LONG_NAME_EXCEDED',
        BANK_ACCOUNT_BANK_NAME_NOT_BLANK = 'BANK_ACCOUNT_BANK_NAME_NOT_BLANK',
        BANK_ACCOUNT_BANK_NAME_EXCEDED = 'BANK_ACCOUNT_BANK_NAME_EXCEDED',
        BANK_ACCOUNT_BANK_ISPB_NOT_BLANK = 'BANK_ACCOUNT_BANK_ISPB_NOT_BLANK',
        BANK_ACCOUNT_BANK_ISPB_EXCEDED = 'BANK_ACCOUNT_BANK_ISPB_EXCEDED',
        BANK_ACCOUNT_BANK_ISPB_INVALID = 'BANK_ACCOUNT_BANK_ISPB_INVALID',
        BANK_ACCOUNT_BANK_TYPE_NOT_BLANK = 'BANK_ACCOUNT_BANK_TYPE_NOT_BLANK',
        BANK_ACCOUNT_BANK_TYPE_EXCEDED = 'BANK_ACCOUNT_BANK_TYPE_EXCEDED',
        INVALID_LIST_VALUE_SIZE = 'INVALID_LIST_VALUE_SIZE',
        INVALID_DATE_TYPE = 'INVALID_DATE_TYPE',
        INVALID_TRANSFER_ERROR_DESCRIPTION = 'INVALID_TRANSFER_ERROR_DESCRIPTION',
        INVALID_TRANSFER_ERROR_DESCRIPTION_SIZE = 'INVALID_TRANSFER_ERROR_DESCRIPTION_SIZE',
        INVALID_TRANSFER_ERROR_CODE = 'INVALID_TRANSFER_ERROR_CODE',
        INVALID_TRANSFER_ERROR_CODE_SIZE = 'INVALID_TRANSFER_ERROR_CODE_SIZE',
        INVALID_TRANSFER_MESSAGE_SIZE = 'INVALID_TRANSFER_MESSAGE_SIZE',
        INVALID_TRANSFER_USER_UUID_SIZE = 'INVALID_TRANSFER_USER_UUID_SIZE',
        INVALID_TRANSFER_USER_UUID = 'INVALID_TRANSFER_USER_UUID',
        INVALID_TRANSFER_RECEIVABLE_UUID = 'INVALID_TRANSFER_RECEIVABLE_UUID',
        INVALID_TRANSFER_RECEIVABLE_UUID_SIZE = 'INVALID_TRANSFER_RECEIVABLE_UUID_SIZE',
        INVALID_TRANSFER_STATUS = 'INVALID_TRANSFER_STATUS',
        INVALID_TRANSFER_UUID_SIZE = 'INVALID_TRANSFER_UUID_SIZE',
        INVALID_TRANSFER_UUID = 'INVALID_TRANSFER_UUID',
        DATE_REQUIRED = 'DATE_REQUIRED',
        INVALID_ORDER_BENEFIT_VOUCHER_DOCUMENT = 'INVALID_ORDER_BENEFIT_VOUCHER_DOCUMENT',
        INVALID_ORDER_BENEFIT_VOUCHER_DOCUMENT_SIZE = 'INVALID_ORDER_BENEFIT_VOUCHER_DOCUMENT_SIZE',
        INVALID_ORDER_BENEFIT_VOUCHER_CODE = 'INVALID_ORDER_BENEFIT_VOUCHER_CODE',
        INVALID_ORDER_BENEFIT_VOUCHER_CODE_SIZE = 'INVALID_ORDER_BENEFIT_VOUCHER_CODE_SIZE',
        INVALID_ORDER_BENEFIT_VOUCHER_EXTERNAL_REFERENCE_ID = 'INVALID_ORDER_BENEFIT_VOUCHER_EXTERNAL_REFERENCE_ID',
        INVALID_ORDER_BENEFIT_VOUCHER_EXTERNAL_REFERENCE_ID_SIZE = 'INVALID_ORDER_BENEFIT_VOUCHER_EXTERNAL_REFERENCE_ID_SIZE',
        INVALID_ORDER_BENEFIT_VOUCHER_STATUS = 'INVALID_ORDER_BENEFIT_VOUCHER_STATUS',
        INVALID_ORDER_BENEFIT_VOUCHER_EXPIRATION_DATE = 'INVALID_ORDER_BENEFIT_VOUCHER_EXPIRATION_DATE',
        INVALID_ORDER_BENEFIT_VOUCHER_REDEEMED_AT = 'INVALID_ORDER_BENEFIT_VOUCHER_REDEEMED_AT',
        INVALID_INTEGRATOR_CODE_PATTERN = 'INVALID_INTEGRATOR_CODE_PATTERN',
        INVALID_INTEGRATOR_CODE_SIZE = 'INVALID_INTEGRATOR_CODE_SIZE',
        UNDER_TED_AMOUNT_PERMITTED = 'UNDER_TED_AMOUNT_PERMITTED',
        EXCEDED_TED_AMOUNT_PERMITTED = 'EXCEDED_TED_AMOUNT_PERMITTED',
        INVALID_DEVICE_DATA = 'INVALID_DEVICE_DATA',
        INVALID_CREDIT_CARD_ENC_DATA_NOT_NULL = 'INVALID_CREDIT_CARD_ENC_DATA_NOT_NULL',
        INVALID_CREDIT_CARD_CIPHER_TEXT_NOT_NULL = 'INVALID_CREDIT_CARD_CIPHER_TEXT_NOT_NULL',
        INVALID_CREDIT_CARD_ENCRYPTION_NOT_NULL = 'INVALID_CREDIT_CARD_ENCRYPTION_NOT_NULL',
        INVALID_CREDIT_CARD_IV_KEY_NOT_NULL = 'INVALID_CREDIT_CARD_IV_KEY_NOT_NULL',
        ACCOUNT_BLOCKED_JUDICIAL = 'ACCOUNT_BLOCKED_JUDICIAL',
        INVALID_COUPON_PARTNER_SIZE = 'INVALID_COUPON_PARTNER_SIZE',
        INVALID_COUPON_PARTNER = 'INVALID_COUPON_PARTNER',
        INVALID_COUPON_SPONSOR_SIZE = 'INVALID_COUPON_SPONSOR_SIZE',
        INVALID_COUPON_SPONSOR = 'INVALID_COUPON_SPONSOR',
        INVALID_COUPON_DOCUMENT_SIZE = 'INVALID_COUPON_DOCUMENT_SIZE',
        INVALID_COUPON_DOCUMENT = 'INVALID_COUPON_DOCUMENT',
        INVALID_COUPON_AMOUNT_MIN = 'INVALID_COUPON_AMOUNT_MIN',
        INVALID_COUPON_AMOUNT = 'INVALID_COUPON_AMOUNT',
        INVALID_COUPON_EARN_DATE = 'INVALID_COUPON_EARN_DATE',
        INVALID_COUPON_EXTERNAL_REFERENCE_ID_SIZE = 'INVALID_COUPON_EXTERNAL_REFERENCE_ID_SIZE',
        INVALID_COUPON_EXTERNAL_REFERENCE_ID = 'INVALID_COUPON_EXTERNAL_REFERENCE_ID',
        INVALID_COUPON_AUTOMATIC_CONFIRMATION = 'INVALID_COUPON_AUTOMATIC_CONFIRMATION',
        INVALID_COUPON_NAME_SIZE = 'INVALID_COUPON_NAME_SIZE',
        INVALID_COUPON_NAME = 'INVALID_COUPON_NAME',
        INVALID_COUPON_DESCRIPTION_SIZE = 'INVALID_COUPON_DESCRIPTION_SIZE',
        INVALID_COUPON_DESCRIPTION = 'INVALID_COUPON_DESCRIPTION',
        INVALID_COUPON_STORE_SIZE = 'INVALID_COUPON_STORE_SIZE',
        INVALID_COUPON_PRODUCT_SIZE = 'INVALID_COUPON_PRODUCT_SIZE',
        COUPON_NOT_FOUND = 'COUPON_NOT_FOUND',
        COUPON_ALREADY_EXISTS = 'COUPON_ALREADY_EXISTS',
        COUPON_SAVE_ERROR = 'COUPON_SAVE_ERROR',
        INVALID_STORE_ACCOUNT_UPLOAD_LETTER_OF_ATTORNEY_IMAGE = 'INVALID_STORE_ACCOUNT_UPLOAD_LETTER_OF_ATTORNEY_IMAGE',
        INVALID_STORE_ACCOUNT_UPLOAD_SOCIAL_CONTRACT_IMAGE = 'INVALID_STORE_ACCOUNT_UPLOAD_SOCIAL_CONTRACT_IMAGE',
        INVALID_STORE_ACCOUNT_UPLOAD_DOCUMENT_IMAGE = 'INVALID_STORE_ACCOUNT_UPLOAD_DOCUMENT_IMAGE',
        INVALID_STORE_ACCOUNT_UPLOAD_DOCUMENT_IMAGE_TYPE = 'INVALID_STORE_ACCOUNT_UPLOAD_DOCUMENT_IMAGE_TYPE',
        TED_DENIED = 'TED_DENIED',
        TED_UNABLE_TO_CANCEL = 'TED_UNABLE_TO_CANCEL',
        INVALID_CONCESSION_UUID_SIZE = 'INVALID_CONCESSION_UUID_SIZE',
        INVALID_CONCESSION_UUID = 'INVALID_CONCESSION_UUID',
        INVALID_CONCESSION_ACCOUNT_TYPE = 'INVALID_CONCESSION_ACCOUNT_TYPE',
        INVALID_CONCESSION_TRANSACTION_ID_SIZE = 'INVALID_CONCESSION_TRANSACTION_ID_SIZE',
        INVALID_CONCESSION_TRANSACTION_ID = 'INVALID_CONCESSION_TRANSACTION_ID',
        INVALID_CONCESSION_TRANSACTION_TYPE = 'INVALID_CONCESSION_TRANSACTION_TYPE',
        INVALID_CONCESSION_AMOUNT_MIN = 'INVALID_CONCESSION_AMOUNT_MIN',
        INVALID_CONCESSION_AMOUNT = 'INVALID_CONCESSION_AMOUNT',
        INVALID_CONCESSION_ATTACHMENT_MAX = 'INVALID_CONCESSION_ATTACHMENT_MAX',
        INVALID_CONCESSION_AMOUNT_MAX = 'INVALID_CONCESSION_AMOUNT_MAX',
        INVALID_CONCESSION_COMBINATION = 'INVALID_CONCESSION_COMBINATION',
        INVALID_CONCESSION_JUSTIFICATION_SIZE = 'INVALID_CONCESSION_JUSTIFICATION_SIZE',
        INVALID_CONCESSION_JUSTIFICATION = 'INVALID_CONCESSION_JUSTIFICATION',
        INVALID_CONCESSION_TICKET_SIZE = 'INVALID_CONCESSION_TICKET_SIZE',
        INVALID_CONCESSION_TICKET_PATTERN = 'INVALID_CONCESSION_TICKET_PATTERN',
        INVALID_CONCESSION_TICKET = 'INVALID_CONCESSION_TICKET',
        INVALID_CONCESSION_BENEFICIARY_SIZE = 'INVALID_CONCESSION_BENEFICIARY_SIZE',
        INVALID_CONCESSION_BENEFICIARY = 'INVALID_CONCESSION_BENEFICIARY',
        INVALID_CONCESSION_USER_SIZE = 'INVALID_CONCESSION_USER_SIZE',
        INVALID_CONCESSION_USER = 'INVALID_CONCESSION_USER',
        ORDER_NOT_COMPLETED = 'ORDER_NOT_COMPLETED',
        INVALID_S3_UPLOAD = 'INVALID_S3_UPLOAD',
        UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR',
        INVALID_PIX_KEY_USE_DOCUMENT = 'INVALID_PIX_KEY_USE_DOCUMENT',
        INVALID_PIX_KEY_USE_PHONE = 'INVALID_PIX_KEY_USE_PHONE',
        USER_NOT_ALLOWED = 'USER_NOT_ALLOWED',
        INVALID_PIX_KEY_KEY_SIZE = 'INVALID_PIX_KEY_KEY_SIZE',
        INVALID_PIX_KEY_KEY = 'INVALID_PIX_KEY_KEY',
        INVALID_PIX_KEY_TYPE = 'INVALID_PIX_KEY_TYPE',
        INVALID_PIX_KEY_DOCUMENT_SIZE = 'INVALID_PIX_KEY_DOCUMENT_SIZE',
        INVALID_PIX_KEY_PHONE_SIZE = 'INVALID_PIX_KEY_PHONE_SIZE',
        INVALID_PIX_KEY_PRE_REGISTER_USE_DOCUMENT = 'INVALID_PIX_KEY_PRE_REGISTER_USE_DOCUMENT',
        INVALID_PIX_KEY_PRE_REGISTER_USE_PHONE = 'INVALID_PIX_KEY_PRE_REGISTER_USE_PHONE',
        INVALID_PIX_KEY_PRE_REGISTER_OPERATION = 'INVALID_PIX_KEY_PRE_REGISTER_OPERATION',
        INVALID_PIX_KEY_PRE_REGISTER_COMBINATION = 'INVALID_PIX_KEY_PRE_REGISTER_COMBINATION',
        PIX_KEY_PRE_REGISTER_ALREADY_REQUESTED = 'PIX_KEY_PRE_REGISTER_ALREADY_REQUESTED',
        PIX_KEY_ALREADY_EXISTS = 'PIX_KEY_ALREADY_EXISTS',
        PIX_KEY_NOT_FOUND = 'PIX_KEY_NOT_FOUND',
        PIX_KEY_ALREADY_FAVORITED = 'PIX_KEY_ALREADY_FAVORITED',
        INVALID_PIX_KEY_OPERATION = 'INVALID_PIX_KEY_OPERATION',
        PIX_KEY_PORTABILITY_EXISTS = 'PIX_KEY_PORTABILITY_EXISTS',
        PIX_KEY_OWNERSHIP_EXISTS = 'PIX_KEY_OWNERSHIP_EXISTS',
        PIX_KEY_COMMUNICATION_FAILURE = 'PIX_KEY_COMMUNICATION_FAILURE',
        PIX_KEY_COMMUNICATION_FAILURE_AT_BV = 'PIX_KEY_COMMUNICATION_FAILURE_AT_BV',
        PIX_KEY_PENDING_PROCESS = 'PIX_KEY_PENDING_PROCESS',
        PIX_KEY_DOCUMENT_IS_DIFFERENT_FROM_REGISTER = 'PIX_KEY_DOCUMENT_IS_DIFFERENT_FROM_REGISTER',
        PIX_KEY_PHONE_IS_DIFFERENT_FROM_REGISTER = 'PIX_KEY_PHONE_IS_DIFFERENT_FROM_REGISTER',
        PIX_KEY_EMAIL_IS_DIFFERENT_FROM_REGISTER = 'PIX_KEY_EMAIL_IS_DIFFERENT_FROM_REGISTER',
        PIX_KEY_CUSTOMER_EXCEEDED_THE_LIMIT = 'PIX_KEY_CUSTOMER_EXCEEDED_THE_LIMIT',
        PIX_KEY_STORE_EXCEEDED_THE_LIMIT = 'PIX_KEY_STORE_EXCEEDED_THE_LIMIT',
        PIX_KEY_UNABLE_TO_EXCLUDE = 'PIX_KEY_UNABLE_TO_EXCLUDE',
        INVALID_PIX_KEY_REASON_TYPE = 'INVALID_PIX_KEY_REASON_TYPE',
        PIX_SCHEDULED_DATE_NOT_BLANK = 'PIX_SCHEDULED_DATE_NOT_BLANK',
        PIX_CLAIM_UNABLE_TO_CREATE = 'PIX_CLAIM_UNABLE_TO_CREATE',
        PIX_CLAIM_UNABLE_TO_CANCEL = 'PIX_CLAIM_UNABLE_TO_CANCEL',
        PIX_CLAIM_UNABLE_TO_CONFIRM = 'PIX_CLAIM_UNABLE_TO_CONFIRM',
        INVALID_PIX_CLAIM_KEY_SIZE = 'INVALID_PIX_CLAIM_KEY_SIZE',
        INVALID_PIX_CLAIM_KEY = 'INVALID_PIX_CLAIM_KEY',
        INVALID_PIX_CLAIM_KEY_TYPE = 'INVALID_PIX_CLAIM_KEY_TYPE',
        INVALID_PIX_CLAIM_TYPE = 'INVALID_PIX_CLAIM_TYPE',
        PIX_CLAIM_ALREADY_REQUESTED = 'PIX_CLAIM_ALREADY_REQUESTED',
        PIX_CLAIM_ALREADY_EXISTS = 'PIX_CLAIM_ALREADY_EXISTS',
        REPORT_NOT_FOUND = 'REPORT_NOT_FOUND',
        INVALID_ATTACHMENT_MIN = 'INVALID_ATTACHMENT_MIN',
        INVALID_ATTACHMENT_MAX = 'INVALID_ATTACHMENT_MAX',
        INVALID_TICKET_SIZE = 'INVALID_TICKET_SIZE',
        INVALID_TICKET_PATTERN = 'INVALID_TICKET_PATTERN',
        INVALID_TICKET = 'INVALID_TICKET',
        PIX_DENIED = 'PIX_DENIED',
        PIX_UNABLE_TO_REFUND_INVALID_TRANSACTION = 'PIX_UNABLE_TO_REFUND_INVALID_TRANSACTION',
        PIX_UNABLE_TO_REFUND_INVALID_PAYEE_IN = 'PIX_UNABLE_TO_REFUND_INVALID_PAYEE_IN',
        PIX_UNABLE_TO_REFUND_INVALID_PAYEE_IN_ACCOUNT = 'PIX_UNABLE_TO_REFUND_INVALID_PAYEE_IN_ACCOUNT',
        PIX_UNABLE_TO_REFUND_INVALID_AMOUNT = 'PIX_UNABLE_TO_REFUND_INVALID_AMOUNT',
        PIX_UNABLE_TO_REFUND_INVALID_DATE = 'PIX_UNABLE_TO_REFUND_INVALID_DATE',
        PIX_NOT_FOUND = 'PIX_NOT_FOUND',
        PIX_UNABLE_TO_CANCEL = 'PIX_UNABLE_TO_CANCEL',
        PIX_END_TO_END_ID_ALREADY_EXISTS = 'PIX_END_TO_END_ID_ALREADY_EXISTS',
        PIX_ALREADY_EXISTS = 'PIX_ALREADY_EXISTS',
        PIX_COMMUNICATION_FAILURE = 'PIX_COMMUNICATION_FAILURE',
        PIX_COMMUNICATION_FAILURE_AT_BV = 'PIX_COMMUNICATION_FAILURE_AT_BV',
        PIX_AMOUNT_GREATER_THAN_LIMIT = 'PIX_AMOUNT_GREATER_THAN_LIMIT',
        PIX_RECURRENCE_NOT_ALLOWED = 'PIX_RECURRENCE_NOT_ALLOWED',
        PIX_PROCESSING_ANALYSIS = 'PIX_PROCESSING_ANALYSIS',
        PIX_RECEIVER_UNAVAILABLE = 'PIX_RECEIVER_UNAVAILABLE',
        PIX_RECEIVER_NOT_FOUND = 'PIX_RECEIVER_NOT_FOUND',
        PIX_PROCESSING_ERROR = 'PIX_PROCESSING_ERROR',
        PIX_TIMEOUT = 'PIX_TIMEOUT',
        PIX_ORIGINAL_CASHOUT_NOT_FOUND = 'PIX_ORIGINAL_CASHOUT_NOT_FOUND',
        PIX_REFUND_OUT_OF_DATE = 'PIX_REFUND_OUT_OF_DATE',
        PIX_AMOUNT_NOT_NULL = 'PIX_AMOUNT_NOT_NULL',
        PIX_MAX_AMOUNT_EXCEDED = 'PIX_MAX_AMOUNT_EXCEDED',
        PIX_MIN_AMOUNT_EXCEDED = 'PIX_MIN_AMOUNT_EXCEDED',
        PIX_MAX_DESCRIPTION_EXCEDED = 'PIX_MAX_DESCRIPTION_EXCEDED',
        PIX_MAX_TRANSACTION_IDENTIFICATION_EXCEDED = 'PIX_MAX_TRANSACTION_IDENTIFICATION_EXCEDED',
        INVALID_DUE_DATE = 'INVALID_DUE_DATE',
        PIX_MAX_END_TO_END_ID_EXCEDED = 'PIX_MAX_END_TO_END_ID_EXCEDED',
        PIX_TRANSACTION_ID_EXCEDED = 'PIX_TRANSACTION_ID_EXCEDED',
        PIX_TRANSACTION_ID_NOT_NULL = 'PIX_TRANSACTION_ID_NOT_NULL',
        PIX_QRCODE_KEY_NOT_BLANK = 'PIX_QRCODE_KEY_NOT_BLANK',
        PIX_QRCODE_KEY_EXCEDED = 'PIX_QRCODE_KEY_EXCEDED',
        PIX_QRCODE_DESCRIPTION_NOT_BLANK = 'PIX_QRCODE_DESCRIPTION_NOT_BLANK',
        PIX_QRCODE_DESCRIPTION_EXCEDED = 'PIX_QRCODE_DESCRIPTION_EXCEDED',
        PIX_QRCODE_AMOUNT_NOT_NULL = 'PIX_QRCODE_AMOUNT_NOT_NULL',
        PIX_QRCODE_AMOUNT_EXCEDED = 'PIX_QRCODE_AMOUNT_EXCEDED',
        PIX_QRCODE_BASE64_NOT_BLANK = 'PIX_QRCODE_BASE64_NOT_BLANK',
        PIX_QRCODE_BASE64_EXCEDED = 'PIX_QRCODE_BASE64_EXCEDED',
        PIX_QRCODE_TEXT_NOT_BLANK = 'PIX_QRCODE_TEXT_NOT_BLANK',
        PIX_QRCODE_TEXT_EXCEDED = 'PIX_QRCODE_TEXT_EXCEDED',
        PIX_QRCODE_IMAGE_TEXT_NOT_BLANK = 'PIX_QRCODE_IMAGE_TEXT_NOT_BLANK',
        PIX_QRCODE_IMAGE_TEXT_EXCEDED = 'PIX_QRCODE_IMAGE_TEXT_EXCEDED',
        INVALID_PIX_QRCODE = 'INVALID_PIX_QRCODE',
        PIX_QRCODE_HAS_EXPIRED = 'PIX_QRCODE_HAS_EXPIRED',
        PIX_QRCODE_ALREADY_PAID_OR_EXPIRED = 'PIX_QRCODE_ALREADY_PAID_OR_EXPIRED',
        INVALID_TRANSACTIONS_IDS = 'INVALID_TRANSACTIONS_IDS',
        INVALID_ENDTOENDORIGIN_SIZE = 'INVALID_ENDTOENDORIGIN_SIZE',
        QRCODE_NOT_FOUND = 'QRCODE_NOT_FOUND',
        SALE_BASKET_NOT_FOUND = 'SALE_BASKET_NOT_FOUND',
        POINT_SALE_QRCODE_NOT_FOUND = 'POINT_SALE_QRCODE_NOT_FOUND',
        SALE_BASKET_OWN_ID_EXISTS = 'SALE_BASKET_OWN_ID_EXISTS',
        VALUE_LIMIT_EXCEEDED = 'VALUE_LIMIT_EXCEEDED',
        SCOPE_NOT_FOUND = 'SCOPE_NOT_FOUND',
        SALE_BASKET_QRCODE_REQUIRED = 'SALE_BASKET_QRCODE_REQUIRED',
        SALE_BASKET_INVALID_AMOUNT = 'SALE_BASKET_INVALID_AMOUNT',
        SALE_BASKET_OWN_ID_REQUIRED = 'SALE_BASKET_OWN_ID_REQUIRED',
        SALE_BASKET_ITEMS_VALUE_INVALID = 'SALE_BASKET_ITEMS_VALUE_INVALID',
        SALE_BASKET_PRODUCT_ID_INVALID = 'SALE_BASKET_PRODUCT_ID_INVALID',
        SALE_BASKET_OWN_ID_LIMIT = 'SALE_BASKET_OWN_ID_LIMIT',
        SALE_BASKET_DISCOUNT_INVALID = 'SALE_BASKET_DISCOUNT_INVALID',
        SALE_BASKET_EXPIRATION_INVALID_MAX = 'SALE_BASKET_EXPIRATION_INVALID_MAX',
        SALE_BASKET_EXPIRATION_INVALID_MIN = 'SALE_BASKET_EXPIRATION_INVALID_MIN',
        SALE_BASKET_EXPIRATION_MALFORMED = 'SALE_BASKET_EXPIRATION_MALFORMED',
        SALE_BASKET_ITEM_DISCOUNT_INVALID = 'SALE_BASKET_ITEM_DISCOUNT_INVALID',
        SALE_BASKET_REPEATED_ITEMS_INVALID_VALUE = 'SALE_BASKET_REPEATED_ITEMS_INVALID_VALUE',
        SALE_BASKET_DUPLICATED_SKU_ITEMS = 'SALE_BASKET_DUPLICATED_SKU_ITEMS',
        SALE_BASKET_NOT_PENDING = 'SALE_BASKET_NOT_PENDING',
        LIMITER_QUANTITY_ORDER_IN_PERIOD_EXCEEDED = 'LIMITER_QUANTITY_ORDER_IN_PERIOD_EXCEEDED',
        LIMITER_TOTAL_ORDER_IN_PERIOD_EXCEEDED = 'LIMITER_TOTAL_ORDER_IN_PERIOD_EXCEEDED',
        SALE_BASKET_NOT_POSSIBLE_CANCEL = 'SALE_BASKET_NOT_POSSIBLE_CANCEL',
        SALES_BASKET_NOT_POSSIBLE_CANCEL = 'SALES_BASKET_NOT_POSSIBLE_CANCEL',
        SALE_BASKET_NOT_POSSIBLE_UPDATE = 'SALE_BASKET_NOT_POSSIBLE_UPDATE',
        START_DATE_REQUIRED = 'START_DATE_REQUIRED',
        END_DATE_REQUIRED = 'END_DATE_REQUIRED',
        PAGE_REQUIRED = 'PAGE_REQUIRED',
        QUANTITY_REQUIRED = 'QUANTITY_REQUIRED',
        START_DATE_INVALID = 'START_DATE_INVALID',
        END_DATE_INVALID = 'END_DATE_INVALID',
        KIT_NAME_INVALID = 'KIT_NAME_INVALID',
        KIT_QUANTITY_INVALID = 'KIT_QUANTITY_INVALID',
        KIT_ITEMS_NOT_EMPTY = 'KIT_ITEMS_NOT_EMPTY',
        POINT_SALE_ID_REQUIRED = 'POINT_SALE_ID_REQUIRED',
        POINT_SALE_ID_INVALID = 'POINT_SALE_ID_INVALID',
        STORE_DOCUMENT_REQUIRED = 'STORE_DOCUMENT_REQUIRED',
        ORDER_NOT_FINISHED = 'ORDER_NOT_FINISHED',
        ORDER_NOT_CONFIRMED = 'ORDER_NOT_CONFIRMED',
        ORDER_NOT_CANCELED = 'ORDER_NOT_CANCELED',
        ORDER_CANCELED = 'ORDER_CANCELED',
        ORDER_NOT_REVERSED = 'ORDER_NOT_REVERSED',
        ORDER_REVERSE_LIMIT_EXCEEDED = 'ORDER_REVERSE_LIMIT_EXCEEDED',
        INVALID_QRCODE_ALIAS = 'INVALID_QRCODE_ALIAS',
        SENDING_QRCODE_AND_ALIAS = 'SENDING_QRCODE_AND_ALIAS',
        UNAUTHORIZED_CELL_PHONE_NUMBER = 'UNAUTHORIZED_CELL_PHONE_NUMBER',
        EXPIRED_PURCHASE = 'EXPIRED_PURCHASE',
        NONEXISTENT_PURCHASE = 'NONEXISTENT_PURCHASE',
        PURCHASE_ALREADY_CONFIRMED = 'PURCHASE_ALREADY_CONFIRMED',
        TRANSACTION_NOT_ALLOWED = 'TRANSACTION_NOT_ALLOWED',
        PHONE_LOCKED_FOR_RECHARGE = 'PHONE_LOCKED_FOR_RECHARGE',
        OPERATION_NOT_PERFORMED_GENERIC_PROVIDER_ERROR = 'OPERATION_NOT_PERFORMED_GENERIC_PROVIDER_ERROR',
        INVALID_CLIENT_CODE = 'INVALID_CLIENT_CODE',
        AMOUNT_NOT_ALLOWED = 'AMOUNT_NOT_ALLOWED',
        SIGNER_CODE_REQUIRED = 'SIGNER_CODE_REQUIRED',
        INSUFFICIENT_CREDIT_LIMIT = 'INSUFFICIENT_CREDIT_LIMIT',
        INSUFFICIENT_INVENTORY = 'INSUFFICIENT_INVENTORY',
        MAXIMUM_NUMBER_OF_CONNECTIONS_REACHED = 'MAXIMUM_NUMBER_OF_CONNECTIONS_REACHED',
        USER_WITHOUT_ACCESS_LEVEL = 'USER_WITHOUT_ACCESS_LEVEL',
        CHARGE_NOT_YET_VIEWED = 'CHARGE_NOT_YET_VIEWED',
        BLOCKED_ESTABLISHMENT = 'BLOCKED_ESTABLISHMENT',
        INCOMPLETE_OR_INVALID_CELL_PHONE_NUMBER = 'INCOMPLETE_OR_INVALID_CELL_PHONE_NUMBER',
        PROVIDER_OR_PRODUCT_NOT_FOUND = 'PROVIDER_OR_PRODUCT_NOT_FOUND',
        INVALID_AMOUNT = 'INVALID_AMOUNT',
        PIX_OUT_POINT_SALE_ID_INVALID_SIZE = 'PIX_OUT_POINT_SALE_ID_INVALID_SIZE',
        PIX_OUT_SALES_CHANNEL_REQUIRED = 'PIX_OUT_SALES_CHANNEL_REQUIRED',
        PIX_OUT_INTEGRADOR_INVALID_SIZE = 'PIX_OUT_INTEGRADOR_INVALID_SIZE',
        PIX_OUT_INTEGRADOR_REQUIRED = 'PIX_OUT_INTEGRADOR_REQUIRED',
        PIX_OUT_ITEM_PRODUCT_ID_INVALID_SIZE = 'PIX_OUT_ITEM_PRODUCT_ID_INVALID_SIZE',
        PIX_OUT_ITEM_VALUE_SIZE_MIN = 'PIX_OUT_ITEM_VALUE_SIZE_MIN',
        PIX_OUT_ITEM_QUANTITY_SIZE_MIN = 'PIX_OUT_ITEM_QUANTITY_SIZE_MIN',
        PIX_OUT_ITEM_SKU_REQUIRED = 'PIX_OUT_ITEM_SKU_REQUIRED',
        PIX_OUT_ITEM_VALUE_REQUIRED = 'PIX_OUT_ITEM_VALUE_REQUIRED',
        PIX_OUT_ITEM_NAME_REQUIRED = 'PIX_OUT_ITEM_NAME_REQUIRED',
        PIX_OUT_ITEM_QUANTITY_REQUIRED = 'PIX_OUT_ITEM_QUANTITY_REQUIRED',
        PIX_OUT_OWNID_REQUIRED = 'PIX_OUT_OWNID_REQUIRED',
        PIX_OUT_POINT_SALE_ID_REQUIRED = 'PIX_OUT_POINT_SALE_ID_REQUIRED',
        ORDER_CANCELLATION_LIMIT_EXCEEDED = 'ORDER_CANCELLATION_LIMIT_EXCEEDED',
        UNABLE_TO_CREATE_PDVTOKEN_ORDER = 'UNABLE_TO_CREATE_PDVTOKEN_ORDER',
        UNABLE_TO_CREATE_OWNID_ORDER = 'UNABLE_TO_CREATE_OWNID_ORDER',
        ORDER_PDVTOKEN_NOT_BLANK = 'ORDER_PDVTOKEN_NOT_BLANK',
        INVALID_SALES_CHANNEL_TYPE = 'INVALID_SALES_CHANNEL_TYPE',
        AUTHORIZATION_PARTNER_NOT_FOUND = 'AUTHORIZATION_PARTNER_NOT_FOUND',
        AUTHORIZATION_PARTNER_ALREADY_EXISTS = 'AUTHORIZATION_PARTNER_ALREADY_EXISTS',
        SALES_CHANNEL_ALREADY_EXISTS = 'SALES_CHANNEL_ALREADY_EXISTS',
        PIX_OUT_SALE_BASKET_IS_NOT_CONFIRMED = 'PIX_OUT_SALE_BASKET_IS_NOT_CONFIRMED',
        QRCODE_PIX_NOT_PAID = 'QRCODE_PIX_NOT_PAID',
        TOKEN_AUTHORIZATION_PARTNER_ALREADY_EXISTS = 'TOKEN_AUTHORIZATION_PARTNER_ALREADY_EXISTS',
        INVALID_AUTHORIZATION_PARTNER_NAME = 'INVALID_AUTHORIZATION_PARTNER_NAME',
        INVALID_AUTHORIZATION_PARTNER_UUID = 'INVALID_AUTHORIZATION_PARTNER_UUID',
        INVALID_STATUS = 'INVALID_STATUS',
        INVALID_PARTNER_NAME_SIZE = 'INVALID_PARTNER_NAME_SIZE',
        INVALID_TOKEN_SIZE = 'INVALID_TOKEN_SIZE',
        INVALID_TOKEN_EMPTY = 'INVALID_TOKEN_EMPTY',
        INVALID_PARTNER_UUID_SIZE = 'INVALID_PARTNER_UUID_SIZE',
        INVALID_PARTNER_FANTASY_NAME = 'INVALID_PARTNER_FANTASY_NAME',
        INVALID_PARTNER_PREFIX = 'INVALID_PARTNER_PREFIX',
        INVALID_AUTHORIZATION_PARTNER_STATUS = 'INVALID_AUTHORIZATION_PARTNER_STATUS',
        INVALID_PREFIX_LIMIT = 'INVALID_PREFIX_LIMIT',
        INVALID_PARTNER = 'INVALID_PARTNER',
        INVALID_AUTHORIZATION_PARTNER_ID = 'INVALID_AUTHORIZATION_PARTNER_ID',
        INVALID_PARTNER_ID_SIZE = 'INVALID_PARTNER_ID_SIZE',
        PARTNER_NOT_LINKED_STORE = 'PARTNER_NOT_LINKED_STORE',
        PARTNER_NOT_LINKED_QRCODE = 'PARTNER_NOT_LINKED_QRCODE',
        SALES_CHANNEL_NOT_LINKED_AUTHORIZATION = 'SALES_CHANNEL_NOT_LINKED_AUTHORIZATION',
        PARTNER_FANTASY_NAME_ALREADY_EXISTS = 'PARTNER_FANTASY_NAME_ALREADY_EXISTS',
        REPORT_QRCODE_MAIL_REQUIRED = 'REPORT_QRCODE_MAIL_REQUIRED',
        REPORT_QRCODE_DATE_START_REQUIRED = 'REPORT_QRCODE_DATE_START_REQUIRED',
        REPORT_QRCODE_DATE_END_REQUIRED = 'REPORT_QRCODE_DATE_END_REQUIRED',
        REPORT_QRCODE_SALES_CHANNEL_INVALID = 'REPORT_QRCODE_SALES_CHANNEL_INVALID',
        REQUIRED_DEPARTMENT_NAME = 'REQUIRED_DEPARTMENT_NAME',
        REQUIRED_DEPARTMENT = 'REQUIRED_DEPARTMENT',
        DEPARTMENT_NOT_FOUND = 'DEPARTMENT_NOT_FOUND',
        ULTRAGAZ_INVALID_VOUCHER_NUMBER = 'ULTRAGAZ_INVALID_VOUCHER_NUMBER',
        ULTRAGAZ_INVALID_VOUCHER_NUMBER_SIZE = 'ULTRAGAZ_INVALID_VOUCHER_NUMBER_SIZE',
        ULTRAGAZ_INVALID_LOG_RESELLERS_SEARCHED_ID = 'ULTRAGAZ_INVALID_LOG_RESELLERS_SEARCHED_ID',
        ULTRAGAZ_INVALID_LOG_RESELLERS_SEARCHED_ID_SIZE = 'ULTRAGAZ_INVALID_LOG_RESELLERS_SEARCHED_ID_SIZE',
        ULTRAGAZ_INVALID_PRODUCT_ID_SIZE = 'ULTRAGAZ_INVALID_PRODUCT_ID_SIZE',
        ULTRAGAZ_INVALID_DESCRIPTION_SIZE = 'ULTRAGAZ_INVALID_DESCRIPTION_SIZE',
        ULTRAGAZ_INVALID_QUANTITY = 'ULTRAGAZ_INVALID_QUANTITY',
        ULTRAGAZ_INVALID_QUANTITY_SIZE = 'ULTRAGAZ_INVALID_QUANTITY_SIZE',
        ULTRAGAZ_INVALID_AMOUNT = 'ULTRAGAZ_INVALID_AMOUNT',
        ULTRAGAZ_INVALID_AMOUNT_SIZE = 'ULTRAGAZ_INVALID_AMOUNT_SIZE',
        ULTRAGAZ_INVALID_SEARCH_ID = 'ULTRAGAZ_INVALID_SEARCH_ID',
        ULTRAGAZ_INVALID_SEARCH_ID_SIZE = 'ULTRAGAZ_INVALID_SEARCH_ID_SIZE',
        ULTRAGAZ_INVALID_RESELLER_SEARCHED_ID = 'ULTRAGAZ_INVALID_RESELLER_SEARCHED_ID',
        ULTRAGAZ_INVALID_RESELLER_SEARCHED_ID_SIZE = 'ULTRAGAZ_INVALID_RESELLER_SEARCHED_ID_SIZE',
        ULTRAGAZ_INVALID_SCHEDULE_DATE_SIZE = 'ULTRAGAZ_INVALID_SCHEDULE_DATE_SIZE',
        ULTRAGAZ_INVALID_SCHEDULE_HOUR_ID_SIZE = 'ULTRAGAZ_INVALID_SCHEDULE_HOUR_ID_SIZE',
        ULTRAGAZ_INVALID_CUSTOMER_ID_SIZE = 'ULTRAGAZ_INVALID_CUSTOMER_ID_SIZE',
        ULTRAGAZ_INVALID_STORE_DOCUMENT = 'ULTRAGAZ_INVALID_STORE_DOCUMENT',
        ULTRAGAZ_INVALID_STORE_DOCUMENT_SIZE = 'ULTRAGAZ_INVALID_STORE_DOCUMENT_SIZE',
        ULTRAGAZ_INVALID_ORDER_ID_SIZE = 'ULTRAGAZ_INVALID_ORDER_ID_SIZE',
        ULTRAGAZ_INVALID_OWN_ID_SIZE = 'ULTRAGAZ_INVALID_OWN_ID_SIZE',
        ULTRAGAZ_INVALID_STORE_NAME_SIZE = 'ULTRAGAZ_INVALID_STORE_NAME_SIZE',
        ULTRAGAZ_INVALID_ULTRAGAZ_ORDER_ID_SIZE = 'ULTRAGAZ_INVALID_ULTRAGAZ_ORDER_ID_SIZE',
        ULTRAGAZ_INVALID_PAYMENT_METHOD_CODE_SIZE = 'ULTRAGAZ_INVALID_PAYMENT_METHOD_CODE_SIZE',
        ULTRAGAZ_INVALID_STATUS = 'ULTRAGAZ_INVALID_STATUS',
        ULTRAGAZ_INVALID_ID_CANCEL_REASON_SIZE = 'ULTRAGAZ_INVALID_ID_CANCEL_REASON_SIZE',
        ULTRAGAZ_INVALID_CODE_CANCEL_REASON = 'ULTRAGAZ_INVALID_CODE_CANCEL_REASON',
        ULTRAGAZ_INVALID_CODE_CANCEL_REASON_SIZE = 'ULTRAGAZ_INVALID_CODE_CANCEL_REASON_SIZE',
        ULTRAGAZ_INVALID_RECEIVED = 'ULTRAGAZ_INVALID_RECEIVED',
        ULTRAGAZ_INVALID_RECEIVED_SIZE = 'ULTRAGAZ_INVALID_RECEIVED_SIZE',
        ULTRAGAZ_INVALID_RATING = 'ULTRAGAZ_INVALID_RATING',
        ULTRAGAZ_INVALID_RATING_SIZE = 'ULTRAGAZ_INVALID_RATING_SIZE',
        ULTRAGAZ_INVALID_COMMENTS_SIZE = 'ULTRAGAZ_INVALID_COMMENTS_SIZE',
        ULTRAGAZ_INVALID_NOTE_SIZE = 'ULTRAGAZ_INVALID_NOTE_SIZE',
        ULTRAGAZ_ADDRESS_NOT_FOUND = 'ULTRAGAZ_ADDRESS_NOT_FOUND',
        USER_ACCESS_TOKEN_REQUIRED = 'USER_ACCESS_TOKEN_REQUIRED',
        INVALID_USER_ACCESS_TOKEN = 'INVALID_USER_ACCESS_TOKEN',
        USERS_DEPARTMENT_IS_NOT_INTERNAL = 'USERS_DEPARTMENT_IS_NOT_INTERNAL',
        USER_NOT_AUTHORIZED_EXCEPTION = 'USER_NOT_AUTHORIZED_EXCEPTION',
        USER_EMAIL_REQUIRED_EXCEPTION = 'USER_EMAIL_REQUIRED_EXCEPTION',
        USER_PASSWORD_REQUIRED_EXCEPTION = 'USER_PASSWORD_REQUIRED_EXCEPTION',
        INVALID_RESELLER_IS_CLOSED = 'INVALID_RESELLER_IS_CLOSED',
        ULTRAGAZ_ADDRESS_NOT_IN_RANGE = 'ULTRAGAZ_ADDRESS_NOT_IN_RANGE',
        ULTRAGAZ_RESELLER_IS_CLOSED = 'ULTRAGAZ_RESELLER_IS_CLOSED',
        ULTRAGAZ_ORDER_IN_PROGRESS = 'ULTRAGAZ_ORDER_IN_PROGRESS',
        ULTRAGAZ_SESSION_EXPIRED = 'ULTRAGAZ_SESSION_EXPIRED',
        ULTRAGAZ_CANCELLATION_FAILED = 'ULTRAGAZ_CANCELLATION_FAILED',
        ULTRAGAZ_CANCELLATION_NOT_ALLOWED = 'ULTRAGAZ_CANCELLATION_NOT_ALLOWED',
        ULTRAGAZ_PRODUCT_NOT_FOUND = 'ULTRAGAZ_PRODUCT_NOT_FOUND',
        ULTRAGAZ_ORDER_EXCEEDED_ABANDONED_LIMIT = 'ULTRAGAZ_ORDER_EXCEEDED_ABANDONED_LIMIT',
        ULTRAGAZ_INVALID_CANCELLATION_REASON = 'ULTRAGAZ_INVALID_CANCELLATION_REASON',
        ULTRAGAZ_ERRATIC_ADDRESS = 'ULTRAGAZ_ERRATIC_ADDRESS',
        TDAZUL_ORDER_EXCEEDED_ABANDONED_LIMIT = 'TDAZUL_ORDER_EXCEEDED_ABANDONED_LIMIT',
        TDAZUL_PRODUCT_CUSTOM_INVALID_POINTS_INFORMED = 'TDAZUL_PRODUCT_CUSTOM_INVALID_POINTS_INFORMED',
        TDAZUL_PRODUCT_CUSTOM_MULTIPLE_FOUND = 'TDAZUL_PRODUCT_CUSTOM_MULTIPLE_FOUND',
        TDAZUL_PRODUCT_CUSTOM_NOT_FOUND = 'TDAZUL_PRODUCT_CUSTOM_NOT_FOUND',
        TDAZUL_PRODUCT_NOT_FOUND = 'TDAZUL_PRODUCT_NOT_FOUND',
        TDAZUL_INSUFFICIENT_KM_BALANCE = 'TDAZUL_INSUFFICIENT_KM_BALANCE',
        INVALID_CARD_BRAND_NAME = 'INVALID_CARD_BRAND_NAME',
        INVALID_CARD_BRAND_NAME_SIZE = 'INVALID_CARD_BRAND_NAME_SIZE',
        INVALID_CARD_BRAND_REGEX = 'INVALID_CARD_BRAND_REGEX',
        INVALID_CARD_BRAND_REGEX_SIZE = 'INVALID_CARD_BRAND_REGEX_SIZE',
        INVALID_CARD_BRAND_ICONURL = 'INVALID_CARD_BRAND_ICONURL',
        INVALID_CARD_BRAND_ICON_URL_SIZE_MAX = 'INVALID_CARD_BRAND_ICON_URL_SIZE_MAX',
        INVALID_CARD_BRAND_MIN_NUMBER_LENGTH = 'INVALID_CARD_BRAND_MIN_NUMBER_LENGTH',
        INVALID_CARD_BRAND_MIN_NUMBER_LENGTH_MAX_VALUE = 'INVALID_CARD_BRAND_MIN_NUMBER_LENGTH_MAX_VALUE',
        INVALID_CARD_BRAND_MAX_NUMBER_LENGTH = 'INVALID_CARD_BRAND_MAX_NUMBER_LENGTH',
        INVALID_CARD_BRAND_MAX_NUMBER_LENGTH_MAX_VALUE = 'INVALID_CARD_BRAND_MAX_NUMBER_LENGTH_MAX_VALUE',
        INVALID_CARD_BRAND_CVV_MIN = 'INVALID_CARD_BRAND_CVV_MIN',
        INVALID_CARD_BRAND_MIN_CVV_LENGTH_MAX_VALUE = 'INVALID_CARD_BRAND_MIN_CVV_LENGTH_MAX_VALUE',
        INVALID_CARD_BRAND_CVV_MAX = 'INVALID_CARD_BRAND_CVV_MAX',
        INVALID_CARD_BRAND_MAX_CVV_LENGTH_MAX_VALUE = 'INVALID_CARD_BRAND_MAX_CVV_LENGTH_MAX_VALUE',
        INVALID_CARD_BRAND_MASK_NUMBER = 'INVALID_CARD_BRAND_MASK_NUMBER',
        INVALID_CARD_BRAND_RANGE_NUMBERMASK_SIZE = 'INVALID_CARD_BRAND_RANGE_NUMBERMASK_SIZE',
        INVALID_CARD_BANK_NUMBER = 'INVALID_CARD_BANK_NUMBER',
        INVALID_CARD_BANK_NUMBER_MAX_SIZE = 'INVALID_CARD_BANK_NUMBER_MAX_SIZE',
        INVALID_CARD_BANK_NAME = 'INVALID_CARD_BANK_NAME',
        INVALID_CARD_BANK_NAME_MAX_SIZE = 'INVALID_CARD_BANK_NAME_MAX_SIZE',
        INVALID_CARD_BANK_RESTRICTION_LABEL = 'INVALID_CARD_BANK_RESTRICTION_LABEL',
        INVALID_CARD_BANK_RESTRICTION_MAX_SIZE = 'INVALID_CARD_BANK_RESTRICTION_MAX_SIZE',
        INVALID_CARD_BANK_VALIDATION_FAILED_MESSAGE = 'INVALID_CARD_BANK_VALIDATION_FAILED_MESSAGE',
        INVALID_CARD_BANK_VALIDATION_FAILED_MESSAGE_MAX_SIZE = 'INVALID_CARD_BANK_VALIDATION_FAILED_MESSAGE_MAX_SIZE',
        INVALID_CARD_BANK_BIN_RESTRICT = 'INVALID_CARD_BANK_BIN_RESTRICT',
        INVALID_CARD_BANK_REQUEST = 'INVALID_CARD_BANK_REQUEST',
        INVALID_CARD_BANK_ONBOARDING_SCREEN_REQUEST = 'INVALID_CARD_BANK_ONBOARDING_SCREEN_REQUEST',
        INVALID_CARD_BANK_ONBOARDING_SCREEN_TITLE = 'INVALID_CARD_BANK_ONBOARDING_SCREEN_TITLE',
        INVALID_CARD_BANK_ONBOARDING_SCREEN_TITLE_MAX_SIZE = 'INVALID_CARD_BANK_ONBOARDING_SCREEN_TITLE_MAX_SIZE',
        INVALID_CARD_BANK_ONBOARDING_SCREEN_DETAILS_MAX_SIZE = 'INVALID_CARD_BANK_ONBOARDING_SCREEN_DETAILS_MAX_SIZE',
        INVALID_CARD_BANK_ONBOARDING_SCREEN_PRIMARY_ACTION_BUTTON_LABEL_MAX_SIZE = 'INVALID_CARD_BANK_ONBOARDING_SCREEN_PRIMARY_ACTION_BUTTON_LABEL_MAX_SIZE',
        INVALID_CARD_BANK_ONBOARDING_SCREEN_SECONDARY_ACTION_BUTTON_LABEL_MAX_SIZE = 'INVALID_CARD_BANK_ONBOARDING_SCREEN_SECONDARY_ACTION_BUTTON_LABEL_MAX_SIZE',
        INVALID_CARD_BANK_ONBOARDING_SCREEN_BOTTOM_SHEET_ACTION_BUTTON_LABEL_MAX_SIZE = 'INVALID_CARD_BANK_ONBOARDING_SCREEN_BOTTOM_SHEET_ACTION_BUTTON_LABEL_MAX_SIZE',
        INVALID_CARD_BANK_ONBOARDING_SCREEN_BOTTOM_SHEET_TITLE_MAX_SIZE = 'INVALID_CARD_BANK_ONBOARDING_SCREEN_BOTTOM_SHEET_TITLE_MAX_SIZE',
        INVALID_CARD_BANK_ONBOARDING_SCREEN_BOTTOM_SHEET_DETAILS_MAX_SIZE = 'INVALID_CARD_BANK_ONBOARDING_SCREEN_BOTTOM_SHEET_DETAILS_MAX_SIZE',
        INVALID_CARD_BANK_CARD_BANK_BIN_REQUESTS = 'INVALID_CARD_BANK_CARD_BANK_BIN_REQUESTS',
        INVALID_CARD_BANK_TITLE = 'INVALID_CARD_BANK_TITLE',
        INVALID_CARD_BANK_MAX_SIZE = 'INVALID_CARD_BANK_MAX_SIZE',
        INVALID_CARD_BANK_BIN = 'INVALID_CARD_BANK_BIN',
        INVALID_CARD_BANK_BIN_SIZE = 'INVALID_CARD_BANK_BIN_SIZE',
        CARD_BANK_BRAND_NOT_FOUND = 'CARD_BANK_BRAND_NOT_FOUND',
        CARD_BANK_BRAND_ALREADY_EXISTS = 'CARD_BANK_BRAND_ALREADY_EXISTS',
        DIGITAL_WALLET_PERMISSION_DENIED = 'DIGITAL_WALLET_PERMISSION_DENIED',
        DIGITAL_WALLET_NOT_FOUND = 'DIGITAL_WALLET_NOT_FOUND',
        DIGITAL_WALLET_ALREADY_EXCLUDE = 'DIGITAL_WALLET_ALREADY_EXCLUDE',
        DIGITAL_WALLET_UNABLE_VALIDATION = 'DIGITAL_WALLET_UNABLE_VALIDATION',
        DIGITAL_WALLLET_ALREADY_PRINCIPAL = 'DIGITAL_WALLLET_ALREADY_PRINCIPAL',
        DIGITAL_WALLLET_INVALID = 'DIGITAL_WALLLET_INVALID',
        DIGITAL_WALLET_PATTERN_MAX_SIZE = 'DIGITAL_WALLET_PATTERN_MAX_SIZE',
        DIGITAL_WALLET_OWNID_MAX_SIZE = 'DIGITAL_WALLET_OWNID_MAX_SIZE',
        DIGITAL_WALLET_TOKEN_MAX_SIZE = 'DIGITAL_WALLET_TOKEN_MAX_SIZE',
        DIGITAL_WALLET_PATTERN_NOT_BLANK = 'DIGITAL_WALLET_PATTERN_NOT_BLANK',
        DIGITAL_WALLET_OWNID_NOT_BLANK = 'DIGITAL_WALLET_OWNID_NOT_BLANK',
        DIGITAL_WALLET_TOKEN_NOT_BLANK = 'DIGITAL_WALLET_TOKEN_NOT_BLANK',
        DIGITAL_WALLET_INVALID_BRAND_TYPE = 'DIGITAL_WALLET_INVALID_BRAND_TYPE',
        DIGITAL_WALLET_INVALID_BRAND = 'DIGITAL_WALLET_INVALID_BRAND',
        DATE_START_GREATER_THAN_DATE_END = 'DATE_START_GREATER_THAN_DATE_END',
        AUTHORIZATION_PARTNER_ERROR = 'AUTHORIZATION_PARTNER_ERROR',
        CUSTOMER_SMS_BIOMETRY_ERROR = 'CUSTOMER_SMS_BIOMETRY_ERROR',
        MAX_ATTEMPTS_EXCEEDED_EXCEPTION = 'MAX_ATTEMPTS_EXCEEDED_EXCEPTION',
        GENERATED_JWT_ERROR = 'GENERATED_JWT_ERROR',
        SALES_CHANNEL_INVALID = 'SALES_CHANNEL_INVALID',
        TRANSACTION_PIX_STATUS_INVALID = 'TRANSACTION_PIX_STATUS_INVALID',
        INVALID_TRANSACTION_LIMITER_PERIOD_TYPE = 'INVALID_TRANSACTION_LIMITER_PERIOD_TYPE',
        INVALID_TRANSACTION_LIMITER_TYPE_TRANSACTION = 'INVALID_TRANSACTION_LIMITER_TYPE_TRANSACTION',
        TRANSACTION_LIMITER_NOT_FOUND = 'TRANSACTION_LIMITER_NOT_FOUND',
        TRANSACTION_LIMITER_ALREADY_REQUESTED = 'TRANSACTION_LIMITER_ALREADY_REQUESTED',
        INVALID_TRANSACTION_LIMITER_PERIOD_TYPE_NOT_BLANK = 'INVALID_TRANSACTION_LIMITER_PERIOD_TYPE_NOT_BLANK',
        INVALID_TRANSACTION_LIMITER_TYPE_TRANSACTION_NOT_BLANK = 'INVALID_TRANSACTION_LIMITER_TYPE_TRANSACTION_NOT_BLANK',
        INVALID_TRANSACTION_LIMITER_PERIOD_LIMIT = 'INVALID_TRANSACTION_LIMITER_PERIOD_LIMIT',
        INVALID_TRANSACTION_LIMITER_TRANSACTION_LIMIT = 'INVALID_TRANSACTION_LIMITER_TRANSACTION_LIMIT',
        INVALID_TRANSACTION_LIMITER_START_PERIOD = 'INVALID_TRANSACTION_LIMITER_START_PERIOD',
        INVALID_ADDRESS_CITY_NOT_BLANK = 'INVALID_ADDRESS_CITY_NOT_BLANK',
        INVALID_ADDRESS_CITY_MAX_SIZED = 'INVALID_ADDRESS_CITY_MAX_SIZED',
        INVALID_ADDRESS_TYPE_NOT_BLANK = 'INVALID_ADDRESS_TYPE_NOT_BLANK',
        INVALID_ADDRESS_TYPE_MAX_SIZED = 'INVALID_ADDRESS_TYPE_MAX_SIZED',
        INVALID_ADDRESS_ZIP_NOT_BLANK = 'INVALID_ADDRESS_ZIP_NOT_BLANK',
        INVALID_ADDRESS_ZIP_MAX_SIZED = 'INVALID_ADDRESS_ZIP_MAX_SIZED',
        INVALID_ADDRESS_STREET_NOT_BLANK = 'INVALID_ADDRESS_STREET_NOT_BLANK',
        INVALID_ADDRESS_STREET_MAX_SIZED = 'INVALID_ADDRESS_STREET_MAX_SIZED',
        INVALID_ADDRESS_NUMBER_NOT_BLANK = 'INVALID_ADDRESS_NUMBER_NOT_BLANK',
        INVALID_ADDRESS_NUMBER_MAX_SIZED = 'INVALID_ADDRESS_NUMBER_MAX_SIZED',
        INVALID_ADDRESS_COMPLEMENT_MAX_SIZED = 'INVALID_ADDRESS_COMPLEMENT_MAX_SIZED',
        INVALID_ADDRESS_DISTRICT_NOT_BLANK = 'INVALID_ADDRESS_DISTRICT_NOT_BLANK',
        INVALID_ADDRESS_DISTRICT_MAX_SIZED = 'INVALID_ADDRESS_DISTRICT_MAX_SIZED',
        INVALID_ADDRESS_STATE_NOT_BLANK = 'INVALID_ADDRESS_STATE_NOT_BLANK',
        INVALID_ADDRESS_STATE_MAX_SIZED = 'INVALID_ADDRESS_STATE_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_ID = 'INVALID_CUSTOMER_LOAN_ID',
        INVALID_ADDRESS_ID = 'INVALID_ADDRESS_ID',
        INVALID_ADDRESS_ALREADY_EXIST = 'INVALID_ADDRESS_ALREADY_EXIST',
        INVALID_BANNER_THUMBURL_NOT_BLANK = 'INVALID_BANNER_THUMBURL_NOT_BLANK',
        INVALID_BANNER_THUMBURL_MAX_SIZED = 'INVALID_BANNER_THUMBURL_MAX_SIZED',
        INVALID_BANNER_TITLE_NOT_BLANK = 'INVALID_BANNER_TITLE_NOT_BLANK',
        INVALID_BANNER_TITLE_MAX_SIZED = 'INVALID_BANNER_TITLE_MAX_SIZED',
        INVALID_BANNER_BODY_NOT_BLANK = 'INVALID_BANNER_BODY_NOT_BLANK',
        INVALID_BANNER_BODY_MAX_SIZED = 'INVALID_BANNER_BODY_MAX_SIZED',
        INVALID_BANNER_ID = 'INVALID_BANNER_ID',
        INVALID_BANNER_NOT_FOUND = 'INVALID_BANNER_NOT_FOUND',
        INVALID_CONTACT_TYPE_NOT_BLANK = 'INVALID_CONTACT_TYPE_NOT_BLANK',
        INVALID_CONTACT_TYPE_MAX_SIZED = 'INVALID_CONTACT_TYPE_MAX_SIZED',
        INVALID_CONTACT_AREA_CODE_NOT_BLANK = 'INVALID_CONTACT_AREA_CODE_NOT_BLANK',
        INVALID_CONTACT_AREA_CODE_MAX_SIZED = 'INVALID_CONTACT_AREA_CODE_MAX_SIZED',
        INVALID_CONTACT_NUMBER_NOT_BLANK = 'INVALID_CONTACT_NUMBER_NOT_BLANK',
        INVALID_CONTACT_NUMBER_MAX_SIZED = 'INVALID_CONTACT_NUMBER_MAX_SIZED',
        INVALID_CONTACT_ID = 'INVALID_CONTACT_ID',
        INVALID_CONTACT_CONTACT_NOT_FOUND = 'INVALID_CONTACT_CONTACT_NOT_FOUND',
        INVALID_CUSTOMER_DOCUMENT_ISSUE_DATE_MAX_SIZED = 'INVALID_CUSTOMER_DOCUMENT_ISSUE_DATE_MAX_SIZED',
        INVALID_CUSTOMER_DOCUMENT_ISSUE_DATE = 'INVALID_CUSTOMER_DOCUMENT_ISSUE_DATE',
        INVALID_CUSTOMER_DOCUMENT_ISSUE_AUTHORITY_MAX_SIZED = 'INVALID_CUSTOMER_DOCUMENT_ISSUE_AUTHORITY_MAX_SIZED',
        INVALID_CUSTOMER_DOCUMENT_ISSUE_STATE_MAX_SIZED = 'INVALID_CUSTOMER_DOCUMENT_ISSUE_STATE_MAX_SIZED',
        INVALID_CUSTOMER_DOCUMENT_TYPE = 'INVALID_CUSTOMER_DOCUMENT_TYPE',
        INVALID_CUSTOMER_DOCUMENT_NOT_FOUND = 'INVALID_CUSTOMER_DOCUMENT_NOT_FOUND',
        INVALID_SIMULATION_QUOTE_ID = 'INVALID_SIMULATION_QUOTE_ID',
        INVALID_PRODUCT = 'INVALID_PRODUCT',
        INVALID_CUSTOMER_LOAN_DOCUMENT = 'INVALID_CUSTOMER_LOAN_DOCUMENT',
        INVALID_CUSTOMER_LOAN_DOCUMENT_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_DOCUMENT_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_DOCUMENT_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_DOCUMENT_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_TYPE_PEOPLE = 'INVALID_CUSTOMER_LOAN_TYPE_PEOPLE',
        INVALID_CUSTOMER_LOAN_TYPE_PEOPLE_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_TYPE_PEOPLE_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_TYPE_PEOPLE_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_TYPE_PEOPLE_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_FIRST_NAME_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_FIRST_NAME_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_FIRST_NAME_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_FIRST_NAME_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_LAST_NAME_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_LAST_NAME_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_LAST_NAME_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_LAST_NAME_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_FULL_NAME_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_FULL_NAME_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_FULL_NAME_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_FULL_NAME_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_GENDER_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_GENDER_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_GENDER_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_GENDER_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_BITHDATE = 'INVALID_CUSTOMER_LOAN_BITHDATE',
        INVALID_CUSTOMER_LOAN_BITHDATE_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_BITHDATE_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_MARITAL_STATUS = 'INVALID_CUSTOMER_LOAN_MARITAL_STATUS',
        INVALID_CUSTOMER_LOAN_MOTHER_NAME_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_MOTHER_NAME_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_MOTHLY_INCOME_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_MOTHLY_INCOME_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_MOTHLY_INCOME_MIN = 'INVALID_CUSTOMER_LOAN_MOTHLY_INCOME_MIN',
        INVALID_CUSTOMER_LOAN_NACIONALITY_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_NACIONALITY_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_NACIONALITY_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_NACIONALITY_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_OCCUPATION_CODE_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_OCCUPATION_CODE_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_OCCUPATION_CODE_MIN = 'INVALID_CUSTOMER_LOAN_OCCUPATION_CODE_MIN',
        INVALID_CUSTOMER_LOAN_OCCUPATION_CODE_MAX = 'INVALID_CUSTOMER_LOAN_OCCUPATION_CODE_MAX',
        INVALID_CUSTOMER_LOAN_OCCUPATION_TYPE_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_OCCUPATION_TYPE_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_OCCUPATION_TYPE_MIN = 'INVALID_CUSTOMER_LOAN_OCCUPATION_TYPE_MIN',
        INVALID_CUSTOMER_LOAN_OCCUPATION_TYPE_MAX = 'INVALID_CUSTOMER_LOAN_OCCUPATION_TYPE_MAX',
        INVALID_CUSTOMER_LOAN_TOTAL_PATRIMONY_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_TOTAL_PATRIMONY_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_TOTAL_PATRIMONY_MIN = 'INVALID_CUSTOMER_LOAN_TOTAL_PATRIMONY_MIN',
        INVALID_CUSTOMER_LOAN_TOTAL_PATRIMONY_DIGIT_INVALID = 'INVALID_CUSTOMER_LOAN_TOTAL_PATRIMONY_DIGIT_INVALID',
        INVALID_CUSTOMER_LOAN_INVALID_NOT_FOUND = 'INVALID_CUSTOMER_LOAN_INVALID_NOT_FOUND',
        INVALID_CUSTOMER_LOAN_INVALID_ALREADY_EXISTS = 'INVALID_CUSTOMER_LOAN_INVALID_ALREADY_EXISTS',
        INVALID_CUSTOMER_LOAN_BIRTHCITY__NOT_BLANK = 'INVALID_CUSTOMER_LOAN_BIRTHCITY__NOT_BLANK',
        INVALID_CUSTOMER_LOAN_BIRTHCITY_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_BIRTHCITY_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_BIRTHSTATE__NOT_BLANK = 'INVALID_CUSTOMER_LOAN_BIRTHSTATE__NOT_BLANK',
        INVALID_CUSTOMER_LOAN_BIRTHSTATE_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_BIRTHSTATE_MAX_SIZED',
        INVALID_VEHICLE_LICENSE_PLATE_NOT_BLANK = 'INVALID_VEHICLE_LICENSE_PLATE_NOT_BLANK',
        INVALID_VEHICLE_LICENSE_PLATE = 'INVALID_VEHICLE_LICENSE_PLATE',
        INVALID_VEHICLE_BRAND_NOT_BLANK = 'INVALID_VEHICLE_BRAND_NOT_BLANK',
        INVALID_VEHICLE_BRAND_MAX_SIZED = 'INVALID_VEHICLE_BRAND_MAX_SIZED',
        INVALID_VEHICLE_CATEGORY_DESCRIPTION_NOT_BLANK = 'INVALID_VEHICLE_CATEGORY_DESCRIPTION_NOT_BLANK',
        INVALID_VEHICLE_CATEGORY_DESCRIPTION_MAX_SIZED = 'INVALID_VEHICLE_CATEGORY_DESCRIPTION_MAX_SIZED',
        INVALID_VEHICLE_MODEL_NOT_BLANK = 'INVALID_VEHICLE_MODEL_NOT_BLANK',
        INVALID_VEHICLE_MODEL_MAX_SIZED = 'INVALID_VEHICLE_MODEL_MAX_SIZED',
        INVALID_VEHICLE_MODEL_YEAR = 'INVALID_VEHICLE_MODEL_YEAR',
        INVALID_VEHICLE_MODEL_YEAR_NOT_BLANK = 'INVALID_VEHICLE_MODEL_YEAR_NOT_BLANK',
        INVALID_VEHICLE_MANUFATURE_YEAR = 'INVALID_VEHICLE_MANUFATURE_YEAR',
        INVALID_VEHICLE_MANUFATURE_YEAR_THAN_THEN_CURRENT = 'INVALID_VEHICLE_MANUFATURE_YEAR_THAN_THEN_CURRENT',
        INVALID_VEHICLE_MODEL_YEAR_THAN_THEN_TWO_YEAR_CURRENT = 'INVALID_VEHICLE_MODEL_YEAR_THAN_THEN_TWO_YEAR_CURRENT',
        INVALID_VEHICLE_MANUFATURE_YEAR_NOT_BLANK = 'INVALID_VEHICLE_MANUFATURE_YEAR_NOT_BLANK',
        INVALID_VEHICLE_MOLICAR_CODE_MAX_SIZED = 'INVALID_VEHICLE_MOLICAR_CODE_MAX_SIZED',
        INVALID_VEHICLE_STATE_LICENSE_NOT_BLANK = 'INVALID_VEHICLE_STATE_LICENSE_NOT_BLANK',
        INVALID_VEHICLE_STATE_LICENSE_MAX_SIZED = 'INVALID_VEHICLE_STATE_LICENSE_MAX_SIZED',
        INVALID_VEHICLE_ID = 'INVALID_VEHICLE_ID',
        INVALID_VEHICLE_NOT_FOUND = 'INVALID_VEHICLE_NOT_FOUND',
        INVALID_VEHICLE_DENY_UPDATE = 'INVALID_VEHICLE_DENY_UPDATE',
        INVALID_VEHICLE_ALREADY_EXIST = 'INVALID_VEHICLE_ALREADY_EXIST',
        INVALID_VEHICLE_CRV = 'INVALID_VEHICLE_CRV',
        INVALID_BV_INTEGRATION = 'INVALID_BV_INTEGRATION',
        INVALID_SIMULATIONQUOTE_NOT_FOUND = 'INVALID_SIMULATIONQUOTE_NOT_FOUND',
        INVALID_CUSTOMER_ALLOWLIST_NOT_FOUND = 'INVALID_CUSTOMER_ALLOWLIST_NOT_FOUND',
        INVALID_SIMULATION_QUOTE_WITH_RESTRICTION = 'INVALID_SIMULATION_QUOTE_WITH_RESTRICTION',
        INVALID_VEHICLE_INSPECTION_NOT_FOUND = 'INVALID_VEHICLE_INSPECTION_NOT_FOUND',
        INVALID_VEHICLE_INSPECTION_NOT_AVAILABLE = 'INVALID_VEHICLE_INSPECTION_NOT_AVAILABLE',
        INVALID_PROPOSALLOAN_NOT_FOUND = 'INVALID_PROPOSALLOAN_NOT_FOUND',
        INVALID_BANNER_ACTIVE_NOT_BLANCK = 'INVALID_BANNER_ACTIVE_NOT_BLANCK',
        INVALID_BANNER_EXPIRATION_DATE_NOT_BLANCK = 'INVALID_BANNER_EXPIRATION_DATE_NOT_BLANCK',
        INVALID_BANNER_TITLE_NOT_BLANCK = 'INVALID_BANNER_TITLE_NOT_BLANCK',
        INVALID_CUSTOMER_LOAN_BIRTHDATE_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_BIRTHDATE_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_EMAIL_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_EMAIL_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_EMAIL_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_EMAIL_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_EMAIL = 'INVALID_CUSTOMER_LOAN_EMAIL',
        INVALID_CUSTOMER_LOAN_MARITAL_STATUS_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_MARITAL_STATUS_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_MARITAL_STATUS_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_MARITAL_STATUS_MAX_SIZED',
        INVALID_CUSTOMER_LOAN_GENDER = 'INVALID_CUSTOMER_LOAN_GENDER',
        INVALID_CUSTOMER_LOAN_MOTHER_NAME_NOT_BLANK = 'INVALID_CUSTOMER_LOAN_MOTHER_NAME_NOT_BLANK',
        INVALID_CUSTOMER_LOAN_ADDRESS_NOT_NULL = 'INVALID_CUSTOMER_LOAN_ADDRESS_NOT_NULL',
        INVALID_CUSTOMER_LOAN_MONTHLY_INCOME = 'INVALID_CUSTOMER_LOAN_MONTHLY_INCOME',
        INVALID_CUSTOMER_LOAN_AGE = 'INVALID_CUSTOMER_LOAN_AGE',
        INVALID_CUSTOMER_LOAN_AGE_MAX_SIZED = 'INVALID_CUSTOMER_LOAN_AGE_MAX_SIZED',
        INVALID_ADDRESS_NOT_FOUND = 'INVALID_ADDRESS_NOT_FOUND',
        INVALID_PHONE_AREA_CODE = 'INVALID_PHONE_AREA_CODE',
        INVALID_DOMAINS_PRODUCT_NOT_BLANK = 'INVALID_DOMAINS_PRODUCT_NOT_BLANK',
        INVALID_ZIP_CODE_NOT_BLANK = 'INVALID_ZIP_CODE_NOT_BLANK',
        INVALID_FEDERATIVE_UNIT_NOT_BLANK = 'INVALID_FEDERATIVE_UNIT_NOT_BLANK',
        INVALID_VEHICLE_MODEL_YEAR_MAX_SIZED = 'INVALID_VEHICLE_MODEL_YEAR_MAX_SIZED',
        INVALID_VEHICLE_CATEGORY_NOT_BLANK = 'INVALID_VEHICLE_CATEGORY_NOT_BLANK',
        INVALID_VEHICLE_ZERO_KILOMETER_NOT_BLANK = 'INVALID_VEHICLE_ZERO_KILOMETER_NOT_BLANK',
        INVALID_VEHICLE_ZERO_KILOMETER_MAX_SIZED = 'INVALID_VEHICLE_ZERO_KILOMETER_MAX_SIZED',
        INVALID_VEHICLE_ZERO_KILOMETER = 'INVALID_VEHICLE_ZERO_KILOMETER',
        INVALID_VEHICLE_LICENSING_FEDERATIVE_NOT_BLANK = 'INVALID_VEHICLE_LICENSING_FEDERATIVE_NOT_BLANK',
        INVALID_VEHICLE_MODEL_COMPLEMENT_NOT_BLANK = 'INVALID_VEHICLE_MODEL_COMPLEMENT_NOT_BLANK',
        INVALID_VEHICLE_EXCLUDED_ARMORED = 'INVALID_VEHICLE_EXCLUDED_ARMORED',
        INVALID_VEHICLE_RENAVAN_MAX_SIZED = 'INVALID_VEHICLE_RENAVAN_MAX_SIZED',
        INVALID_PROPOSAL_LOAN_PRODUCT_MAX_SIZED = 'INVALID_PROPOSAL_LOAN_PRODUCT_MAX_SIZED',
        INVALID_PROPOSAL_LOAN_PROTOCOL_MAX_SIZED = 'INVALID_PROPOSAL_LOAN_PROTOCOL_MAX_SIZED',
        INVALID_PROPOSAL_LOAN_PROTOCOL_NOT_BLANK = 'INVALID_PROPOSAL_LOAN_PROTOCOL_NOT_BLANK',
        INVALID_PROPOSAL_LOAN_BV_NUMBER_NOT_BLANK = 'INVALID_PROPOSAL_LOAN_BV_NUMBER_NOT_BLANK',
        INVALID_PROPOSAL_LOAN_BV_NUMBER_MAX_SIZED = 'INVALID_PROPOSAL_LOAN_BV_NUMBER_MAX_SIZED',
        INVALID_PROPOSAL_LOAN_STATUS_NOT_BLANK = 'INVALID_PROPOSAL_LOAN_STATUS_NOT_BLANK',
        INVALID_PROPOSAL_LOAN_STATUS_MAX_SIZED = 'INVALID_PROPOSAL_LOAN_STATUS_MAX_SIZED',
        INVALID_PROPOSAL_LOAN_DESCRIPTION_NOT_BLANK = 'INVALID_PROPOSAL_LOAN_DESCRIPTION_NOT_BLANK',
        INVALID_PROPOSAL_LOAN_DESCRIPTION_MAX_SIZED = 'INVALID_PROPOSAL_LOAN_DESCRIPTION_MAX_SIZED',
        INVALID_SIMULATION_QUOTE_DUE_DATE_MAX_SIZED = 'INVALID_SIMULATION_QUOTE_DUE_DATE_MAX_SIZED',
        INVALID_SIMULATION_QUOTE_DUE_DATE = 'INVALID_SIMULATION_QUOTE_DUE_DATE',
        INVALID_SIMULATION_QUOTE_COUNT_INSTALLMENT = 'INVALID_SIMULATION_QUOTE_COUNT_INSTALLMENT',
        INVALID_SIMULATION_QUOTE_COUNT_INSTALLMENT_MAX_SIZED = 'INVALID_SIMULATION_QUOTE_COUNT_INSTALLMENT_MAX_SIZED',
        INVALID_SIMULATION_QUOTE_VALUE_LOAN = 'INVALID_SIMULATION_QUOTE_VALUE_LOAN',
        INVALID_SIMULATION_QUOTE_VALUE_LOAN_NOT_BLANK = 'INVALID_SIMULATION_QUOTE_VALUE_LOAN_NOT_BLANK',
        INVALID_SIMULATION_QUOTE_FIRST_INSTALLMENT = 'INVALID_SIMULATION_QUOTE_FIRST_INSTALLMENT',
        INVALID_SIMULATION_QUOTE_FIRST_INSTALLMENT_MAX_SIZED = 'INVALID_SIMULATION_QUOTE_FIRST_INSTALLMENT_MAX_SIZED',
        INVALID_SIMULATION_QUOTE_WAITING_DAY = 'INVALID_SIMULATION_QUOTE_WAITING_DAY',
        INVALID_SIMULATION_QUOTE_WAITING_DAY_MAX_SIZED = 'INVALID_SIMULATION_QUOTE_WAITING_DAY_MAX_SIZED',
        INVALID_SIMULATION_QUOTE_ZERO_VEHICLE_NOT_BLANK = 'INVALID_SIMULATION_QUOTE_ZERO_VEHICLE_NOT_BLANK',
        INVALID_SIMULATION_QUOTE_ZERO_VEHICLE = 'INVALID_SIMULATION_QUOTE_ZERO_VEHICLE',
        INVALID_SIMULATION_QUOTE_PAYMENT_TYPE_NOT_BLANK = 'INVALID_SIMULATION_QUOTE_PAYMENT_TYPE_NOT_BLANK',
        INVALID_SIMULATION_QUOTE_PAYMENT_TYPE_MAX_SIZED = 'INVALID_SIMULATION_QUOTE_PAYMENT_TYPE_MAX_SIZED',
        INVALID_CONTRACT_ORIGINATION_NOT_BLANK = 'INVALID_CONTRACT_ORIGINATION_NOT_BLANK',
        INVALID_CONTRACT_ORIGINATION_MAX_SIZED = 'INVALID_CONTRACT_ORIGINATION_MAX_SIZED',
        INVALID_CONTRACT_INSTALLMENTS_NOT_BLANK = 'INVALID_CONTRACT_INSTALLMENTS_NOT_BLANK',
        INVALID_CONTRACT_DUE_DATE_MAX_SIZED = 'INVALID_CONTRACT_DUE_DATE_MAX_SIZED',
        INVALID_CONTRACT_DUE_DATE = 'INVALID_CONTRACT_DUE_DATE',
        INVALID_COLLECTION_TICKET_CONTRACT_CODE_NOT_BLANK = 'INVALID_COLLECTION_TICKET_CONTRACT_CODE_NOT_BLANK',
        INVALID_COLLECTION_TICKET_CONTRACT_CODE_MAX_SIZED = 'INVALID_COLLECTION_TICKET_CONTRACT_CODE_MAX_SIZED',
        INVALID_COLLECTION_TICKET_SEQUENCE_NOT_BLANK = 'INVALID_COLLECTION_TICKET_SEQUENCE_NOT_BLANK',
        INVALID_COLLECTION_TICKET_CHANNEL_CODE_NOT_BLANK = 'INVALID_COLLECTION_TICKET_CHANNEL_CODE_NOT_BLANK',
        INVALID_COLLECTION_TICKET_BILLING_INSTRUMENT_NOT_BLANK = 'INVALID_COLLECTION_TICKET_BILLING_INSTRUMENT_NOT_BLANK',
        INVALID_COLLECTION_TICKET_BILLING_INSTRUMENT_MAX_SIZED = 'INVALID_COLLECTION_TICKET_BILLING_INSTRUMENT_MAX_SIZED',
        INVALID_COLLECTION_TICKET_VALUE_NOT_BLANK = 'INVALID_COLLECTION_TICKET_VALUE_NOT_BLANK',
        INVALID_UNICO_JWT_TOKEN_NOT_BLANK = 'INVALID_UNICO_JWT_TOKEN_NOT_BLANK',
        INVALID_INSURANCE_FISRT_NAME_DOCUMENT_MAX_SIZED = 'INVALID_INSURANCE_FISRT_NAME_DOCUMENT_MAX_SIZED',
        INVALID_UNICO_NOT_FOUND = 'INVALID_UNICO_NOT_FOUND',
        INVALID_UNICO_ALREADY_EXIST = 'INVALID_UNICO_ALREADY_EXIST',
        INVALID_UNICO_ERROR_EXCEPTION = 'INVALID_UNICO_ERROR_EXCEPTION',
        INVALID_UNICO_INTEGRATION_EXCEPTION = 'INVALID_UNICO_INTEGRATION_EXCEPTION',
        INVALID_READ_WRITE_ERROR_EXCEPTION = 'INVALID_READ_WRITE_ERROR_EXCEPTION',
        INVALID_PERSONAL_LOAN_ERROR_EXCEPTION = 'INVALID_PERSONAL_LOAN_ERROR_EXCEPTION',
        INVALID_ACCEPTLOAN_ERROR_EXCEPTION = 'INVALID_ACCEPTLOAN_ERROR_EXCEPTION',
        INVALID_ACCEPTLOAN_NOT_FOUND = 'INVALID_ACCEPTLOAN_NOT_FOUND',
        INVALID_FLOW_SIMULATION_ERROR_EXCEPTION = 'INVALID_FLOW_SIMULATION_ERROR_EXCEPTION',
        INVALID_DOCUMENT_ACCEPT_LOAN_NOT_FOUND = 'INVALID_DOCUMENT_ACCEPT_LOAN_NOT_FOUND',
        INVALID_DOCUMENT_ACCEPT_LOAN_ERROR_EXCEPTION = 'INVALID_DOCUMENT_ACCEPT_LOAN_ERROR_EXCEPTION',
        INVALID_ADDRESS_ERROR_EXCEPTION = 'INVALID_ADDRESS_ERROR_EXCEPTION',
        INVALID_COLLECTION_TICKET_NOT_FOUND = 'INVALID_COLLECTION_TICKET_NOT_FOUND',
        INVALID_COLLECTION_TICKET_ERROR_EXCEPTION = 'INVALID_COLLECTION_TICKET_ERROR_EXCEPTION',
        INVALID_CONTACT_NOT_FOUND = 'INVALID_CONTACT_NOT_FOUND',
        INVALID_CONTACT_ERROR_EXCEPTION = 'INVALID_CONTACT_ERROR_EXCEPTION',
        INVALID_CONTRACT_ORIGINATION_NOT_FOUND = 'INVALID_CONTRACT_ORIGINATION_NOT_FOUND',
        INVALID_CONTRACT_ORIGINATION_ERROR_EXCEPTION = 'INVALID_CONTRACT_ORIGINATION_ERROR_EXCEPTION',
        INVALID_CUSTOMER_ALLOWLIST_ERROR_EXCEPTION = 'INVALID_CUSTOMER_ALLOWLIST_ERROR_EXCEPTION',
        INVALID_DOCUMENT_UPLOAD_NOT_FOUND = 'INVALID_DOCUMENT_UPLOAD_NOT_FOUND',
        INVALID_DOCUMENT_UPLOAD_ERROR_EXCEPTION = 'INVALID_DOCUMENT_UPLOAD_ERROR_EXCEPTION',
        INVALID_DOMAIN_NOT_FOUND = 'INVALID_DOMAIN_NOT_FOUND',
        INVALID_DOMAIN_ERROR_EXCEPTION = 'INVALID_DOMAIN_ERROR_EXCEPTION',
        INVALID_BENEFIT_NOT_FOUND = 'INVALID_BENEFIT_NOT_FOUND',
        INVALID_BENEFIT_ERROR_EXCEPTION = 'INVALID_BENEFIT_ERROR_EXCEPTION',
        INVALID_NOTIFICATION_NOT_FOUND = 'INVALID_NOTIFICATION_NOT_FOUND',
        INVALID_NOTIFICATION_ERROR_EXCEPTION = 'INVALID_NOTIFICATION_ERROR_EXCEPTION',
        INVALID_VEHICLE_UF_DOES_NOT_MATCH_EXCEPTION = 'INVALID_VEHICLE_UF_DOES_NOT_MATCH_EXCEPTION',
        INVALID_VALIDATE_SIMULATION_DATA_NOT_FOUND = 'INVALID_VALIDATE_SIMULATION_DATA_NOT_FOUND',
        INVALID_VALIDATE_SIMULATION_DATA_ERROR_EXCEPTION = 'INVALID_VALIDATE_SIMULATION_DATA_ERROR_EXCEPTION',
        INVALID_VALIDATE_SIMULATION_DATA_NOT_MATCH = 'INVALID_VALIDATE_SIMULATION_DATA_NOT_MATCH',
        PRE_PAID_CUSTOMER_CREDIT_CARD_NOT_FOUND = 'PRE_PAID_CUSTOMER_CREDIT_CARD_NOT_FOUND',
        PRE_PAID_CUSTOMER_ACCOUNT_NOT_FOUND = 'PRE_PAID_CUSTOMER_ACCOUNT_NOT_FOUND',
        PRE_PAID_ENCRYPT_ERROR_EXCEPTION = 'PRE_PAID_ENCRYPT_ERROR_EXCEPTION',
        PRE_PAID_CUSTOMER_VIRTUAL_CREDIT_CARD_NOT_FOUND = 'PRE_PAID_CUSTOMER_VIRTUAL_CREDIT_CARD_NOT_FOUND',
        PRE_PAID_CUSTOMER_CREDIT_CARD_STATUS_NOT_FOUND = 'PRE_PAID_CUSTOMER_CREDIT_CARD_STATUS_NOT_FOUND',
        UNABLE_TO_RETRIEVE_TRANSACTION_INFORMATION = 'UNABLE_TO_RETRIEVE_TRANSACTION_INFORMATION',
        UNABLE_TO_RETRIEVE_MERCHANT_ACCOUNT_INFORMATION = 'UNABLE_TO_RETRIEVE_MERCHANT_ACCOUNT_INFORMATION',
        UNABLE_TO_PROCESS_PRODUCT_TYPE = 'UNABLE_TO_PROCESS_PRODUCT_TYPE',
        UNABLE_TO_PROCESS_BRCODE_PAYMENT = 'UNABLE_TO_PROCESS_BRCODE_PAYMENT',
        UNABLE_TO_RETRIEVE_PUBLIC_KEY = 'UNABLE_TO_RETRIEVE_PUBLIC_KEY',
        INVALID_BRCODE_BENEFIT_ID_NULL = 'INVALID_BRCODE_BENEFIT_ID_NULL',
        INVALID_BRCODE_NOT_NULL = 'INVALID_BRCODE_NOT_NULL',
        INVALID_BRCODE = 'INVALID_BRCODE',
        INVALID_BRCODE_CANCELATION_EXCEPTION = 'INVALID_BRCODE_CANCELATION_EXCEPTION',
        INVALID_BRCODE_GEOLOCATION = 'INVALID_BRCODE_GEOLOCATION',
        INVALID_BRCODE_LATITUDE = 'INVALID_BRCODE_LATITUDE',
        INVALID_BRCODE_LONGITUDE = 'INVALID_BRCODE_LONGITUDE',
        INVALID_BRCODE_CUSTOMER = 'INVALID_BRCODE_CUSTOMER',
        INVALID_BRCODE_CUSTOMER_ID = 'INVALID_BRCODE_CUSTOMER_ID',
        INVALID_BRCODE_CUSTOMER_OWNID = 'INVALID_BRCODE_CUSTOMER_OWNID',
        INVALID_BRCODE_CUSTOMER_NAME = 'INVALID_BRCODE_CUSTOMER_NAME',
        INVALID_BRCODE_CUSTOMER_DOCUMENT = 'INVALID_BRCODE_CUSTOMER_DOCUMENT',
        BRCODE_TRANSACTION_DUPLICATED = 'BRCODE_TRANSACTION_DUPLICATED',
        BRCODE_TRANSACTION_NOT_FOUND = 'BRCODE_TRANSACTION_NOT_FOUND',
        BRCODE_ORIGINAL_TRANSACTION_NOT_FOUND = 'BRCODE_ORIGINAL_TRANSACTION_NOT_FOUND',
        INVALID_BRCODE_BENEFIT = 'INVALID_BRCODE_BENEFIT',
        INSUFFICIENT_KMV_BALANCE = 'INSUFFICIENT_KMV_BALANCE',
        INVALID_ORDER_LIMITER_TYPE = 'INVALID_ORDER_LIMITER_TYPE',
        INVALID_ORDER_LIMITER_VALUE = 'INVALID_ORDER_LIMITER_VALUE',
        INVALID_ORDER_LIMITER_STORE_DOCUMENT = 'INVALID_ORDER_LIMITER_STORE_DOCUMENT',
        INVALID_ORDER_LIMITER_PERIOD_DAY = 'INVALID_ORDER_LIMITER_PERIOD_DAY',
        INVALID_ORDER_LIMITER_STATUS = 'INVALID_ORDER_LIMITER_STATUS',
        ORDER_LIMITER_NOT_FOUND = 'ORDER_LIMITER_NOT_FOUND',
        ORDER_LIMITER_VALUE_EXCEEDED = 'ORDER_LIMITER_VALUE_EXCEEDED',
        ORDER_LIMITER_QUANTITY_EXCEEDED = 'ORDER_LIMITER_QUANTITY_EXCEEDED',
        INVALID_ADDRESS_HOLDER_ZIP_NOT_BLANK = 'INVALID_ADDRESS_HOLDER_ZIP_NOT_BLANK',
        INVALID_ADDRESS_HOLDER_ZIP_MAX_SIZED = 'INVALID_ADDRESS_HOLDER_ZIP_MAX_SIZED',
        INVALID_ADDRESS_HOLDER_STREET_NOT_BLANK = 'INVALID_ADDRESS_HOLDER_STREET_NOT_BLANK',
        INVALID_ADDRESS_HOLDER_STREET_MAX_SIZED = 'INVALID_ADDRESS_HOLDER_STREET_MAX_SIZED',
        INVALID_ADDRESS_HOLDER_NUMBER_NOT_BLANK = 'INVALID_ADDRESS_HOLDER_NUMBER_NOT_BLANK',
        INVALID_ADDRESS_HOLDER_NUMBER_MAX_SIZED = 'INVALID_ADDRESS_HOLDER_NUMBER_MAX_SIZED',
        INVALID_ADDRESS_HOLDER_CITY_NOT_BLANK = 'INVALID_ADDRESS_HOLDER_CITY_NOT_BLANK',
        INVALID_ADDRESS_HOLDER_CITY_MAX_SIZED = 'INVALID_ADDRESS_HOLDER_CITY_MAX_SIZED',
        INVALID_ADDRESS_HOLDER_DISTRICT_NOT_BLANK = 'INVALID_ADDRESS_HOLDER_DISTRICT_NOT_BLANK',
        INVALID_ADDRESS_HOLDER_DISTRICT_MAX_SIZED = 'INVALID_ADDRESS_HOLDER_DISTRICT_MAX_SIZED',
        INVALID_HOLDER_ID_NOT_BLANK = 'INVALID_HOLDER_ID_NOT_BLANK',
        INVALID_QUOTE_ID_NOT_BLANK = 'INVALID_QUOTE_ID_NOT_BLANK',
        INVALID_INSURANCE_COVERAGE_UUID_NOT_BLANK = 'INVALID_INSURANCE_COVERAGE_UUID_NOT_BLANK',
        INVALID_INSURANCE_COVERAGE_UUID_MAX_SIZED = 'INVALID_INSURANCE_COVERAGE_UUID_MAX_SIZED',
        INVALID_INSURANCE_COVERAGE_VALUE = 'INVALID_INSURANCE_COVERAGE_VALUE',
        INVALID_INSURANCE_COVERAGE_VALUE_NOT_BLANK = 'INVALID_INSURANCE_COVERAGE_VALUE_NOT_BLANK',
        INVALID_INSURANCE_COVERAGE_DEDUCTIBLE = 'INVALID_INSURANCE_COVERAGE_DEDUCTIBLE',
        INVALID_INSURANCE_COVERAGE_DEDUCTIBLE_NOT_BLANK = 'INVALID_INSURANCE_COVERAGE_DEDUCTIBLE_NOT_BLANK',
        INVALID_INSURANCE_COVERAGE_CUSTOMIZE = 'INVALID_INSURANCE_COVERAGE_CUSTOMIZE',
        INVALID_INSURANCE_COVERAGE_CUSTOMIZE_NOT_BLANK = 'INVALID_INSURANCE_COVERAGE_CUSTOMIZE_NOT_BLANK',
        INVALID_CUSTOMER_PROSPECT_LICENSE_PLATE = 'INVALID_CUSTOMER_PROSPECT_LICENSE_PLATE',
        INVALID_CUSTOMER_PROSPECT_LICENSE_PLATE_NOT_BLANK = 'INVALID_CUSTOMER_PROSPECT_LICENSE_PLATE_NOT_BLANK',
        INVALID_CUSTOMER_PROSPECT_LICENSE_PLATE_MAX_SIZED = 'INVALID_CUSTOMER_PROSPECT_LICENSE_PLATE_MAX_SIZED',
        INVALID_CUSTOMER_PROSPECT_BRAND_NOT_BLANK = 'INVALID_CUSTOMER_PROSPECT_BRAND_NOT_BLANK',
        INVALID_CUSTOMER_PROSPECT_BRAND_MAX_SIZED = 'INVALID_CUSTOMER_PROSPECT_BRAND_MAX_SIZED',
        INVALID_CUSTOMER_PROSPECT_MODEL_NOT_BLANK = 'INVALID_CUSTOMER_PROSPECT_MODEL_NOT_BLANK',
        INVALID_CUSTOMER_PROSPECT_MODEL_MAX_SIZED = 'INVALID_CUSTOMER_PROSPECT_MODEL_MAX_SIZED',
        INVALID_CUSTOMER_PROSPECT_MODEL_YEAR_NOT_BLANK = 'INVALID_CUSTOMER_PROSPECT_MODEL_YEAR_NOT_BLANK',
        INVALID_CUSTOMER_PROSPECT_MODEL_YEAR_MAX_SIZED = 'INVALID_CUSTOMER_PROSPECT_MODEL_YEAR_MAX_SIZED',
        INVALID_CUSTOMER_PROSPECT_MODEL_YEAR = 'INVALID_CUSTOMER_PROSPECT_MODEL_YEAR',
        INVALID_CUSTOMER_PROSPECT_ANSWER_NOT_BLANK = 'INVALID_CUSTOMER_PROSPECT_ANSWER_NOT_BLANK',
        INVALID_CUSTOMER_PROSPECT_ANSWER_MAX_SIZED = 'INVALID_CUSTOMER_PROSPECT_ANSWER_MAX_SIZED',
        INVALID_CUSTOMER_PROSPECT_ANSWER = 'INVALID_CUSTOMER_PROSPECT_ANSWER',
        INVALID_CUSTOMER_PROSPECT_INSURANCE_COMPANY_MAX_SIZED = 'INVALID_CUSTOMER_PROSPECT_INSURANCE_COMPANY_MAX_SIZED',
        INVALID_CUSTOMER_PROSPECT_DUEDATE = 'INVALID_CUSTOMER_PROSPECT_DUEDATE',
        INVALID_CUSTOMER_PROSPECT_UUID = 'INVALID_CUSTOMER_PROSPECT_UUID',
        INVALID_DOCUMENT_HOLDER_TYPE_NOT_BLANK = 'INVALID_DOCUMENT_HOLDER_TYPE_NOT_BLANK',
        INVALID_DOCUMENT_HOLDER_TYPE_MAX_SIZED = 'INVALID_DOCUMENT_HOLDER_TYPE_MAX_SIZED',
        INVALID_DOCUMENT_HOLDER_NUMBER_NOT_BLANK = 'INVALID_DOCUMENT_HOLDER_NUMBER_NOT_BLANK',
        INVALID_DOCUMENT_HOLDER_NUMBER_MAX_SIZED = 'INVALID_DOCUMENT_HOLDER_NUMBER_MAX_SIZED',
        INVALID_DOCUMENT_HOLDER_ISSUE_DATE_NOT_BLANK = 'INVALID_DOCUMENT_HOLDER_ISSUE_DATE_NOT_BLANK',
        INVALID_DOCUMENT_HOLDER_ISSUE_DATE = 'INVALID_DOCUMENT_HOLDER_ISSUE_DATE',
        INVALID_DOCUMENT_HOLDER_ISSUE_DATE_MAX_SIZED = 'INVALID_DOCUMENT_HOLDER_ISSUE_DATE_MAX_SIZED',
        INVALID_DOCUMENT_HOLDER_ISSUE_AGENCY_NOT_BLANK = 'INVALID_DOCUMENT_HOLDER_ISSUE_AGENCY_NOT_BLANK',
        INVALID_DOCUMENT_HOLDER_ISSUE_AGENCY_MAX_SIZED = 'INVALID_DOCUMENT_HOLDER_ISSUE_AGENCY_MAX_SIZED',
        INVALID_DOCUMENT_HOLDER_UUID = 'INVALID_DOCUMENT_HOLDER_UUID',
        INVALID_DOCUMENT_HOLDER_TYPE = 'INVALID_DOCUMENT_HOLDER_TYPE',
        INVALID_INSURANCE_DRIVER_UUID = 'INVALID_INSURANCE_DRIVER_UUID',
        INVALID_INSURANCE_DRIVER_DOCUMENT_NOT_BLANK = 'INVALID_INSURANCE_DRIVER_DOCUMENT_NOT_BLANK',
        INVALID_INSURANCE_DRIVER_DOCUMENT_MAX_SIZED = 'INVALID_INSURANCE_DRIVER_DOCUMENT_MAX_SIZED',
        INVALID_INSURANCE_DRIVER_DOCUMENT = 'INVALID_INSURANCE_DRIVER_DOCUMENT',
        INVALID_INSURANCE_DRIVER_NAME_NOT_BLANK = 'INVALID_INSURANCE_DRIVER_NAME_NOT_BLANK',
        INVALID_INSURANCE_DRIVER_NAME_MAX_SIZED = 'INVALID_INSURANCE_DRIVER_NAME_MAX_SIZED',
        INVALID_INSURANCE_DRIVER_BIRTH_DATE_MAX_SIZED = 'INVALID_INSURANCE_DRIVER_BIRTH_DATE_MAX_SIZED',
        INVALID_INSURANCE_DRIVER_GENDER_NOT_BLANK = 'INVALID_INSURANCE_DRIVER_GENDER_NOT_BLANK',
        INVALID_INSURANCE_DRIVER_GENDER_MAX_SIZED = 'INVALID_INSURANCE_DRIVER_GENDER_MAX_SIZED',
        INVALID_INSURANCE_DRIVER_MARITAL_STATUS_NOT_BLANK = 'INVALID_INSURANCE_DRIVER_MARITAL_STATUS_NOT_BLANK',
        INVALID_INSURANCE_DRIVER_MARITAL_STATUS_MAX_SIZED = 'INVALID_INSURANCE_DRIVER_MARITAL_STATUS_MAX_SIZED',
        INVALID_INSURANCE_DRIVER_RELATIONAL_TYPE_CODE_NOT_BLANK = 'INVALID_INSURANCE_DRIVER_RELATIONAL_TYPE_CODE_NOT_BLANK',
        INVALID_INSURANCE_DRIVER_RELATIONAL_TYPE_CODE_MAX_SIZED = 'INVALID_INSURANCE_DRIVER_RELATIONAL_TYPE_CODE_MAX_SIZED',
        INVALID_INSURANCE_INSPECTION_UUID = 'INVALID_INSURANCE_INSPECTION_UUID',
        INVALID_INSURANCE_INSPECTION_STATUS_NOT_BLANK = 'INVALID_INSURANCE_INSPECTION_STATUS_NOT_BLANK',
        INVALID_INSURANCE_HOLDER_DOCUMENT_NOT_BLANK = 'INVALID_INSURANCE_HOLDER_DOCUMENT_NOT_BLANK',
        INVALID_INSURANCE_HOLDER_DOCUMENT_MAX_SIZED = 'INVALID_INSURANCE_HOLDER_DOCUMENT_MAX_SIZED',
        INVALID_INSURANCE_HOLDER_DOCUMENT = 'INVALID_INSURANCE_HOLDER_DOCUMENT',
        INVALID_INSURANCE_HOLDER_FIRST_NAME_NOT_BLANK = 'INVALID_INSURANCE_HOLDER_FIRST_NAME_NOT_BLANK',
        INVALID_INSURANCE_HOLDER_FIRST_NAME_MAX_SIZED = 'INVALID_INSURANCE_HOLDER_FIRST_NAME_MAX_SIZED',
        INVALID_INSURANCE_HOLDER_LAST_NAME_NOT_BLANK = 'INVALID_INSURANCE_HOLDER_LAST_NAME_NOT_BLANK',
        INVALID_INSURANCE_HOLDER_LAST_NAME_MAX_SIZED = 'INVALID_INSURANCE_HOLDER_LAST_NAME_MAX_SIZED',
        INVALID_INSURANCE_HOLDER_FULL_NAME_NOT_BLANK = 'INVALID_INSURANCE_HOLDER_FULL_NAME_NOT_BLANK',
        INVALID_INSURANCE_HOLDER_FULL_NAME_MAX_SIZED = 'INVALID_INSURANCE_HOLDER_FULL_NAME_MAX_SIZED',
        INVALID_INSURANCE_HOLDER_GENDER_NOT_BLANK = 'INVALID_INSURANCE_HOLDER_GENDER_NOT_BLANK',
        INVALID_INSURANCE_HOLDER_GENDER_MAX_SIZED = 'INVALID_INSURANCE_HOLDER_GENDER_MAX_SIZED',
        INVALID_INSURANCE_HOLDER_BIRTH_DATE = 'INVALID_INSURANCE_HOLDER_BIRTH_DATE',
        INVALID_INSURANCE_HOLDER_BIRTH_DATE_MAX_SIZED = 'INVALID_INSURANCE_HOLDER_BIRTH_DATE_MAX_SIZED',
        INVALID_INSURANCE_HOLDER_MARITAL_STATUS_NOT_BLANK = 'INVALID_INSURANCE_HOLDER_MARITAL_STATUS_NOT_BLANK',
        INVALID_INSURANCE_HOLDER_MARITAL_STATUS_MAX_SIZED = 'INVALID_INSURANCE_HOLDER_MARITAL_STATUS_MAX_SIZED',
        INVALID_INSURANCE_HOLDER_MOTHER_NAME_MAX_SIZED = 'INVALID_INSURANCE_HOLDER_MOTHER_NAME_MAX_SIZED',
        INVALID_INSURANCE_HOLDER_EMAIL_MAX_SIZED = 'INVALID_INSURANCE_HOLDER_EMAIL_MAX_SIZED',
        INVALID_INSURANCE_HOLDER_EMAIL = 'INVALID_INSURANCE_HOLDER_EMAIL',
        INVALID_INSURANCE_PROFESSIONAL_CODE_MAX_SIZED = 'INVALID_INSURANCE_PROFESSIONAL_CODE_MAX_SIZED',
        INVALID_INSURANCE_INCOME_TIER_MAX_SIZED = 'INVALID_INSURANCE_INCOME_TIER_MAX_SIZED',
        INVALID_INSURANCE_PROFESSIONAL_NOT_BLANK = 'INVALID_INSURANCE_PROFESSIONAL_NOT_BLANK',
        INVALID_INSURANCE_INCOME_TIER_NOT_BLANK = 'INVALID_INSURANCE_INCOME_TIER_NOT_BLANK',
        INVALID_INSURANCE_PARTNER_UUID = 'INVALID_INSURANCE_PARTNER_UUID',
        INVALID_INSURANCE_PARTNER_NAME_MAX_SIZED = 'INVALID_INSURANCE_PARTNER_NAME_MAX_SIZED',
        INVALID_INSURANCE_PARTNER_NAME_NOT_BLANK = 'INVALID_INSURANCE_PARTNER_NAME_NOT_BLANK',
        INVALID_INSURANCE_PARTNER_ACTIVE = 'INVALID_INSURANCE_PARTNER_ACTIVE',
        INVALID_INSURANCE_PARTNER_ACTIVE_NOT_BLANK = 'INVALID_INSURANCE_PARTNER_ACTIVE_NOT_BLANK',
        INVALID_INSURANCE_PHONE_UUID = 'INVALID_INSURANCE_PHONE_UUID',
        INVALID_INSURANCE_PHONE_AREA_CODE_MAX_SIZED = 'INVALID_INSURANCE_PHONE_AREA_CODE_MAX_SIZED',
        INVALID_INSURANCE_PHONE_AREA_CODE_NOT_NULL = 'INVALID_INSURANCE_PHONE_AREA_CODE_NOT_NULL',
        INVALID_INSURANCE_PHONE_AREA_CODE = 'INVALID_INSURANCE_PHONE_AREA_CODE',
        INVALID_INSURANCE_PHONE_NUMBER_MAX_SIZED = 'INVALID_INSURANCE_PHONE_NUMBER_MAX_SIZED',
        INVALID_INSURANCE_QUOTE_CI_MAX_SIZED = 'INVALID_INSURANCE_QUOTE_CI_MAX_SIZED',
        INVALID_INSURANCE_QUOTE_RENEW = 'INVALID_INSURANCE_QUOTE_RENEW',
        INVALID_INSURANCE_QUOTE_PEP = 'INVALID_INSURANCE_QUOTE_PEP',
        INVALID_INSURANCE_QUOTE_VEHICLE_NEW = 'INVALID_INSURANCE_QUOTE_VEHICLE_NEW',
        INVALID_INSURANCE_QUOTE_UNDERAGE_DRIVER = 'INVALID_INSURANCE_QUOTE_UNDERAGE_DRIVER',
        INVALID_INSURANCE_QUOTE_VEHICLE_USE_MAX_SIZED = 'INVALID_INSURANCE_QUOTE_VEHICLE_USE_MAX_SIZED',
        INVALID_INSURANCE_QUOTE_STATUS_NOT_NULL = 'INVALID_INSURANCE_QUOTE_STATUS_NOT_NULL',
        INVALID_INSURANCE_RESULT_QUOTE = 'INVALID_INSURANCE_RESULT_QUOTE',
        INVALID_INSURANCE_RESULT_QUOTE_MAX_SIZED = 'INVALID_INSURANCE_RESULT_QUOTE_MAX_SIZED',
        INVALID_INSURANCE_VEHICLE_LICENSE_PLATE_NOT_BLANK = 'INVALID_INSURANCE_VEHICLE_LICENSE_PLATE_NOT_BLANK',
        INVALID_INSURANCE_VEHICLE_LICENSE_PLATE = 'INVALID_INSURANCE_VEHICLE_LICENSE_PLATE',
        INVALID_INSURANCE_VEHICLE_MANUFACTURE_YEAR = 'INVALID_INSURANCE_VEHICLE_MANUFACTURE_YEAR',
        INVALID_INSURANCE_VEHICLE_MANUFACTURE_YEAR_NOT_NULL = 'INVALID_INSURANCE_VEHICLE_MANUFACTURE_YEAR_NOT_NULL',
        INVALID_INSURANCE_VEHICLE_MODEL_YEAR = 'INVALID_INSURANCE_VEHICLE_MODEL_YEAR',
        INVALID_INSURANCE_VEHICLE_MODEL_YEAR_NOT_NULL = 'INVALID_INSURANCE_VEHICLE_MODEL_YEAR_NOT_NULL',
        INVALID_INSURANCE_VEHICLE_BRAND_CODE_MAX_SIZED = 'INVALID_INSURANCE_VEHICLE_BRAND_CODE_MAX_SIZED',
        INVALID_INSURANCE_VEHICLE_BRAND_MAX_SIZED = 'INVALID_INSURANCE_VEHICLE_BRAND_MAX_SIZED',
        INVALID_INSURANCE_VEHICLE_BRAND_NOT_BLANK = 'INVALID_INSURANCE_VEHICLE_BRAND_NOT_BLANK',
        INVALID_INSURANCE_VEHICLE_VERSION_MAX_SIZED = 'INVALID_INSURANCE_VEHICLE_VERSION_MAX_SIZED',
        INVALID_INSURANCE_VEHICLE_VERSION_NOT_BLANK = 'INVALID_INSURANCE_VEHICLE_VERSION_NOT_BLANK',
        INVALID_INSURANCE_VEHICLE_VALUE = 'INVALID_INSURANCE_VEHICLE_VALUE',
        INVALID_INSURANCE_VEHICLE_VALUE_NOT_BLANK = 'INVALID_INSURANCE_VEHICLE_VALUE_NOT_BLANK',
        INVALID_INSURANCE_VEHICLE_FUEL_TYPE_MAX_SIZED = 'INVALID_INSURANCE_VEHICLE_FUEL_TYPE_MAX_SIZED',
        INVALID_INSURANCE_VEHICLE_ARMORED = 'INVALID_INSURANCE_VEHICLE_ARMORED',
        INVALID_INSURANCE_VEHICLE_RESTAMPED_CHASSIS = 'INVALID_INSURANCE_VEHICLE_RESTAMPED_CHASSIS',
        INVALID_INSURANCE_VEHICLE_FIPE_CODE_MAX_SIZED = 'INVALID_INSURANCE_VEHICLE_FIPE_CODE_MAX_SIZED',
        INVALID_INSURANCE_VEHICLE_FIPE_CODE_NOT_BLANK = 'INVALID_INSURANCE_VEHICLE_FIPE_CODE_NOT_BLANK',
        INVALID_INSURANCE_VEHICLE_AUCTION = 'INVALID_INSURANCE_VEHICLE_AUCTION',
        INVALID_INSURANCE_VEHICLE_EXEMPT_MAX_SIZED = 'INVALID_INSURANCE_VEHICLE_EXEMPT_MAX_SIZED',
        INVALID_INSURANCE_VEHICLE_VIN_MAX_SIZED = 'INVALID_INSURANCE_VEHICLE_VIN_MAX_SIZED',
        INVALID_INSURANCE_VEHICLE_VIN_NOT_BLANK = 'INVALID_INSURANCE_VEHICLE_VIN_NOT_BLANK',
        CUSTOMER_PROPESCT_NOT_FOUND = 'CUSTOMER_PROPESCT_NOT_FOUND',
        PARTNER_NOT_FOUND_EXCEPTION = 'PARTNER_NOT_FOUND_EXCEPTION',
        QUOTE_NOT_FOUND_EXCEPTION = 'QUOTE_NOT_FOUND_EXCEPTION',
        ADDRESS_HOLDER_DELETE_NOT_ALLOWED = 'ADDRESS_HOLDER_DELETE_NOT_ALLOWED',
        ADDRESS_HOLDER_UPDATE_NOT_ALLOWED = 'ADDRESS_HOLDER_UPDATE_NOT_ALLOWED',
        ADDRESS_HOLDER_NOT_FOUND = 'ADDRESS_HOLDER_NOT_FOUND',
        RESULT_QUOTE_NOT_FOUND = 'RESULT_QUOTE_NOT_FOUND',
        CUSTOMER_PROSPECT_DENY_CREATE = 'CUSTOMER_PROSPECT_DENY_CREATE',
        DOCUMENT_HOLDER_ALREADY_EXIST_TYPE = 'DOCUMENT_HOLDER_ALREADY_EXIST_TYPE',
        DOCUMENT_HOLDER_NOT_FOUND = 'DOCUMENT_HOLDER_NOT_FOUND',
        INSURANCE_DOMAIN_NOT_FOUND = 'INSURANCE_DOMAIN_NOT_FOUND',
        INSURANCE_DOMAIN_ARMORED_NOT_FOUND = 'INSURANCE_DOMAIN_ARMORED_NOT_FOUND',
        INSURANCE_DOMAIN_FUEL_TYPE_NOT_FOUND = 'INSURANCE_DOMAIN_FUEL_TYPE_NOT_FOUND',
        INSURANCE_DOMAIN_GENDER_NOT_FOUND = 'INSURANCE_DOMAIN_GENDER_NOT_FOUND',
        INSURANCE_DOMAIN_INCOME_TIER_NOT_FOUND = 'INSURANCE_DOMAIN_INCOME_TIER_NOT_FOUND',
        INSURANCE_DOMAIN_INSURANCE_COMPANY_NOT_FOUND = 'INSURANCE_DOMAIN_INSURANCE_COMPANY_NOT_FOUND',
        INSURANCE_DOMAIN_ISSUING_AGENCY_NOT_FOUND = 'INSURANCE_DOMAIN_ISSUING_AGENCY_NOT_FOUND',
        INSURANCE_DOMAIN_MARITAL_STATUS_NOT_FOUND = 'INSURANCE_DOMAIN_MARITAL_STATUS_NOT_FOUND',
        INSURANCE_DOMAIN_PROFESSION_STATUS_NOT_FOUND = 'INSURANCE_DOMAIN_PROFESSION_STATUS_NOT_FOUND',
        INSURANCE_DOMAIN_RELATION_HOLDER_NOT_FOUND = 'INSURANCE_DOMAIN_RELATION_HOLDER_NOT_FOUND',
        INSURANCE_DOMAIN_RELATION_TAX_EXEMPTION_NOT_FOUND = 'INSURANCE_DOMAIN_RELATION_TAX_EXEMPTION_NOT_FOUND',
        INSURANCE_DRIVER_ALREADY_EXISTS = 'INSURANCE_DRIVER_ALREADY_EXISTS',
        INSURANCE_DRIVER_NOT_FOUND = 'INSURANCE_DRIVER_NOT_FOUND',
        INSURANCE_INSPECTION_ALREADY_EXIST = 'INSURANCE_INSPECTION_ALREADY_EXIST',
        INSURANCE_INSPECTION_NOT_FOUND = 'INSURANCE_INSPECTION_NOT_FOUND',
        INSURANCE_HOLDER_ALREADY_EXISTS = 'INSURANCE_HOLDER_ALREADY_EXISTS',
        INSURANCE_HOLDER_UPDATE_NOT_ALLOWED = 'INSURANCE_HOLDER_UPDATE_NOT_ALLOWED',
        INSURANCE_HOLDER_NOT_FOUND = 'INSURANCE_HOLDER_NOT_FOUND',
        INSURANCE_HOLDER_PHONE_DELETE_NOT_ALLOWED = 'INSURANCE_HOLDER_PHONE_DELETE_NOT_ALLOWED',
        INSURANCE_HOLDER_PHONE_UPDATE_NOT_ALLOWED = 'INSURANCE_HOLDER_PHONE_UPDATE_NOT_ALLOWED',
        INSURANCE_HOLDER_PHONE_NOT_FOUND = 'INSURANCE_HOLDER_PHONE_NOT_FOUND',
        INSURANCE_RISK_ADDRESS_NOT_FOUND = 'INSURANCE_RISK_ADDRESS_NOT_FOUND',
        INSURANCE_RISK_ITEM_COVERAGE_NOT_FOUND = 'INSURANCE_RISK_ITEM_COVERAGE_NOT_FOUND',
        INSURANCE_SUTHUB_INTEGRATION_ERROR = 'INSURANCE_SUTHUB_INTEGRATION_ERROR',
        INSURANCE_PAYMENT_OPTION_NOT_FOUND = 'INSURANCE_PAYMENT_OPTION_NOT_FOUND',
        INSURANCE_PAYMENT_INSTRUMENT_METHOD_ID_NOT_BLANK = 'INSURANCE_PAYMENT_INSTRUMENT_METHOD_ID_NOT_BLANK',
        INSURANCE_PAYMENT_INSTRUMENT_METHOD_ID_MAX_SIZED = 'INSURANCE_PAYMENT_INSTRUMENT_METHOD_ID_MAX_SIZED',
        INSURANCE_PAYMENT_INSTRUMENT_METHOD_NOT_BLANK = 'INSURANCE_PAYMENT_INSTRUMENT_METHOD_NOT_BLANK',
        INSURANCE_PAYMENT_INSTRUMENT_METHOD_INVALID = 'INSURANCE_PAYMENT_INSTRUMENT_METHOD_INVALID',
        INSURANCE_PAYMENT_INSTRUMENT_PAYMENT_OPTION_ID_NOT_BLANK = 'INSURANCE_PAYMENT_INSTRUMENT_PAYMENT_OPTION_ID_NOT_BLANK',
        INSURANCE_PAYMENT_INSTRUMENT_PAYMENT_OPTION_ID_MAX_SIZED = 'INSURANCE_PAYMENT_INSTRUMENT_PAYMENT_OPTION_ID_MAX_SIZED',
        INSURANCE_PAYMENT_INSTRUMENT_PAYMENT_METHOD_EXCEPTION = 'INSURANCE_PAYMENT_INSTRUMENT_PAYMENT_METHOD_EXCEPTION',
        INSURANCE_PAYMENT_INSTRUMENT_NOT_FOUND = 'INSURANCE_PAYMENT_INSTRUMENT_NOT_FOUND',
        INSURANCE_PAYMENT_INSTRUMENT_ALREADY_EXISTS = 'INSURANCE_PAYMENT_INSTRUMENT_ALREADY_EXISTS',
        INSURANCE_PAYMENT_OPTION_DENY_UPDATE = 'INSURANCE_PAYMENT_OPTION_DENY_UPDATE',
        INSURANCE_PAYMENT_INSTALLMENT_COUNT_INVALID = 'INSURANCE_PAYMENT_INSTALLMENT_COUNT_INVALID',
        INSURANCE_PAYMENT_INSTRUMENT_UPDATE_COUNT_INVALID = 'INSURANCE_PAYMENT_INSTRUMENT_UPDATE_COUNT_INVALID',
        INSURANCE_PAYMENT_INSTALLMENT_ALREADY_PAID = 'INSURANCE_PAYMENT_INSTALLMENT_ALREADY_PAID',
        INSURANCE_PAYMENT_ERROR = 'INSURANCE_PAYMENT_ERROR',
        INVALID_INSURANCE_PAYMENT_STATUS = 'INVALID_INSURANCE_PAYMENT_STATUS',
        INSURANCE_WEBHOOK_CONTRACT_ID_MAX_SIZED = 'INSURANCE_WEBHOOK_CONTRACT_ID_MAX_SIZED',
        INSURANCE_WEBHOOK_PRODUCT_ID_MAX_SIZED = 'INSURANCE_WEBHOOK_PRODUCT_ID_MAX_SIZED',
        INSURANCE_WEBHOOK_CONTRACT_ID_NOT_BLANK = 'INSURANCE_WEBHOOK_CONTRACT_ID_NOT_BLANK',
        INSURANCE_WEBHOOK_PRODUCT_ID_NOT_BLANK = 'INSURANCE_WEBHOOK_PRODUCT_ID_NOT_BLANK',
        INSURANCE_WEBHOOK_STATUS_CODE_INVALID = 'INSURANCE_WEBHOOK_STATUS_CODE_INVALID',
        INSURANCE_WEBHOOK_STATUS_CODE_NOT_BLANK = 'INSURANCE_WEBHOOK_STATUS_CODE_NOT_BLANK',
        INSURANCE_WEBHOOK_ERROR = 'INSURANCE_WEBHOOK_ERROR',
        INSURANCE_PAYMENT_HISTORY_NOT_FOUND = 'INSURANCE_PAYMENT_HISTORY_NOT_FOUND',
        INSURANCE_BENEFIT_ID_NOT_BLANK = 'INSURANCE_BENEFIT_ID_NOT_BLANK',
        INSURANCE_BENEFIT_ID_MAX_SIZED = 'INSURANCE_BENEFIT_ID_MAX_SIZED',
        INSURANCE_BENEFIT_TYPE_NOT_BLANK = 'INSURANCE_BENEFIT_TYPE_NOT_BLANK',
        INVALID_INSURANCE_BENEFIT_TYPE = 'INVALID_INSURANCE_BENEFIT_TYPE',
        BENEFIT_INSURANCE_ALREADY_EXISTS = 'BENEFIT_INSURANCE_ALREADY_EXISTS',
        BENEFIT_INSURANCE_DENY_UPDATE = 'BENEFIT_INSURANCE_DENY_UPDATE',
        BENEFIT_INSURANCE_NOT_FOUND = 'BENEFIT_INSURANCE_NOT_FOUND',
        INVALID_EXCEL_FILE = 'INVALID_EXCEL_FILE',
        BENEFIT_BOND_ALREADY_EXISTS = 'BENEFIT_BOND_ALREADY_EXISTS',
        BENEFIT_BOND_NOT_FOUND = 'BENEFIT_BOND_NOT_FOUND',
        CASHBACK_SPLIT_PERCENTAGE = 'CASHBACK_SPLIT_PERCENTAGE',
        MIN_CASHBACK_REQUIRED = 'MIN_CASHBACK_REQUIRED',
        MAX_CASHBACK_REQUIRED = 'MAX_CASHBACK_REQUIRED',
        MEDIUM_CASHBACK_REQUIRED = 'MEDIUM_CASHBACK_REQUIRED',
        MIN_CASHBACK = 'MIN_CASHBACK',
        MAX_CASHBACK = 'MAX_CASHBACK',
        MAX_CASHBACK_GREATER_OR_EQUALS_THAN_MIN = 'MAX_CASHBACK_GREATER_OR_EQUALS_THAN_MIN',
        MEDIUM_CASHBACK_LESS_THAN = 'MEDIUM_CASHBACK_LESS_THAN',
        MEDIUM_CASHBACK_GREATER_OR_EQUALS_THAN_MIN = 'MEDIUM_CASHBACK_GREATER_OR_EQUALS_THAN_MIN',
        INVALID_QUANTITY_OF_ITEMS = 'INVALID_QUANTITY_OF_ITEMS',
        INVALID_NEGATIVE_VALUE = 'INVALID_NEGATIVE_VALUE',
        TERMINAL_ID_NOT_NULL = 'TERMINAL_ID_NOT_NULL',
        TERMINAL_ID_INVALID = 'TERMINAL_ID_INVALID',
        NSU_NOT_NULL = 'NSU_NOT_NULL',
        NSU_INVALID = 'NSU_INVALID',
        IP_NOT_NULL = 'IP_NOT_NULL',
        IP_INVALID = 'IP_INVALID',
        VENDOR_ID_NOT_NULL = 'VENDOR_ID_NOT_NULL',
        VENDOR_ID_INVALID = 'VENDOR_ID_INVALID',
        ORIGIN_NOT_NULL = 'ORIGIN_NOT_NULL',
        ORIGIN_INVALID = 'ORIGIN_INVALID',
        ORIGIN_REQUEST_AT_NOT_NULL = 'ORIGIN_REQUEST_AT_NOT_NULL',
        ORIGIN_REQUEST_AT_INVALID = 'ORIGIN_REQUEST_AT_INVALID',
        ORIGIN_VERSION_NOT_NULL = 'ORIGIN_VERSION_NOT_NULL',
        ACTION_NOT_NULL = 'ACTION_NOT_NULL',
        AMOUNT_NOT_NULL = 'AMOUNT_NOT_NULL',
        CARD_BIN_NOT_NULL = 'CARD_BIN_NOT_NULL',
        CARD_BIN_INVALID = 'CARD_BIN_INVALID',
        CARD_BIN_SIZE_MAX = 'CARD_BIN_SIZE_MAX',
        RECHARGE_NOT_NULL = 'RECHARGE_NOT_NULL',
        REASON_NOT_NULL = 'REASON_NOT_NULL',
        INVALID_TYPE_FLOW = 'INVALID_TYPE_FLOW',
        INVALID_TRANSACTION = 'INVALID_TRANSACTION',
        AMOUNT_NOT_MINUS_ZERO = 'AMOUNT_NOT_MINUS_ZERO',
        ECOMMERCE_ORDER_DOCUMENT_REQUIRED = 'ECOMMERCE_ORDER_DOCUMENT_REQUIRED',
        ECOMMERCE_ORDER_STORE_DOCUMENT_REQUIRED = 'ECOMMERCE_ORDER_STORE_DOCUMENT_REQUIRED',
        ECOMMERCE_ORDER_AMOUNT_REQUIRED = 'ECOMMERCE_ORDER_AMOUNT_REQUIRED',
        ECOMMERCE_ORDER_AMOUNT_MIN = 'ECOMMERCE_ORDER_AMOUNT_MIN',
        ECOMMERCE_ORDER_AMOUNT_MAX = 'ECOMMERCE_ORDER_AMOUNT_MAX',
        ECOMMERCE_ORDER_DISCOUNT_REQUIRED = 'ECOMMERCE_ORDER_DISCOUNT_REQUIRED',
        ECOMMERCE_ORDER_DISCOUNT_MIN = 'ECOMMERCE_ORDER_DISCOUNT_MIN',
        ECOMMERCE_ORDER_CATEGORY_REQUIRED = 'ECOMMERCE_ORDER_CATEGORY_REQUIRED',
        ECOMMERCE_ORDER_OWNID_REQUIRED = 'ECOMMERCE_ORDER_OWNID_REQUIRED',
        ECOMMERCE_ORDER_ITEMS_REQUIRED = 'ECOMMERCE_ORDER_ITEMS_REQUIRED',
        ECOMMERCE_ORDER_SKU_REQUIRED = 'ECOMMERCE_ORDER_SKU_REQUIRED',
        ECOMMERCE_ORDER_NAME_REQUIRED = 'ECOMMERCE_ORDER_NAME_REQUIRED',
        ECOMMERCE_ORDER_VALUE_REQUIRED = 'ECOMMERCE_ORDER_VALUE_REQUIRED',
        ECOMMERCE_ORDER_VALUE_MIN = 'ECOMMERCE_ORDER_VALUE_MIN',
        ECOMMERCE_ORDER_VALUE_MAX = 'ECOMMERCE_ORDER_VALUE_MAX',
        ECOMMERCE_ORDER_QUANTITY_REQUIRED = 'ECOMMERCE_ORDER_QUANTITY_REQUIRED',
        ECOMMERCE_ORDER_QUANTITY_MIN = 'ECOMMERCE_ORDER_QUANTITY_MIN',
        ECOMMERCE_ORDER_QUANTITY_MAX = 'ECOMMERCE_ORDER_QUANTITY_MAX',
        ECOMMERCE_ORDER_HOST_REQUIRED = 'ECOMMERCE_ORDER_HOST_REQUIRED',
        VIP_INVALID = 'VIP_INVALID',
        PARTICIPANT_INVALID = 'PARTICIPANT_INVALID',
        POINT_OF_SALE_INVALID = 'POINT_OF_SALE_INVALID',
        ORDER_EXPIRED = 'ORDER_EXPIRED',
        ORDER_REVERSED = 'ORDER_REVERSED',
        PRODUCT_ID_NOT_NULL = 'PRODUCT_ID_NOT_NULL',
        CUSTOMER_PASSWORD_INVALID = 'CUSTOMER_PASSWORD_INVALID',
        VIP_NOT_FOUND = 'VIP_NOT_FOUND',
        NSU_CANCEL_NOT_NULL = 'NSU_CANCEL_NOT_NULL',
    }


}
