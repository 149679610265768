import { memo, useCallback, useState } from 'react';
import { Switch, withStyles, createTheme, ThemeProvider, SwitchProps } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';

import theme from '../../styles/theme';
import { formatNumber, sendTagMessageToNative } from '../../utils';

import { Currency, Modal } from '../index';
import {
  Container,
  CoverageValue,
  CoverageValueLabel,
  Description,
  SeeMore,
  SliderWrapper,
  SubtitleModal,
  TagWrapper,
  TextContainer,
  TitleCoverage,
  TitleModal,
  TittleTagWrapper,
} from './styles';
import { AlignToLeft, HelperText, Tag } from '../styles';

export interface CoverageCoverProps {
  id: string;
  title: string;
  tagPrice?: number;
  price?: number;
  type?: string;
  customizationHelperText?: string;
  coveragesTag?: string;
  tagParam?: string;
  isChecked?: boolean;
  isBasic?: boolean;
  optionPrices?: number[];
  action?: (value?: any) => void;
  removeAction?: (value?: any) => void;
}

const CoverageCover = ({
  id,
  title,
  tagPrice,
  price = 0,
  type,
  customizationHelperText,
  coveragesTag,
  tagParam = '',
  isChecked = false,
  isBasic = false,
  optionPrices = [],
  action,
  removeAction,
}: CoverageCoverProps): JSX.Element => {
  const [checked, setChecked] = useState(isChecked);
  const [showModal, setShowModal] = useState(false);
  const [priceCustomValue, setPriceCustomValue] = useState<number>(price);

  const toggleModal = () => setShowModal(prev => !prev);

  const handleChange = (event: any, title: string) => {
    sendTagMessageToNative(coveragesTag || '', new Map([[tagParam, title]]));
    const hasChecked = event.target.checked;
    setChecked(hasChecked);

    if (action && hasChecked) {
      action({ id, name: priceCustomValue });
    }
    if (removeAction && !hasChecked) {
      removeAction({ id, name: priceCustomValue });
    }
  };
  const handleChangeSlider = (_, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setPriceCustomValue(newValue);
    }
  };

  const BlueSwitch = withStyles({
    switchBase: {},
    checked: {
      color: theme.colors.blueStrong,
      '&$checked': {
        color: theme.colors.blueStrong,
      },
      '&$checked + $track': {
        color: theme.colors.blueStrong,
      },
    },
    disabled: {
      color: theme.colors.blueGrayLight,
      '&$checked': {
        color: theme.colors.blueGrayLight,
      },
      '&$checked + $track': {
        color: theme.colors.blueGrayLight,
      },
    },
    track: {
      // Controls default (unchecked) color for the track
      opacity: 0.3,
      backgroundColor: theme.colors.blueStrong,
      '$checked$checked + &': {
        // Controls checked color for the track
        backgroundColor: theme.colors.blueStrong,
      },
    },
  })(Switch) as (props: SwitchProps) => JSX.Element;

  const BlueSlider = createTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: '#0063E7',
          height: 4,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: '#0063E7',
          border: '2px solid currentColor',
          marginTop: -10,
          marginLeft: -12,
          '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
          },
        },
        active: {},
        track: {
          color: '#0063E7',
          height: 4,
          borderRadius: 8,
        },
        rail: {
          opacity: 1,
          color: '#B3C4D0',
          height: 4,
          borderRadius: 8,
        },
      },
    },
  });
  const min = Math.min.apply(null, optionPrices);
  const max = Math.max.apply(null, optionPrices);
  const marks = optionPrices.map(item => ({ value: item, label: '' }));
  const optionDescriptionText = optionPrices.length === 1 || isChecked || checked ? 'Cobertura de' : 'Cobertura a partir de';

  const handleClickModal = useCallback(() => {
    toggleModal();
    setChecked(true);
    if (action) {
      action({ id, name: priceCustomValue });
    }
  }, [priceCustomValue]);

  return (
    <>
      <Container>
        <TextContainer>
          <TittleTagWrapper>
            <TitleCoverage>{title}</TitleCoverage>
            {tagPrice && (
              <TagWrapper>
                <Tag>
                  <Currency value={tagPrice} />
                </Tag>
              </TagWrapper>
            )}
          </TittleTagWrapper>
          {price !== 0 && (
            <Description>
              {optionDescriptionText} <Currency value={checked ? priceCustomValue : price} />
            </Description>
          )}
          {type && <Description>{type}</Description>}
          {(optionPrices.length !== 0 || !isBasic) && <SeeMore onClick={toggleModal}>Mudar valor</SeeMore>}
        </TextContainer>
        <BlueSwitch checked={checked} onChange={event => handleChange(event, title)} disabled={isChecked && isBasic} />
      </Container>
      {!!price && (
        <Modal
          open={showModal}
          onClick={handleClickModal}
          onClose={toggleModal}
          oneButton={isChecked && isBasic}
          twoButtons={!isChecked || !isBasic}
          confirmLabel={isChecked && isBasic ? 'Fechar' : 'Salvar cobertura'}
          buttonDisabled={false}
        >
          <div style={{ paddingInline: 32 }}>
            <TitleModal>Cobertura para {title.toLowerCase()}</TitleModal>
            <SubtitleModal>
              {customizationHelperText ?? `Escolha o valor de cobertura para ${title.toLowerCase()}.`}
            </SubtitleModal>
            <AlignToLeft>
              <CoverageValueLabel>Valor da cobertura</CoverageValueLabel>
              <CoverageValue>
                <Currency value={priceCustomValue} />
              </CoverageValue>
            </AlignToLeft>
            {(!isChecked || !isBasic) && (
              <SliderWrapper>
                <ThemeProvider theme={BlueSlider}>
                  <Slider
                    aria-label="Price options"
                    value={priceCustomValue}
                    step={null}
                    min={min}
                    max={max}
                    marks={marks}
                    onChange={handleChangeSlider}
                  />
                </ThemeProvider>
              </SliderWrapper>
            )}
            <HelperText>Valor máximo de {formatNumber.currency(max)}</HelperText>
          </div>
        </Modal>
      )}
    </>
  );
};

export default memo(CoverageCover);
