import { memo, useState } from 'react';
import { Carousel } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { Container, Indicators } from './styles';
import Slides from '../Slides';
import Button from '../Button';

export type SlideModel = {
  image: string;
  title: string;
  subtitle: string;
  buttonTag?: string;
  action?: () => void;
  to?: string;
  option?: string;
};

type CarouselProps = {
  items: SlideModel[];
};

const CarouselRenovation = ({ items }: CarouselProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const history = useHistory();

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const slides = items.map(item => (
    <Slides key={item.title} item={item} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} />
  ));

  const redirectTo = (url: string, option?: string) => (option ? history.push(url, { option }) : history.push(url));

  const hasButton = items.map(
    ({ buttonTag, to, option, title }, index) =>
      buttonTag &&
      index === activeIndex && (
        <Button
          key={`${buttonTag}${title}`}
          background="yellowStars"
          color="blueTypo"
          fullWidth
          paddingX={32}
          noMargin
          onClick={() => (to ? redirectTo(to, option) : next())}
        >
          {buttonTag}
        </Button>
      ),
  );

  return (
    <Container>
      <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={false}>
        {slides.length > 1 && <Indicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />}
        {slides}
      </Carousel>
      {hasButton}
    </Container>
  );
};

export default memo(CarouselRenovation);
