import { useEffect, useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

import CoverageCover, { CoverageCoverProps } from '../CoverageCover';

import { Container, Title } from './styles';
import theme from '../../styles/theme';

interface AccordionProps {
  coverages: CoverageCoverProps[];
  accordionTitle: string;
  showOpen?: boolean;
  coveragesTag?: string;
}

const Accordion = ({ accordionTitle, coverages, showOpen = false, coveragesTag }: AccordionProps): JSX.Element => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  useEffect(() => setShow(showOpen), [showOpen]);

  return (
    <>
      <Container borderBottom={!show} onClick={toggleShow}>
        <Title>{accordionTitle}</Title>
        {show ? (
          <BiChevronUp style={{ width: 24, height: 24, color: theme.colors.blueTypo }} />
        ) : (
          <BiChevronDown style={{ width: 24, height: 24 }} />
        )}
      </Container>
      {show &&
        coverages.map(coverage => (
          <CoverageCover
            key={`${coverage.id}-${coverage.title}`}
            id={coverage.id}
            coveragesTag={coveragesTag}
            price={coverage.price}
            title={coverage.title}
            type={coverage.type}
            customizationHelperText={coverage.customizationHelperText}
            tagParam={coverage.tagParam}
            isChecked={coverage.isChecked}
            isBasic={coverage.isBasic}
            optionPrices={coverage.optionPrices}
            action={coverage.action}
            removeAction={coverage.removeAction}
          />
        ))}
    </>
  );
};

export default Accordion;
