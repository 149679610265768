import { memo, useCallback, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';

import Portal from './Portal';
import { Button, Dialog, DialogContent, Icon, Overlay, Phone, Primario, Secundario } from './styles';
import { callDialer } from '../../utils';

interface ModalProps {
  buttonDisabled?: boolean;
  children: JSX.Element;
  onClose: (boolean) => void;
  onClick?: () => void;
  open: boolean;
  isHidden?: boolean;
  oneButton?: boolean;
  twoButtons?: boolean;
  confirmLabel?: string;
  closeLabel?: string;
  secondaryClick?: () => void;
  copied?: boolean;
  phone?: string;
  overlay?: () => void;
}

const Modal = ({
  buttonDisabled,
  children,
  onClose,
  onClick,
  open,
  isHidden = false,
  oneButton = false,
  twoButtons = false,
  confirmLabel = 'Confirmar',
  closeLabel = 'Fechar',
  secondaryClick,
  copied = false,
  phone = '',
  overlay,
}: ModalProps): JSX.Element => {
  const animation = useSpring({
    config: {
      duration: 250,
    },
    width: '100%',
    transform: open ? `translateY(0%)` : `translateY(150%)`,
  });

  const keyPress = useCallback(
    e => {
      if (buttonDisabled) return;

      if (e.key === 'Escape' && open) {
        onClose(prev => !prev);
      }
    },
    [buttonDisabled, onClose, open],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  return open ? (
    <Portal>
      <Overlay onClick={overlay ? () => overlay() : () => onClose(prev => !prev)}>
        <animated.div
          style={{
            ...animation,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'flex-end',
          }}
        >
          <Dialog onClick={() => (!overlay ? onClose(prev => !prev) : null)}>
            <Icon onClick={() => onClose(prev => !prev)} />
            <DialogContent>{children}</DialogContent>
            {(twoButtons || oneButton) && (
              <>
                {phone ? (
                  <Phone
                    onClick={() => {
                      if (onClick) onClick();
                      callDialer(phone);
                    }}
                  >
                    {confirmLabel}
                  </Phone>
                ) : (
                  <Primario
                    onClick={onClick ? () => onClick() : () => onClose(prev => !prev)}
                    noMargin
                    background={copied ? 'yellowStars' : 'blueStrong'}
                    color={copied ? 'blueTypo' : 'white'}
                  >
                    {confirmLabel}
                  </Primario>
                )}
                <Secundario
                  onClick={secondaryClick ? () => secondaryClick() : () => onClose(prev => !prev)}
                  color="blueStrong"
                  background="white"
                  noMargin
                  hidden={oneButton}
                >
                  {closeLabel}
                </Secundario>
              </>
            )}
          </Dialog>
          {!twoButtons && !oneButton && !isHidden && (
            <Button noMargin fullWidth disabled={buttonDisabled} onClick={onClick ? () => onClick() : () => onClose(false)}>
              {confirmLabel}
            </Button>
          )}
        </animated.div>
      </Overlay>
    </Portal>
  ) : (
    <></>
  );
};

export default memo(Modal);
