/* eslint-disable react/no-array-index-key */
import { Suspense } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

import { AFTER_SALES } from './afterSales';
import { CP_CVG, EntryPointPersonalLoans } from './cpCvg';
import { HOME, Home } from './home';
import { INSURANCE, InsuranceEntryPoint } from './insurance';
import { KTC } from './ktc';
import { PAYMENT } from './payment';
import { SURVEY } from './survey';

import { Loading } from '../components';

const dotEnv = process.env.REACT_APP_NAME || '';

const Routes = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const transitions = useTransition(location, {
    from: {
      opacity: 0,
      transform: `translate(${history.action === 'PUSH' ? '100%' : '-50%'},0)`,
    },
    enter: { opacity: 1, transform: 'translate(0%,0)' },
    leave: {
      opacity: 0,
      transform: `translate(${history.action === 'PUSH' ? '-50%' : '100%'},0)`,
      overflow: 'hidden',
    },
    overflow: 'hidden',
  });

  return transitions((props, item) => (
    <animated.div style={props}>
      <Suspense fallback={<Loading />}>
        <Switch location={item}>
          {/* Home - escolhido através do .env */}
          <Route exact path="/" component={EntryPointPersonalLoans} />
          <Route exact path="/insurance" component={InsuranceEntryPoint} />

          {/* Insurance */}
          {INSURANCE.map(({ path, component }, index) => (
            <Route exact path={path} component={component} key={String(index)} />
          ))}

          {/* After Sales */}
          {AFTER_SALES.map(({ path, component }, index) => (
            <Route exact path={path} component={component} key={String(index)} />
          ))}

          {/* Survey */}
          {SURVEY.map(({ path, component }, index) => (
            <Route exact path={path} component={component} key={String(index)} />
          ))}

          {/* Payment */}
          {PAYMENT.map(({ path, component }, index) => (
            <Route exact path={path} component={component} key={String(index)} />
          ))}

          {/* //Conhecendo o cliente 500km */}
          {KTC.map(({ path, component }, index) => (
            <Route exact path={path} component={component} key={String(index)} />
          ))}

          {/* CP CVG */}
          {CP_CVG.map((route, index) => (
            <Route exact path={route.path} component={route.component} key={String(index)} />
          ))}

          {/* Telas iniciais */}
          {HOME.map((route, index) => (
            <Route exact path={route.path} component={route.component} key={String(index)} />
          ))}

          {/* Caso seja digitado alguma rota inexistente roteia para o início */}
          <Route path="*" component={Home[dotEnv]} />
        </Switch>
      </Suspense>
    </animated.div>
  ));
};

export default Routes;
