import { memo, useCallback } from 'react';
import { CarouselItemProps } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { Currency, MapfreHeader } from '../index';
import { Offer } from '../../models/insurance/offer';
import { brandChange } from '../../store';
import { sortArray } from '../../utils';

import Button from '../Button';
import { SlideOfferLink, Check, SlideOfferContainer, SlideOfferLogoContainer, SlideOfferInfo, SlideOfferDetails } from './styles';

type SlideProps = CarouselItemProps<HTMLDivElement> & {
  offer: Offer;
  onSelect?: () => void;
  secondaryClick?: () => void;
  onAllCoverages?: () => void;
};

const brand = {
  bv: 'bvHeader',
  mapfre: 'mapfreHeader',
  TokyoMarine: 'tokyoHeader',
  question: 'questionCircle',
};

const SlideOffer = ({ offer, onSelect, secondaryClick, onAllCoverages }: SlideProps): JSX.Element => {
  const dispatch = useDispatch();

  const installments = `${offer.paymentOptions[0]?.installments ?? offer?.installmentsNumber}x `;
  const valueInstallments = offer.paymentOptions[0]?.valueInstallments ?? offer.installmentsValue;

  const saveBranch = useCallback((company: string) => dispatch(brandChange(brand[company])), []);

  const handleClick = useCallback(() => {
    saveBranch(offer.company);
    onSelect(offer.company);
  }, [offer.company, onSelect]);

  const handleSecondaryClick = useCallback(() => {
    saveBranch(offer.company);
    if (secondaryClick) secondaryClick(offer.company);
  }, [offer.company, secondaryClick]);

  const handleAllCoverages = useCallback(() => {
    saveBranch(offer.company);
    if (onAllCoverages) onAllCoverages();
  }, [offer.company, onAllCoverages]);

  const renderDetails = useCallback(() => {
    if (!offer || !offer?.coverages || offer.coverages.length === 0) return <></>;

    const basicCoverages = (offer?.coverages).filter(({ basic }) => basic);
    const filteredCoverages = sortArray(
      basicCoverages.filter((_, index) => index < 4),
      'description',
      'ASC',
    );

    return filteredCoverages.map(({ description, fipeText, insuredAmount, uuid, price }) => (
      <p className="coverage" key={`coverage-${uuid}`}>
        <Check />
        <span>
          {fipeText ? `${fipeText}` : <Currency value={insuredAmount ?? price} />}
          {` ${description.replace(/^\w{2,}\s-\s/gm, '')}`}
        </span>
      </p>
    ));
  }, [offer]);

  return (
    <SlideOfferContainer>
      <SlideOfferInfo>
        <SlideOfferLogoContainer>
          {offer.company === 'mapfre' ? <MapfreHeader style={{ margin: 0 }} /> : offer.company}
        </SlideOfferLogoContainer>
        <div className="texts">
          <div>
            <p>Corretora Protenseg</p>
            <p className="current">
              {installments} <Currency value={valueInstallments} />
            </p>

            {offer.cupom ? <p>Cupom de {offer.cupom} aplicado</p> : null}

            <p>
              Total de <Currency value={offer.paymentOptions[0]?.valueTotal ?? offer.offerValue - offer.cupom} />
            </p>
          </div>
        </div>
        <div className="buttons">
          <Button noMargin background="blueStrong" color="white" onClick={handleClick} fullWidth>
            Contratar plano
          </Button>
          <Button background="transparent" noMargin color="blueStrong" onClick={handleSecondaryClick}>
            Customizar coberturas
          </Button>
        </div>
      </SlideOfferInfo>

      <SlideOfferDetails>
        <div>{renderDetails()}</div>

        <p>
          <em>Proposta válida por 10 dias corridos</em>
        </p>
        <p>
          Confira <SlideOfferLink onClick={handleAllCoverages}>todas as coberturas</SlideOfferLink> do plano
        </p>
      </SlideOfferDetails>
    </SlideOfferContainer>
  );
};

export default memo(SlideOffer);
