/* eslint-disable react/jsx-props-no-spreading */
import { AllHTMLAttributes, useEffect, useState } from 'react';

import { ProgressBarStyled, ProgressStyled } from './styles';

export type ProgressBarProps = AllHTMLAttributes<HTMLDivElement> & {
  actualProgress?: number;
};

const ProgressBar = ({ actualProgress = 40 }: ProgressBarProps): JSX.Element => {
  const [progress, setProgress] = useState(actualProgress);

  useEffect(() => {
    setProgress(actualProgress);
  }, [actualProgress]);

  return (
    <>
      <ProgressBarStyled>
        <ProgressStyled widthValue={progress} />
      </ProgressBarStyled>
    </>
  );
};

export default ProgressBar;
