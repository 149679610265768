import { Container, CustomCheckbox, Label } from './styles';

interface CheckboxInsuranceProps {
  label: string;
  value: boolean;
  handleChange: (newValue) => void;
}

const CheckboxInsuranceCVG = ({ label, value, handleChange }: CheckboxInsuranceProps): JSX.Element => {
  return (
    <Container onClick={handleChange}>
      <CustomCheckbox checked={value} />
      <div>
        <Label>{label}</Label>
      </div>
    </Container>
  );
};

export default CheckboxInsuranceCVG;
