/* eslint-disable @typescript-eslint/no-empty-function */
import React, { memo, useEffect, useRef, useState } from 'react';

import Loading from '../Loading';

import {
  ContainerAuthentication,
  ContentInputAuthentication,
  HelperAuthentication,
  InputAuthentication,
  TitleAuthentication,
} from './styles';

type SMSCodeProps = {
  title?: string;
  helperText?: string;
  valid: (value: boolean) => void;
};

const SMSCode = ({ title, helperText, valid }: SMSCodeProps) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const firstRef = useRef<HTMLInputElement>(null);
  const secondRef = useRef<HTMLInputElement>(null);
  const thirdRef = useRef<HTMLInputElement>(null);
  const fourthRef = useRef<HTMLInputElement>(null);
  const fifthRef = useRef<HTMLInputElement>(null);
  const sixthRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, attributes, name } = event.target;

    if (value.length === 0) {
      setHasError(false);
      return;
    }

    // if (Number.isNaN(parseFloat(value))) {
    //   setHasError(true);
    //   return;
    // }

    // eslint-disable-next-line dot-notation
    const maxLength = Number(attributes['maxlength'].value);
    const hasNextElement = event.target.nextElementSibling?.tagName.toLowerCase() === 'input';

    if (value.length === maxLength && hasNextElement) {
      if (name === 'first') {
        secondRef.current?.focus();
        return;
      }

      if (name === 'second') {
        thirdRef.current?.focus();
        return;
      }

      if (name === 'third') {
        fourthRef.current?.focus();
        return;
      }

      if (name === 'fourth') {
        fifthRef.current?.focus();
        return;
      }

      sixthRef.current?.focus();
    }

    if (value.length === maxLength && name === 'sixth') {
      valid(true);
    }
  };

  const handleKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { name } = event.currentTarget;

    if (event.key === 'Backspace' || event.which === 8) {
      valid(false);
      if (name === 'first') {
        return;
      }

      if (name === 'second') {
        firstRef.current?.focus();
        return;
      }

      if (name === 'third') {
        secondRef.current?.focus();
        return;
      }

      if (name === 'fourth') {
        thirdRef.current?.focus();
        return;
      }

      if (name === 'fifth') {
        fourthRef.current?.focus();
        return;
      }

      if (name === 'sixth') {
        fifthRef.current?.focus();
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);

    setTimeout(() => {
      firstRef.current?.focus();
    }, 1000);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <ContainerAuthentication>
      <TitleAuthentication>{title || 'Text field'}</TitleAuthentication>

      <ContentInputAuthentication>
        <InputAuthentication ref={firstRef} name="first" onChange={handleChange} onKeyUp={handleKey} error={hasError} />
        <InputAuthentication ref={secondRef} name="second" onChange={handleChange} onKeyUp={handleKey} error={hasError} />
        <InputAuthentication ref={thirdRef} name="third" onChange={handleChange} onKeyUp={handleKey} error={hasError} />
        <InputAuthentication ref={fourthRef} name="fourth" onChange={handleChange} onKeyUp={handleKey} error={hasError} />
        <InputAuthentication ref={fifthRef} name="fifth" onChange={handleChange} onKeyUp={handleKey} error={hasError} />
        <InputAuthentication ref={sixthRef} name="sixth" onChange={handleChange} onKeyUp={handleKey} error={hasError} />
      </ContentInputAuthentication>

      <HelperAuthentication>{helperText || 'Helper text'}</HelperAuthentication>
    </ContainerAuthentication>
  );
};

export default memo(SMSCode);
