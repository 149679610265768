import { AxiosResponse } from 'axios';
import { ErrorApi } from './ErrorApi';
import { ApiError, ResponseError } from '../generated/personal-loans';
import { isProdEnv, returnToNative } from '../../utils';

const genericErrorTexts = {
  title: 'Tivemos alguns problemas internos, tente novamente',
  text: 'Não conseguimos dar continuidade em sua cotação. Tente novamente e se o problema continuar entre em contato com nosso atendimento (0800 839292).',
};

export const GenericErrors = {
  INTERNAL: {
    title: 'Sistema indisponível',
    text: 'Desculpe, nosso sistema está indisponível no momento, tente novamente em alguns minutos.',
    errorCode: '001',
  } as ErrorApi,
  EXTERNAL: {
    title: 'Sistema indisponível',
    text: 'Desculpe, nosso sistema está indisponível no momento, tente novamente em alguns minutos.',
    errorCode: '222',
  } as ErrorApi,
  CPF_RESTRICTION: {
    ...genericErrorTexts,
    errorCode: '223',
  } as ErrorApi,
  CONNECTION: {
    title: 'Verifique sua conexão e tente novamente',
    text: 'Você está sem internet no momento, verifique seu wifi ou rede móvel e tente novamente.',
    errorCode: '000',
  } as ErrorApi,
  INVALID_VEHICLE_UF: {
    title: 'O estado (UF) do seu cadastro está divergente do estado (UF) do veículo',
    text: 'Verifique os dados informados e se necessário atualizar o cadastro, entre em contato com (11) 3003-3424.',
    errorCode: '224',
  } as ErrorApi,
  NOT_ALLOWED: {
    title: 'Usuário não autorizado',
    text: 'Usuário não está presente na allowlist.',
    errorCode: '225',
  } as ErrorApi,
  INVALID_AUTH: {
    ...genericErrorTexts,
    text: 'Desculpe! O prazo para efetivação da simulação expirou, será necessário iniciar uma nova simulação.',
    errorCode: '007',
    httpStatus: '401',
  } as ErrorApi,
  ERROR_SUT: {
    ...genericErrorTexts,
    errorCode: '240',
  } as ErrorApi,
  CUSTOMER_NOT_FOUND: {
    ...genericErrorTexts,
    title: 'Sistema indisponível',
    text: 'Desculpe, nosso sistema está indisponível no momento, tente novamente em alguns minutos.',
    errorCode: '241',
  } as ErrorApi,
  BOLETO_GENERATION_ERROR: {
    ...genericErrorTexts,
    title: 'Não conseguimos gerar seu boleto através do app.',
    text: 'Acesse o site <a href="http://www.bv.com.br/boleto" target="_blank" rel="noopener noreferrer">http://www.bv.com.br/boleto</a> ou entre em contato com (11) 3003-1616 para emissão da 2º via.',
    errorCode: '255',
  } as ErrorApi,
  CREDIT_CARD_NOT_FOUND: {
    ...genericErrorTexts,
    title: 'Nenhum Cartão encontrado',
    text: `Favor cadastrar novo cartão para continuar ou escolha Débito em conta.\n\n${genericErrorTexts.text}`,
    errorCode: '242',
  } as ErrorApi,
  INVALID_INSURANCE_HOLDER_MARITAL_STATUS_NOT_BLANK: {
    ...genericErrorTexts,
    title: 'Informe o estado civil do segurado!',
    text: `Estado Civíl não informado. Por favor revise os dados informados e tente novamente!`,
    errorCode: '243',
  } as ErrorApi,
  INVALID_DOCUMENT: {
    ...genericErrorTexts,
    title: 'Documento inválido',
    text: `Verifique o número do documento informado pois tem algo de errado.\n\n${genericErrorTexts.text}`,
    errorCode: '244',
  } as ErrorApi,
  INVALID_ZIPCODE: {
    ...genericErrorTexts,
    title: 'Informe um CEP válido!',
    text: `CEP digitado inválido ou ausente.\n\n${genericErrorTexts.text}`,
    errorCode: '245',
  } as ErrorApi,
  INVALID_VEHICLE_NOT_FOUND: {
    ...genericErrorTexts,
    title: 'Veiculo não localizado!',
    text: `Verifique a placa informada.\n\n${genericErrorTexts.text}`,
    errorCode: '246',
  } as ErrorApi,
};

const knowErrorsList: ErrorApi[] = Object.keys(GenericErrors).map(item => {
  return GenericErrors[item] as ErrorApi;
});

export const newException = (
  errorCode?: string,
  httpStatus?: string,
  title?: string,
  text?: string,
  retryAction?: () => void,
): ErrorApi => {
  return {
    text: text || '',
    title: title || '',
    errorCode: errorCode || '',
    httpStatus: httpStatus || '',
    retryAction,
  };
};

const getResponseStatusErrorCode = (apiError: ApiError): string => {
  const { error } = apiError?.body?.cause?.errors[0];
  const listCreditCardsNotFound = [
    ResponseError.error.PRE_PAID_CUSTOMER_CREDIT_CARD_NOT_FOUND,
    ResponseError.error.CREDIT_CARD_NOT_FOUND,
  ];

  if (error === ResponseError.error.INVALID_BV_INTEGRATION) {
    return GenericErrors.EXTERNAL.errorCode as string;
  }
  if (error === ResponseError.error.INVALID_SIMULATION_QUOTE_WITH_RESTRICTION) {
    return GenericErrors.CPF_RESTRICTION.errorCode as string;
  }
  if (error === ResponseError.error.INVALID_VEHICLE_UF_DOES_NOT_MATCH_EXCEPTION) {
    return GenericErrors.INVALID_VEHICLE_UF.errorCode as string;
  }
  if (error === ResponseError.error.INSURANCE_SUTHUB_INTEGRATION_ERROR) {
    return GenericErrors.ERROR_SUT.errorCode as string;
  }
  if (error === ResponseError.error.CUSTOMER_NOT_FOUND) {
    return GenericErrors.CUSTOMER_NOT_FOUND.errorCode as string;
  }
  if (listCreditCardsNotFound.includes(error)) {
    return GenericErrors.CREDIT_CARD_NOT_FOUND.errorCode as string;
  }
  if (error === ResponseError.error.INVALID_CUSTOMER_ALLOWLIST_NOT_FOUND) {
    return GenericErrors.NOT_ALLOWED.errorCode as string;
  }
  if (error === ResponseError.error.INVALID_INSURANCE_HOLDER_MARITAL_STATUS_NOT_BLANK) {
    return GenericErrors.INVALID_INSURANCE_HOLDER_MARITAL_STATUS_NOT_BLANK.errorCode as string;
  }
  if (error === ResponseError.error.INVALID_DOCUMENT) {
    return GenericErrors.INVALID_DOCUMENT.errorCode as string;
  }
  if ([ResponseError.error.INVALID_ZIPCODE, 'INVALID_CEP_VALUE'].includes(error)) {
    return GenericErrors.INVALID_ZIPCODE.errorCode as string;
  }
  if (error === ResponseError.error.INVALID_VEHICLE_NOT_FOUND) {
    return GenericErrors.INVALID_VEHICLE_NOT_FOUND.errorCode as string;
  }
  if (error?.status && error?.status === 400 && error === 'INTERNAL_SERVER_ERROR') {
    return '500';
  }
  return error?.status?.toString() || '550';
};

export const handleAxiosException = (error: AxiosResponse): ApiError => {
  return {
    status: error.status,
    body: error.data,
    message: error.statusText,
    name: '',
    stack: '',
    statusText: '',
    url: '',
  };
};

function getErrorObject(errorCodeOrigin: string): ErrorApi {
  return (
    knowErrorsList.find(({ errorCode, httpStatus }) => (httpStatus || errorCode) === errorCodeOrigin) || GenericErrors.INTERNAL
  );
}

export const handleException = (error: ApiError, retryAction?: () => void): ErrorApi => {
  if (!isProdEnv()) console.log({ ...error });
  try {
    if (error?.status) {
      const errorCode = getResponseStatusErrorCode(error);
      const errorObject = getErrorObject(errorCode);
      const retryActionRevised = error.status === 401 ? returnToNative : retryAction;

      return {
        ...errorObject,
        httpStatus: errorCode,
        retryAction: retryActionRevised,
        exceptionCode: error?.body?.cause?.errors[0]?.error,
      };
    }
    if (error?.toString() === 'TypeError: Failed to fetch') {
      return { ...GenericErrors.CONNECTION, retryAction };
    }
    return { ...GenericErrors.INTERNAL, retryAction };
  } catch (e) {
    console.error('>>>>>>>>>', e);
    return { ...GenericErrors.INTERNAL, retryAction };
  }
};
