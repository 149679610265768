import { ErrorApi } from '../../api';
import { LoadingProps } from '../../data';

import { AbsoluteWrapper, ErrorModal, HeaderForm, Loading, LoadingObj } from '..';
import { Body, Container } from './styles';

type LayoutFormProps = {
  children?: JSX.Element | JSX.Element[];
  noPaddingX?: boolean;
  stepper?: 'yellow' | 'top' | 'none';
  closeAction?: () => void;
  backAction?: () => void;
  helpAction?: () => void;
  onlyCustomBackAction?: boolean;
  hideBackButton?: boolean;
  colorbackground?: 'blueTypo' | 'white';
  isLoading?: boolean | LoadingProps;
  isError?: ErrorApi;
  actionPrimaryButton?: () => void;
};

const LayoutForm = ({
  children,
  noPaddingX = false,
  stepper = 'top',
  closeAction,
  backAction,
  helpAction,
  onlyCustomBackAction = false,
  hideBackButton,
  colorbackground = 'white',
  isLoading = false,
  isError,
  actionPrimaryButton,
}: LayoutFormProps): JSX.Element => {
  return (
    <AbsoluteWrapper>
      <Container colorbackground={colorbackground}>
        <HeaderForm
          stepper={stepper}
          closeAction={closeAction}
          backAction={backAction}
          helpAction={helpAction}
          hideBackButton={hideBackButton}
          colorbackground={colorbackground}
          onlyCustomBackAction={onlyCustomBackAction}
        />
        <Body noPaddingX={noPaddingX}>
          {children}

          {isLoading === true && !isError && <Loading />}

          {typeof isLoading === 'object' && !isError && (
            <LoadingObj props={isLoading.props} totalProgress={isLoading.totalProgress} />
          )}

          {isError && <ErrorModal actionPrimaryButton={actionPrimaryButton} errorDetails={isError} />}
        </Body>
      </Container>
    </AbsoluteWrapper>
  );
};

export default LayoutForm;
