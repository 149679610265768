import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { changePersonalCreditInfoAction } from '../../../actions';
import { customerIsAllowed, GenericErrors, getAllSimulationsByUser, handleException } from '../../../api';
import { selectPersonalCredit } from '../../../store';
import { Home } from './styles';
import { CircularLoading } from '../../../components/Loading';
import { CustomerLoanResponse, ResponseError } from '../../../api/generated/personal-loans';
import { returnToNative } from '../../../utils';
import { SimulationStatusEnum } from '../../../models';
import { isDev } from '../../../utils/isDev';
import { resolveFakeSimulationQuoteData } from '../AfterSales/ConsultContract/fallback';

const EntryPointPersonalLoans = (): JSX.Element => {
  const { currentQuote } = useSelector(selectPersonalCredit);
  const dispatch = useDispatch();
  const history = useHistory();

  const resolveSimulationResponse = async response => {
    const customerAllowed = response?.length > 0 || (await customerIsAllowed());
    const simulations = response?.filter(simulation => simulation?.status !== SimulationStatusEnum.DISAPPROVED) || [];
    const hasSomeCalculatedSimulation = simulations.some(quote => quote?.valueLoan);
    const { firstName, document } =
      simulations.find(({ customerLoan }) => customerLoan?.firstName)?.customerLoan || ({} as CustomerLoanResponse);
    dispatch(
      changePersonalCreditInfoAction({
        customer: {
          firstName,
          document,
        },
        quotes: simulations,
        currentQuote: {
          ...currentQuote,
          quoteOptions: {
            ...currentQuote.quoteOptions,
            customerAllowed,
          },
        },
      }),
    );
    if (simulations.length === 0) {
      history.push('/start');
    } else if (simulations?.length > 0 && hasSomeCalculatedSimulation) {
      history.push('/cvg/afterSales');
    } else {
      history.push('/start');
    }
  };

  const checkForOpenedSimulations = () => {
    getAllSimulationsByUser()
      .then(resolveSimulationResponse)
      .catch(async reason => {
        const errorApi = handleException(reason);
        if (errorApi.errorCode === GenericErrors.NOT_ALLOWED.errorCode) {
          dispatch(
            changePersonalCreditInfoAction({
              currentQuote: {
                ...currentQuote,
                quoteOptions: {
                  ...currentQuote.quoteOptions,
                  customerAllowed: false,
                },
              },
            }),
          );
          history.push('/start');
        } else if (errorApi.exceptionCode === ResponseError.error.INVALID_SIMULATIONQUOTE_NOT_FOUND) {
          const customerAllowed = await customerIsAllowed();
          dispatch(
            changePersonalCreditInfoAction({
              currentQuote: {
                ...currentQuote,
                quoteOptions: {
                  ...currentQuote.quoteOptions,
                  customerAllowed,
                },
              },
            }),
          );
          history.push('/start');
        } else if (errorApi.exceptionCode === ResponseError.error.PERSONAL_LOANS_CUSTOMER_BLOCKED_BY_DOCUMENT_REFUSAL) {
          const customerAllowed = false;
          dispatch(
            changePersonalCreditInfoAction({
              currentQuote: {
                ...currentQuote,
                quoteOptions: {
                  ...currentQuote.quoteOptions,
                  customerAllowed,
                },
              },
            }),
          );

          history.push('/waiting-for-approval', { choice: 'denied' });
        } else {
          if (isDev) {
            return resolveFakeSimulationQuoteData()
              .then(response => {
                console.log('simulation_quote mocked due to an error', errorApi);
                resolveSimulationResponse(response);
              })
              .catch(error => console.log(error));
          }

          return setTimeout(() => {
            returnToNative();
          }, 2000);
        }

        return null;
      });
  };

  useEffect(() => {
    checkForOpenedSimulations();
  }, []);

  return (
    <Home style={{ justifyContent: 'center' }}>
      <CircularLoading thickness={3} size={80} />
    </Home>
  );
};

export default EntryPointPersonalLoans;
