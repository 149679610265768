import { memo, useEffect, useState } from 'react';
import JSBarcode, { BaseOptions } from 'jsbarcode';

import Modal from '../Modal';
import { PBarCodeModal, TitleBarCodeModal } from './styles';
import { copyToClipboard, humanFormatedJoin, pluralSuffix } from '../../utils';
import { Boleto } from '../../api/models/personal-loans';
import { Currency } from '../index';

type BarCodeModalProps = {
  boleto?: Boleto;
  onClick?: () => void;
  onClose?: (value: boolean) => void;
  secondaryClick?: () => void;
  open?: boolean;
};

const BarCodeModal = ({ boleto, onClick, onClose, secondaryClick, open = false }: BarCodeModalProps): JSX.Element => {
  const [valueToPrepare, setValueToPrepare] = useState<string | number>('');
  const [isOpened, setIsOpened] = useState(false);
  const [textCopied, setTextCopied] = useState(false);
  const [textCopy, setTextCopy] = useState('');
  const { dueDate, installments } = boleto || ({} as Boleto);

  const copyBarCode = async value => {
    copyToClipboard(value);
    setTextCopy(!textCopy ? value : textCopy); // TODO - 'textCopy' não está sendo usado em nenhum lugar
  };

  const options: BaseOptions = {
    width: 1,
    height: 74,
    lineColor: '#002063',
    margin: 0,
    marginBottom: 20,
    displayValue: false,
  };

  useEffect(() => {
    const digitableLine = boleto?.digitableLine || '';
    if (!valueToPrepare) {
      setValueToPrepare(digitableLine);
    }
    if (isOpened) {
      const svgElement = document.getElementById('barcode');
      const preparedValue = String(digitableLine).replaceAll('.', '').replaceAll(' ', '');
      JSBarcode(svgElement, preparedValue, options);
    }
  }, [isOpened]);

  useEffect(() => {
    setIsOpened(open);
  }, [open]);

  return (
    <Modal
      open={isOpened}
      onClose={onClose ?? setIsOpened}
      confirmLabel={textCopied ? 'Código copiado' : 'Copiar código'}
      onClick={() => {
        if (onClick) onClick();
        setTextCopied(true);
        copyBarCode(valueToPrepare);
      }}
      secondaryClick={() => {
        if (secondaryClick) secondaryClick();
        setIsOpened(false);
      }}
      twoButtons
      copied={textCopied}
    >
      <>
        <TitleBarCodeModal>Copie o código do boleto</TitleBarCodeModal>
        <PBarCodeModal>
          <strong>
            <Currency value={boleto?.value} />
          </strong>
          {'\n'}
          {`Parcela${pluralSuffix(installments)} ${humanFormatedJoin(
            installments?.map(installment => installment?.installment),
          )}`}
          {'\n'}
          {`Esse boleto vence dia ${dueDate}`}
        </PBarCodeModal>

        <div style={{ width: 300, margin: '0 auto' }}>
          <svg xmlns="http://www.w3.org/2000/svg" id="barcode" aria-label="código de barra a ser pago" />
        </div>

        <PBarCodeModal>{valueToPrepare}</PBarCodeModal>
      </>
    </Modal>
  );
};

export default memo(BarCodeModal);
