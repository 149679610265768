import { Content, Currency } from '../index';
import { Domain } from '../../models';
import { ButtonInline, ButtonsContainer, Container, Description, Title, ValueLabel, Warning } from './styles';

export interface CoverageCoverProps {
  title: string;
  description: string;
  options: Domain[];
  action?: (value: Domain) => void;
  warning?: string;
  installmentValue?: number;
  installmentSelected?: Domain;
}

const MultiButtons = ({
  title,
  description,
  options,
  action,
  warning,
  installmentValue,
  installmentSelected,
}: CoverageCoverProps): JSX.Element => {
  const handleClick = (newValue: Domain) => {
    if (action) {
      action(newValue);
    }
  };

  const buttons = options.map(item => (
    <ButtonInline key={item.id} item={item} onClick={() => handleClick(item)} active={item.id === installmentSelected?.id}>
      {item.id}
    </ButtonInline>
  ));
  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <ValueLabel>
          {installmentSelected?.id}X <Currency value={installmentValue || 0} />
        </ValueLabel>
        <Description>{description}</Description>
      </Content>
      <ButtonsContainer>{buttons}</ButtonsContainer>
      {warning && <Warning>{warning}</Warning>}
    </Container>
  );
};

export default MultiButtons;
