import { Dispatch } from 'redux';
import { sortArray } from './arrayOperations';

import { changePersonalCreditInfoAction } from '../actions';
import {
  getDomainCitiesByFederativeUnit,
  getDomainProfessionalTypes,
  getDomainProfessions,
  getProposalLoan,
  InstallmentePaymentStatusEnum,
} from '../api';
import {
  ContractOriginationInstallment,
  ContractOriginationResponse,
  SimulationQuoteResponse,
  VehicleResponse,
} from '../api/generated/personal-loans';
import { CustomerData, OfferCredit } from '../api/models/personal-loans';
import { FlowSteps, getGenderById, getNameById, getResultQuote, numberOfInstallments, States } from '../data';
import { formatDate } from './formatDate';
import { CarInfo, Domain, InstalmentsLoan, LoanReduxModel, LoanTypeEnum, QuoteReduxModel } from '../models';
import { PersonalLoansReduxModel } from '../reducers/personalLoansReducer';
import { currentStep, StepProps, store } from '../store';
import { formatNumber } from './formatNumber';
import { capitalizeEveryFirstLetter } from './string';
import { percentOfValue } from './percentOfValue';
import { infoIcon } from '../assets';
import { isDev } from './isDev';
import { resolveFakeOccupationData, resolveFakeProfessionTypeData } from '../pages/CpCvg/Documents/SalaryOccupation/fallback';
import { resolveFakeFederativeData } from '../pages/CpCvg/Documents/Naturalness/fallback';

const getRedux = () => {
  const { currentQuote, customer, loan, loans, quotes } = store.getState().personalCredit;
  return { currentQuote, customer, loan, loans, quotes };
};
const convertModel = (
  currentQuote: QuoteReduxModel,
  quoteSimulation: SimulationQuoteResponse,
  loanType?: LoanTypeEnum,
  offerCredit?: OfferCredit,
): QuoteReduxModel => {
  const rgDocument = quoteSimulation?.customerLoan?.customerDocuments?.find(document => document?.type?.toUpperCase() === 'RG');
  const cnhDocument = quoteSimulation?.customerLoan?.customerDocuments?.find(document => document?.type?.toUpperCase() === 'CNH');
  const motherName = quoteSimulation?.customerLoan?.motherName;
  const flowSimulation = quoteSimulation?.flowSimulation;
  const { currentQuote: oldCurrentQuote, quotes } = getRedux();
  const resultQuote = quotes?.find(({ id }) => id === quoteSimulation.id)?.resultQuote;

  return {
    ...oldCurrentQuote,
    quoteId: quoteSimulation?.id as string,
    flowSimulation,
    loan: {
      ...oldCurrentQuote?.loan,
      loanType,
      resultQuote,
      installmentPlan: { id: `${quoteSimulation?.countInstallments}` },
    },
    customerLoan: { id: quoteSimulation?.customerLoan?.id as string },
    warrantyCar: {
      ...oldCurrentQuote?.warrantyCar,
      uuid: quoteSimulation?.vehicle?.id,
      licensePlate: quoteSimulation?.vehicle?.licensePlate,
      categoryDescription: quoteSimulation?.vehicle?.categoryDescription,
      brand: { id: quoteSimulation?.vehicle?.brand || '', name: quoteSimulation?.vehicle?.brand },
      model: { id: quoteSimulation?.vehicle?.modelVersion || '', name: quoteSimulation?.vehicle?.modelVersion },
      modelVersion: {
        id: quoteSimulation?.vehicle?.model || '',
        name: quoteSimulation?.vehicle?.model?.replace(quoteSimulation?.vehicle?.modelVersion || '', '')?.trim(),
      },
      licensedState: getNameById(quoteSimulation?.vehicle?.stateLicenseVehicle),
      manufactoreYear: {
        id: quoteSimulation?.vehicle?.manufactoreYear || '',
        name: quoteSimulation?.vehicle?.manufactoreYear,
      },
      modelYear: Number(quoteSimulation?.vehicle?.modelYear),
      renavam: quoteSimulation?.vehicle?.renavan,
    },
    quoteOptions: {
      ...currentQuote?.quoteOptions,
      iofAD: offerCredit?.iofAD,
      iofRate: offerCredit?.aliquota,
    },
    personalInfo: {
      ...currentQuote?.personalInfo,
      monthlySalary: quoteSimulation?.customerLoan?.monthlyIncome,
      patrimony: quoteSimulation?.customerLoan?.totalPatrimony,
      gender: getGenderById(quoteSimulation?.customerLoan?.gender),
      occupation: {
        id: quoteSimulation?.customerLoan?.occupationCode?.toString() ?? '',
      },
      workingStatus: {
        id: quoteSimulation?.customerLoan?.occupationType?.toString() ?? '',
      },
      nacionality: {
        id: quoteSimulation?.customerLoan?.nacionality || '',
      },
      placeOfBirthState: {
        id: quoteSimulation?.customerLoan?.placeOfBirthState || '',
      },
      placeOfBirthCity: {
        id: '',
        name: quoteSimulation?.customerLoan?.placeOfBirthCity,
      },
      maritalStatus: {
        id: '',
        name: quoteSimulation?.customerLoan?.maritalStatus,
      },
      motherName,
    },
    documents: {
      ...currentQuote?.documents,
      rg: rgDocument && {
        type: 'RG',
        uuid: rgDocument.uuid,
        issuerAuthority: rgDocument.issuingAuthority,
        number: rgDocument.document,
        issuerCity: rgDocument.issuingState,
        issueDate: rgDocument.issueDate,
        issuerState: rgDocument.issuingState,
      },
      cnh: cnhDocument && {
        type: 'CNH',
        uuid: cnhDocument.uuid,
        issuerAuthority: cnhDocument.issuingAuthority,
        number: cnhDocument.document,
        issuerCity: cnhDocument.issuingState,
        issueDate: cnhDocument.issueDate,
        issuerState: cnhDocument.issuingState,
      },
    },
    benefit: {
      uuid: quoteSimulation?.proposalLoan?.benefit?.uuid,
      benefitType: quoteSimulation?.proposalLoan?.benefit?.benefitType,
    },
    depositBank: {
      bank: { id: quoteSimulation?.customerLoan?.bankAccountResponse?.code || '' },
      agency: quoteSimulation?.customerLoan?.bankAccountResponse?.agency,
      account: quoteSimulation?.customerLoan?.bankAccountResponse?.number,
      checkNumber: quoteSimulation?.customerLoan?.bankAccountResponse?.checkNumber,
    },
  };
};

export const creditOfferToRedux = (
  currentQuote: QuoteReduxModel,
  quoteSimulation: SimulationQuoteResponse,
  loanType?: LoanTypeEnum,
  offerCredit?: OfferCredit,
  customerData?: CustomerData,
): PersonalLoansReduxModel => {
  const { customer, loan, loans, quotes } = getRedux();
  return {
    customer: {
      ...customer,
      ...customerData,
    },
    currentQuote: convertModel(currentQuote, quoteSimulation, loanType, offerCredit),
    loan,
    loans,
    quotes,
  };
};
export const convertContractDetails = (contract: ContractOriginationResponse): LoanReduxModel | undefined => {
  if (!contract.contractOriginationBody || !contract.contractOriginationBody.contratoDadosComplementares) return undefined;

  const { contratoDadosComplementares, contrato } = contract?.contractOriginationBody;
  const { contractOriginationInstallmentFinance, installmentDueDate, installmentLastDate, installmentValue } =
    contratoDadosComplementares;

  const installments = sortArray(contractOriginationInstallmentFinance?.contractOriginationInstallment, 'installment', 'ASC');

  const firstInstallment = formatDate.ISOtoDate(installmentDueDate);
  const list = installments as ContractOriginationInstallment[];
  let paid = 0;
  let remaining = 0;

  installments?.forEach(installment => {
    const { installmentCodeSituation } = installment;

    const hasItemDuplicate = list.findIndex(
      i => i.installment === installment.installment && i.installmentCodeSituation === InstallmentePaymentStatusEnum.LATE,
    );

    if (
      installmentCodeSituation === InstallmentePaymentStatusEnum.PAID_AFTER_DUE_DATE ||
      installmentCodeSituation === InstallmentePaymentStatusEnum.PAID_IN_TIME
    ) {
      paid += 1;
      if (hasItemDuplicate >= 0) list.splice(hasItemDuplicate, 1);
    }

    if (
      installmentCodeSituation === InstallmentePaymentStatusEnum.LATE ||
      installmentCodeSituation === InstallmentePaymentStatusEnum.UP_TO_DATE
    )
      remaining += 1;
  });

  const installmentDetails: InstalmentsLoan = {
    overdueInstallments: list.filter(i => i.installmentCodeSituation === InstallmentePaymentStatusEnum.LATE),
    paid,
    remaining,
    total: list.length,
    current: list?.find(i => i.installmentCodeSituation === InstallmentePaymentStatusEnum.UP_TO_DATE),
    dueDayOfMonth: firstInstallment?.getDay(),
    firstDueDate: formatDate.toPTBR(firstInstallment),
    lastDueDate: formatDate.toPTBR(installmentLastDate),
    list,
    value: installmentValue,
  };

  const loanType = contrato?.modalDesc === 'CREDITO PESSOAL' ? LoanTypeEnum.CP : LoanTypeEnum.CVG;

  return {
    loanType,
    loanValueTransferedToCustome: false,
    installments: installmentDetails,
    proposalNumber: contrato?.proposalNumber,
    contractNumber: contrato?.contractNumber,
  };
};

type HistoryProps = {
  push(path: string, state?: unknown): void;
};

type LocationProps = {
  state: any;
};

type GetProposalProps = {
  history: HistoryProps;
  setLoading: (value: boolean) => void;
  dispatch: Dispatch<any>;
  location?: LocationProps;
  step?: StepProps;
};

type RedirectToProps = GetProposalProps & {
  status?: string;
};

const dispatchToDocumentation = (dispatch: Dispatch<any>) => {
  const { currentQuote } = getRedux();
  dispatch(
    changePersonalCreditInfoAction({
      currentQuote: {
        ...currentQuote,
        flowSimulation: {
          ...currentQuote.flowSimulation,
          step: FlowSteps.DOCUMENTATION,
        },
      },
    }),
  );
};

const redirectTo = (props: RedirectToProps) => {
  const { dispatch, history, setLoading, location, status = '', step } = props;

  setLoading(false);
  if (location?.state) {
    history.push(`${location.state}`);
    return;
  }

  if (dispatch && step) {
    dispatch(currentStep(step.currentStep + 1));
  }

  if (status === 'BV_PROCESS_ERROR') {
    history.push('/');
    return;
  }

  if (['BV_CREDIT_REFUSED', 'BV_WARRANTY_REFUSED'].includes(status)) {
    history.push('/waiting-for-approval', { choice: status });
    return;
  }

  dispatchToDocumentation(dispatch);
  history.push('/waiting-for-approval', { choice: 'cvg' });
};

export const getProposal = (props: GetProposalProps) => {
  const { history, setLoading, location, dispatch } = props;
  getProposalLoan()
    .then(result => redirectTo({ history, setLoading, location, status: result.status, dispatch }))
    .catch(() => console.log('Aguarde mais um pouco, ainda não temos o resultado'));

  let counter = 1;

  const timer = setInterval(() => {
    console.log(`TimerID: ${timer} - Contador é: ${counter} - Hora agora: ${new Date().toLocaleTimeString('pt-BR')}`);
    getProposalLoan()
      .then(result => {
        console.log(`O TimerID: ${timer} acabou as ${new Date().toLocaleTimeString('pt-BR')}, na ${counter}ª tentativa`);
        clearInterval(timer);

        redirectTo({ history, setLoading, location, status: result.status, dispatch });
      })
      .catch(() => {
        console.log('Aguarde mais um pouco, ainda não temos o resultado');

        if (counter >= 5) {
          console.log(`O TimerID: ${timer} acabou as ${new Date().toLocaleTimeString('pt-BR')}`);
          clearInterval(timer);
          setLoading(false);
          history.push('/');
          return;
        }
        counter += 1;
      });
  }, 30000);

  return timer;
};

export const warrantyCarSection = (vehicle?: CarInfo | VehicleResponse) => {
  const { currentQuote, loan: contractedLoan } = getRedux();
  if (!vehicle && !currentQuote && !contractedLoan) return [];

  const vehicleRedux = vehicle || currentQuote.warrantyCar || contractedLoan?.vehicle || contractedLoan?.quote?.vehicle;
  const brand = typeof vehicleRedux?.brand === 'object' ? vehicleRedux?.brand.name : vehicleRedux?.brand;
  const model = typeof vehicleRedux?.model === 'object' ? vehicleRedux?.model.name : vehicleRedux?.model;

  return [
    { label: 'Placa', value: vehicleRedux?.licensePlate },
    { label: 'Marca', value: brand },
    { label: 'Modelo', value: model, valueAlign: 'end' },
    { label: 'Ano', value: vehicleRedux?.modelYear },
  ];
};

export const renavamSection = () => {
  const { currentQuote, loan: contractedLoan } = getRedux();
  if (!currentQuote && !contractedLoan) return [];

  const renavamInRedux =
    currentQuote.warrantyCar?.renavam || contractedLoan?.vehicle?.renavan || contractedLoan?.quote?.vehicle?.renavan;
  if (!renavamInRedux) return [];

  return [{ label: 'Número', value: renavamInRedux }];
};

const getProfessionalTypeById = (typeId?: string) =>
  getDomainProfessionalTypes()
    .then(res => res.find(({ id }) => id === typeId)?.name)
    .catch(() => {
      if (isDev) {
        resolveFakeProfessionTypeData()
          .then(res => {
            return res.map(
              ({ partnerCode, description }): Domain => ({
                id: partnerCode,
                name: capitalizeEveryFirstLetter(description, 3),
              }),
            );
          })
          .then(res => res.find(({ id }) => id === typeId)?.name);
      }
    });

const getProfessionById = (professionId?: string) =>
  getDomainProfessions()
    .then(res => res.find(({ id }) => id === professionId)?.name)
    .catch(() => {
      if (isDev) {
        resolveFakeOccupationData()
          .then(res => {
            return res.map(
              ({ partnerCode, description }): Domain => ({
                id: partnerCode,
                name: capitalizeEveryFirstLetter(description, 3),
              }),
            );
          })
          .then(res => res.find(({ id }) => id === professionId)?.name);
      }
    });

const getCityById = (stateId: string, cityId: string) =>
  getDomainCitiesByFederativeUnit(stateId)
    .then(res => res.find(({ id, name }) => id === cityId || name?.toUpperCase() === cityId)?.name || '')
    .catch(() => {
      if (isDev) {
        resolveFakeFederativeData()
          .then(response => {
            return response
              .filter(({ ativo }) => ativo)
              .map(({ codigo, nome }) => ({ id: String(codigo), name: capitalizeEveryFirstLetter(nome, 2) }));
          })
          .then(res => res.find(({ id, name }) => id === cityId || name?.toUpperCase() === cityId)?.name || '');
      }
    });

export const registrationSection = (setValue: any): void => {
  const { currentQuote } = getRedux();
  if (!currentQuote.personalInfo) return;

  const { gender, maritalStatus, monthlySalary, nacionality, occupation, placeOfBirthCity, placeOfBirthState, workingStatus } =
    currentQuote.personalInfo;

  Promise.all([
    getProfessionById(occupation?.id),
    getProfessionalTypeById(workingStatus?.id),
    getCityById(placeOfBirthState?.id || '', placeOfBirthCity?.id || placeOfBirthCity?.name || ''),
  ]).then(res => {
    const [professional, professionalSituation, city] = res;
    const registrations = [
      { label: 'Estado civil', value: capitalizeEveryFirstLetter(maritalStatus?.name) },
      { label: 'Renda', value: formatNumber.currency(monthlySalary) },
      { label: 'Profissão', value: professional },
      { label: 'Situação Profissional', value: professionalSituation },
      { label: 'Gênero', value: gender?.name },
      { label: 'Nacionalidade', value: capitalizeEveryFirstLetter(nacionality?.id || nacionality?.name) },
      { label: 'Estado', value: States.find(({ id }) => id === placeOfBirthState?.id)?.name },
      { label: 'Cidade', value: city },
    ];

    if (!currentQuote.documents || (!currentQuote.documents.cnh && currentQuote.documents.rg)) {
      setValue(registrations);
      return;
    }

    const { cnh, rg } = currentQuote.documents;
    const currentDocument = cnh || rg;
    setValue([
      ...registrations,
      { label: currentDocument?.type || 'RG', value: currentDocument?.number },
      { label: 'Órgão emissor', value: currentDocument?.issuerAuthority },
      { label: 'UF do documento', value: currentDocument?.issuerState },
    ]);
  });
};

type GetLoansDetailProps = {
  setFirstDoubleValue: any;
  setSecondtDoubleValue: any;
  setFirstSimpleValue: any;
  setSecondSimpleValue: any;
};
export const getLoansDetails = (props: GetLoansDetailProps) => {
  const { setFirstDoubleValue, setSecondtDoubleValue, setFirstSimpleValue, setSecondSimpleValue } = props;
  const {
    valueTotalInstallmentWithoutInsurance,
    iof,
    tc,
    totalLoan,
    valueInsurance,
    installmentWithoutInsurance,
    valueTotalInstallments,
  } = getResultQuote();

  const firstDouble = [
    {
      labelLeft: 'Valor total do crédito',
      valueLeft: `${formatNumber.currency(totalLoan)}`,
      labelRight: '<span>Indice (%)</span>',
      valueRight: `${percentOfValue(totalLoan, totalLoan)}`,
    },
    {
      labelLeft: 'Valor líquido de crédito',
      valueLeft: `${formatNumber.currency(valueTotalInstallmentWithoutInsurance)}`,
      labelRight: `${percentOfValue(totalLoan, valueTotalInstallmentWithoutInsurance)}`,
    },
  ];

  const secondDouble = [
    {
      labelLeft: 'Tarifa de Cadastro',
      valueLeft: `${formatNumber.currency(tc)}`,
      valueRight: `${percentOfValue(totalLoan, tc)}`,
    },
    {
      labelLeft: 'IOF total',
      icon: infoIcon,
      iconName: 'infoIcon',
      valueLeft: formatNumber.currency(iof),
      valueRight: percentOfValue(totalLoan, iof),
    },
    {
      labelLeft: 'Seguros',
      valueLeft: formatNumber.currency(valueInsurance),
      valueRight: percentOfValue(totalLoan, valueInsurance),
    },
    { labelLeft: 'Capitalização', valueLeft: 'R$ 0,00', valueRight: '0,00%' },
    { labelLeft: 'Assistência', valueLeft: 'R$ 0,00', valueRight: '0,00%' },
  ];

  const firstSimple = [
    { label: 'Tipo de operação', value: 'Crédito com garantia' },
    { label: 'Tipo de contrato', value: 'Novo' },
    { label: 'Forma de emissão', value: 'Eletrônico' },
  ];
  const secondSimple = [
    {
      label: 'Valor da parcela',
      value: formatNumber.currency(installmentWithoutInsurance),
    },
    {
      label: 'Valor total com imposto',
      value: formatNumber.currency(valueTotalInstallments + iof),
    },
    {
      label: 'Quantidade de parcelas',
      value: numberOfInstallments(),
    },
    {
      label: 'Vencimento da primeira',
      value: '14/06/2022', // TODO - Encontrar a informação no banco
    },
    {
      label: 'Vencimento da última',
      value: '14/06/2024', // TODO - Encontrar a informação no banco
    },
  ];

  setFirstDoubleValue(firstDouble);
  setSecondtDoubleValue(secondDouble);
  setFirstSimpleValue(firstSimple);
  setSecondSimpleValue(secondSimple);
};
