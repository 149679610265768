import { AllHTMLAttributes, memo, useCallback, useEffect, useState } from 'react';
import { FlowSimulationReponse } from '../../api/generated/personal-loans';

import { DotChecked, DotWarning, Dot, StepperWrapper, StepperItem, StepName } from './styles';
import { FlowSteps } from '../../data';

const stepName = {
  0: 'Simulação',
  1: 'Documentação',
  2: 'Vistoria',
  3: 'Contrato',
};

export type ProgressBarWithMilestonesProps = AllHTMLAttributes<HTMLDivElement> & {
  flowSimulation?: FlowSimulationReponse;
};

type RenderDotProps = {
  activated: boolean;
  className: string;
  index: number;
  isContract: boolean;
  name: string;
};

type HowToRenderDotProps = {
  check: boolean;
  id: string;
  index: number;
};

const ProgressBarWithMilestones = ({ flowSimulation }: ProgressBarWithMilestonesProps): JSX.Element => {
  const [progress, setProgress] = useState(0);

  const isActive = useCallback((id: string) => (flowSimulation?.active ? id === flowSimulation?.step : false), [flowSimulation]);

  const renderDotChecked = useCallback(
    (props: RenderDotProps) => {
      const { activated, className, index, name } = props;
      if (activated && ['DISAPPROVED', 'WAITING'].includes(flowSimulation?.status || '')) {
        return (
          <StepperItem key={String(index)} className={className} active>
            <DotWarning />
            <StepName activated={activated} warning={activated}>
              {name}
            </StepName>
          </StepperItem>
        );
      }

      return (
        <StepperItem key={String(index)} className={className} checked>
          <DotChecked />
          <StepName checked>{name}</StepName>
        </StepperItem>
      );
    },
    [flowSimulation],
  );

  const renderDotWithNumber = useCallback((props: RenderDotProps) => {
    const { activated, className, index, isContract, name } = props;
    return (
      <StepperItem key={String(index)} className={className} active={activated}>
        <Dot activated={activated} isContract={isContract}>
          {isContract && activated ? '!' : index + 1}
        </Dot>
        <StepName activated={activated} warning={isContract}>
          {name}
        </StepName>
      </StepperItem>
    );
  }, []);

  const howToRenderDot = useCallback(
    (props: HowToRenderDotProps) => {
      const { check, id, index } = props;
      const name: string = stepName[index];
      const activated = isActive(id);
      const isContract = index === 3;
      const lastClassName = isContract ? 'last' : '';
      const className = index === 0 ? 'first' : lastClassName;

      const propsRenderDot = { activated, className, index, isContract, name };

      if (check || (activated && flowSimulation?.status === 'WAITING')) return renderDotChecked(propsRenderDot);

      return renderDotWithNumber(propsRenderDot);
    },
    [flowSimulation],
  );

  useEffect(() => {
    switch (flowSimulation?.step) {
      case FlowSteps.SIMULATION:
        setProgress(0);
        break;
      case FlowSteps.DOCUMENTATION:
        setProgress(40);
        break;
      case FlowSteps.VEHICLE_INSPECTION:
        setProgress(70);
        break;
      case FlowSteps.CONTRACT:
        setProgress(100);
        break;
      default:
        setProgress(0);
    }
  }, []);

  return (
    <StepperWrapper>
      {howToRenderDot({ id: FlowSteps.SIMULATION, check: progress >= 40, index: 0 })}
      {howToRenderDot({ id: FlowSteps.DOCUMENTATION, check: progress >= 70, index: 1 })}
      {howToRenderDot({ id: FlowSteps.VEHICLE_INSPECTION, check: progress >= 100, index: 2 })}
      {howToRenderDot({ id: FlowSteps.CONTRACT, check: false, index: 3 })}
    </StepperWrapper>
  );
};

export default memo(ProgressBarWithMilestones);
