import { formatNumber } from './formatNumber';
import { BenefitTypeEnum } from '../models';
import { BenefitResponse } from '../api/generated/personal-loans';
import { getBenefit } from '../api';

type PercentValueProps = {
  cashback?: number | string;
  installmentWithoutInsurance?: number;
};

type TitleProps = PercentValueProps & {
  benefit: 'CASHBACK' | 'KMV';
  kmv: string;
};

type GetBenefitDetailProps = {
  benefit: BenefitTypeEnum;
  history: any;
  setFn: (value?: BenefitResponse) => void;
  setLoading: (value: boolean) => void;
  secondBenefit?: BenefitTypeEnum;
  setSecondFn?: (value?: BenefitResponse) => void;
};

export const getPercentValue = (props: PercentValueProps) => {
  const { cashback, installmentWithoutInsurance } = props;

  if (!cashback || !installmentWithoutInsurance) return '1%';
  return formatNumber.percent((+cashback * 100) / installmentWithoutInsurance / 100, 2);
};

export const getTitleSuccess = (props: TitleProps) => {
  const { benefit, kmv, cashback, installmentWithoutInsurance } = props;
  const percentValue = getPercentValue({ cashback, installmentWithoutInsurance });

  return benefit === 'KMV'
    ? `Você vai ganhar ${kmv} Km de Vantagem`
    : `Você vai ganhar ${percentValue} de cashback em cada parcela`;
};

export const getSubtitleSuccess = (benefit: string) => {
  return `Você receberá o ${benefit === BenefitTypeEnum.KMV ? 'Km' : 'cashback'} nas parcelas pagas.`; // TODO revisar texto
};

export const titleModalSuccess = 'Como funcionam os benefícios abastece-aí';
export const descriptionModalSuccess =
  'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC is a is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum ipsum dolor sit amet.., comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H Rackham.';

export const getCashbackDetailFallback = (props: PercentValueProps) => {
  const percentValue = getPercentValue(props);
  return `Você recebe ${percentValue} de cashback nas parcelas pagas até a 10ª parcela. Depois da 11ª parcela não haverá mais o benefício de cashback.`;
};

export const getKmvDetailFallback = () =>
  'Você recebe 200 de KMV nas parcelas pagas até a 10ª parcela. Depois da 11ª parcela não haverá mais o benefício de km.';

export const getBenefitDetail = (props: GetBenefitDetailProps) => {
  const { benefit, history, setFn, setLoading, secondBenefit, setSecondFn } = props;
  setLoading(true);
  getBenefit()
    .then(response => {
      setFn(response.find(item => item.benefitType === benefit));
      if (secondBenefit && setSecondFn) {
        setSecondFn(response.find(item => item.benefitType === secondBenefit));
      }
    })
    .catch(() => history.push('/document-status'))
    .finally(() => setLoading(false));
};
