import { lazy } from 'react';

/* Tela Inicial */
export const InsuranceEntryPoint = lazy(() => import('../pages/Insurance/EntryPoint'));
const Renovation = lazy(() => import('../pages/Insurance/New/insuranceWelcome'));

// Renovação
const RenewHome = lazy(() => import('../pages/Insurance/Renew/RenewHome'));
const CiCode = lazy(() => import('../pages/Insurance/Renew/OldInsurance'));

// Cotação
const VehicleUseType = lazy(() => import('../pages/Insurance/New/VehicleUseType'));
const CarPlate = lazy(() => import('../pages/Insurance/New/CarPlate'));
const CarPlateDetails = lazy(() => import('../pages/Insurance/New/CarPlateDetails'));
const CarDetails = lazy(() => import('../pages/Insurance/New/CarDetails'));
const CarCheckUp = lazy(() => import('../pages/Insurance/New/CarCheckUp'));
const CallInsurance = lazy(() => import('../pages/Insurance/New/CallInsurance'));
const WhoDrives = lazy(() => import('../pages/Insurance/New/WhoDrives'));
const CarDriver = lazy(() => import('../pages/Insurance/New/CarDriver'));
const GenderMaritalStatus = lazy(() => import('../pages/Insurance/New/GenderMaritalStatus'));
const UnderAge = lazy(() => import('../pages/Insurance/New/UnderAge'));
const CarAddress = lazy(() => import('../pages/Insurance/New/CarAddress'));
const NewCarAddress = lazy(() => import('../pages/Insurance/New/NewCarAddress'));
const OfferCarousel = lazy(() => import('../pages/Insurance/New/ChooseOffer'));
const AllPlans = lazy(() => import('../pages/Insurance/New/AllPlans'));
const InsuranceCoverage = lazy(() => import('../pages/Insurance/New/InsuranceCoverage'));

// Contratação
const RgNumber = lazy(() => import('../pages/Insurance/New/RgNumber'));
const SalaryOccupation = lazy(() => import('../pages/Insurance/New/SalaryOccupation'));
const ReviewFinish = lazy(() => import('../pages/Insurance/New/ReviewFinish'));
const MainConduct = lazy(() => import('../pages/Insurance/New/mainConduct'));

// const FuelType = lazy(() => import('../pages/Insurance/New/FuelType'));

export const INSURANCE = [
  { path: '/renovation', component: Renovation },

  { path: '/renew-home', component: RenewHome },
  { path: '/ci-code', component: CiCode },

  { path: '/vehicle-use-type', component: VehicleUseType },
  { path: '/car-plate', component: CarPlate },
  { path: '/carplatedetails', component: CarPlateDetails },
  { path: '/car-details', component: CarDetails },
  { path: '/car-checkup', component: CarCheckUp },
  { path: '/call-insurance', component: CallInsurance },
  { path: '/who-drives', component: WhoDrives },
  { path: '/car-driver', component: CarDriver },
  { path: '/gender-marital', component: GenderMaritalStatus },
  { path: '/under-age', component: UnderAge },
  { path: '/car-address', component: CarAddress },
  { path: '/new-car-address', component: NewCarAddress },
  { path: '/offer-carousel', component: OfferCarousel },
  { path: '/all-plans', component: AllPlans },
  { path: '/insurance-coverage', component: InsuranceCoverage },

  { path: '/rg-number', component: RgNumber },
  { path: '/occupation', component: SalaryOccupation },
  { path: '/review-finish', component: ReviewFinish },

  { path: '/main-conduct', component: MainConduct },

  // { path: '/fuel-type', component: FuelType },
];
