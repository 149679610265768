export const SrcInfo =
  'O Sistema de Informações de Créditos (SCR) é um sistema constituído e administrado pelo Banco Central do Brasil (Bacen) que recebe as informações dos bancos e instituições financeiras sobre as operações de crédito contratadas pelos seus clientes.   \n' +
  '\n' +
  'Esse sistema permite ao Bacen monitorar o crédito no âmbito do sistema financeiro, bem como a fiscalizar os bancos e demais participantes.   Adicionalmente, o SCR permite que os bancos troquem informações sobre o endividamento e montante de responsabilidade dos clientes em operações de crédito, auxiliando assim no processo de aprovação de novos empréstimos para estes clientes.\n' +
  '\n' +
  'Você poderá consultar os seus dados no SCR junto ao BACEN (sistema Registrato) ou por meio das instituições financeiras com que você mantenha relacionamento, podendo inclusive solicitar a correção ou exclusão de dados incorreto ou desatualizados constantes do SCR.   Para maiores informações consulte o site ou aplicativo do seu banco.\n' +
  '\n' +
  'Esta autorização poderá ser utilizada pelos seguintes bancos parceiros: [Banco Votorantim S.A] ';

export const privacyPolicy =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec varius est tincidunt elit porta fermentum. Curabitur pharetra elit magna, ac sollicitudin ante ullamcorper a. Nunc sodales id ante et lobortis. Phasellus mollis nec purus a volutpat. \n' +
  '\n' +
  'Sed in purus scelerisque, facilisis enim vitae, egestas est. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum rutrum, massa mollis iaculis eleifend, magna dolor accumsan ex, et faucibus enim dolor eu urna. Sed feugiat pharetra nunc in viverra. \n' +
  '\n' +
  'Donec pellentesque, tortor a dignissim aliquam, nibh quam maximus elit, at euismod nisi mauris sit amet odio. Curabitur sed augue vitae ligula vulputate auctor eu et arcu. Praesent ullamcorper, felis in interdum tempor, nibh tellus bibendum risus, quis volutpat libero neque nec arcu. Quisque vitae purus ullamcorper, porttitor elit id, rutrum leo. Ut volutpat rutrum orci. \n' +
  '\n' +
  'Suspendisse euismod diam sit amet purus laoreet, sed tempor enim fermentum. Curabitur vitae ex mauris. In vulputate diam et neque lacinia, sed semper tortor finibus. ';

export const termsOfUse = `Coleta e Tratamento de Dados: Estou ciente que o BV coletará e tratará os meus dados pessoais de acordo com o disposto na Política de Privacidade (disponível em <a href="http://www.bv.com.br/politica-de-privacidade" target="_blank" rel="noopener">www.bv.com.br/politica-de-privacidade</a>) e, quando aplicável, nos Termos de Uso (disponível em <a href="http://www.bv.com.br/termo-de-uso" target="_blank" rel="noopener">www.bv.com.br/termo-de-uso</a>), e sempre em conformidade com todas as leis e regulamentos aplicáveis ao tratamento de dados pessoais realizado no Brasil, incluindo, mas não se limitando à Lei 13.709, de 14 de agosto de 2018 e suas alterações posteriores. Adicionalmente, estou ciente que os meus direitos como titular de dados pessoais poderão ser exercidos por meio Portal da Transparência (<a href="https://portaldetransparencia.bv.com.br/" target="_blank" rel="noopener">https://portaldetransparencia.bv.com.br/</a>) ou outro ambiente publicamente divulgado pelo BV que venha a substituí-lo.`;

export const contractClauses = `1. Forma de Emissão: Reconheço como válida, eficaz e vinculante esta Cédula de Crédito Bancário (“CCB”), nos termos do art. 29 da Lei 10.931/04 emitida por mim na forma indicada no item Forma de emissão acima, sendo que poderá ser formalizada por (i) suporte eletrônico , assinada com a coleta de meus dados biométricos e/ou meu aceite eletrônico em ambiente autenticado, a depender do sistema ou plataforma de contratação, nos termos do § 2º do art. 10 da Medida Provisória nº 2.200-2/01; ou (ii) suporte físico, assinada com a minha assinatura caligráfica.
2. Valor do Crédito. Emito essa CCB, representativa do crédito bancário concedido pelo BV, no Valor Total do Crédito (item B5) e reconheço que o presente instrumento constitui título executivo extrajudicial, nos termos do artigo 28 da Lei 10.931/04. 2.1. O Valor Líquido do Crédito (item B6) será creditado na minha conta ao início do relacionamento e contratação dessa operação de crédito, conforme acordado nessa CCB (item E7). Em substituição ao pagamento dessa tarifa, declaro que me ofereceram a possibilidade de apresentar os seguintes documentos para compor meu cadastro no BV: Cédula de Identidade, CPF, comprovante de endereço, pesquisa em bancos de dados restritivos de crédito, certidões de cartórios de protesto e comprovante de renda; e (iii) todas as informações constantes do preâmbulo e as informações cadastrais fornecidas e utilizadas para fins de emissão dessa CCB são corretas, completas e verdadeiras e que minha renda, faturamento (se for o caso) e patrimônio declarados são/foram obtidos de forma lícita, estando ciente do art. 11, II da Lei 9.613/98, conforme alterada pela Lei 12.683/12, e que a falsidade ou incompletude das informações ensejará a aplicação das penalidades legais, especialmente criminais, conforme art. 19 da Lei 7.492/86 e dos artigos 297, 298 e 299 do Código Penal, civis e administrativas, nos termos do art. 2º da Lei 7.115/83. 4.1. Seguro de Proteção Financeira. Sei que a contratação de Seguro de Proteção`;

export const closeModal =
  'Poxa você já chegou até aqui, não desiste da contratação não. Acelere seus sonhos pegando um crédito aqui com agente';
