/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProposalLoanResponse } from '../models/ProposalLoanResponse';
import { request as __request } from '../core/request';

export class ProposalLoanReadControllerService {

    /**
     * proposalLoan
     * @param product product
     * @param simulationQuoteId simulationQuoteId
     * @param xCustomerUuid x-customer-uuid
     * @param acceptLanguage Accept-Language
     * @returns ProposalLoanResponse OK
     * @throws ApiError
     */
    public static async proposalLoanUsingGet(
        product: string,
        simulationQuoteId: string,
        xCustomerUuid: string,
        acceptLanguage?: string,
    ): Promise<ProposalLoanResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/personal_loans/proposal_loan/product/${product}/simulation_quote/${simulationQuoteId}`,
            headers: {
                'x-customer-uuid': xCustomerUuid,
                'Accept-Language': acceptLanguage,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

}