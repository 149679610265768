import dayjs from 'dayjs';

export const getInfoFromCodebar = (codebar?: string): { value?: number; dueDate?: string } => {
  if (!codebar) return {};
  const dueDateBaseline = dayjs('10-07-1997', 'MM-DD-YYYY');
  const codeBarNumbers = codebar.replace(/\D/g, '');
  const daysAfterBaseline = Number(codeBarNumbers.substring(33, 37));
  const dueDate = dueDateBaseline.add(daysAfterBaseline, 'day');
  const value = Number(codeBarNumbers.slice(-10)) / 100;
  return { value, dueDate: dueDate.format('DD/MM/YYYY') };
};
