import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormSubtitle, FormTitle } from '..';

import { returnToNative } from '../../utils/nativeBridge';

import Modal from '../Modal';
import { CloseIcon, ContentIcon } from './styles';

interface Props {
  onClick?: () => void;
  secondaryClick?: () => void;
  onClose?: () => void;
  overideClose?: () => void;
}

const CloseModal = ({ onClick, secondaryClick, onClose, overideClose }: Props): JSX.Element => {
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);

  const openModal = () => setIsOpened(prev => !prev);

  const handleClick = e => {
    if (onClose) onClose();
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();
      if (overideClose) return overideClose();

      return openModal();
    }, 300);
  };

  const returnTo = () => {
    if (secondaryClick) secondaryClick();
    setIsOpened(false);
    returnToNative();

    history.push('/');
  };

  return (
    <>
      <ContentIcon onClick={handleClick} aria-label="Close button icon">
        <div>
          <CloseIcon />
        </div>
      </ContentIcon>

      <Modal
        open={isOpened}
        onClose={setIsOpened}
        twoButtons
        confirmLabel="Não quero desistir"
        closeLabel="Sim tenho certeza"
        onClick={() => {
          if (onClick) onClick();
        }}
        secondaryClick={returnTo}
      >
        <div style={{ padding: '0 32px' }}>
          <FormTitle style={{ marginBottom: 20 }}>tem certeza que quer desistir agora?</FormTitle>
          <FormSubtitle>
            Poxa você já chegou até aqui, não desiste da contratação não. Acelere seus sonhos pegando um crédito aqui com agente
          </FormSubtitle>
        </div>
      </Modal>
    </>
  );
};

export default memo(CloseModal);
