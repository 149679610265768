import { Domain } from '../../models';

const male = { id: 'M', name: 'Homem' };
const female = { id: 'F', name: 'Mulher' };
export const Genders: Domain[] = [male, female];

export const getGenderById = (id = ''): Domain => {
  if (['MALE', 'M'].includes(id.toUpperCase())) {
    return male;
  }
  if (['FEMALE', 'F'].includes(id.toUpperCase())) {
    return female;
  }
  return {} as Domain;
};
