import styled, { keyframes } from 'styled-components/macro';
import { FaCheck } from 'react-icons/fa';
import { CgSearch } from 'react-icons/cg';

import { Answer } from '../InputCover/styles';

import { pxToRem } from '../../utils';

const animate = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: ${pxToRem(500)};
    height: ${pxToRem(500)};
    opacity: 0;
  }
`;

export const ItemContainer = styled.div<{ selected?: boolean }>`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${pxToRem(24)} ${pxToRem(32)};

  border-style: none none solid none;
  border-color: ${({ theme }) => theme.colors.blueStrong}1A;
  border-width: ${pxToRem(1)};

  background-color: ${({ selected }) => selected && '#0069E71A'};

  overflow: hidden;
  position: relative;

  span {
    position: absolute;
    background-color: #000;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ${animate} 1s linear infinite;
  }
`;

export const Item = styled.p`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(22)};
  color: ${({ theme }) => theme.colors.blueTypo};
  margin: 0;
  pointer-events: none;
`;

export const CheckInput = styled(FaCheck)`
  color: ${({ theme }) => theme.colors.blueStrong};
  width: ${pxToRem(18)};
  height: ${pxToRem(18)};
`;

export const Title = styled(Answer)`
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(32)};
`;

export const TitleWrapper = styled.div`
  width: 100%;
  padding: 0 ${pxToRem(32)};
`;

export const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 10;
  background: white;
  padding: ${pxToRem(16)} 0;
`;

export const ContainerSearch = styled.div`
  width: ${pxToRem(300)};
  height: ${pxToRem(52)};

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${pxToRem(32)};
  margin-bottom: ${pxToRem(24)};
  padding: 0 ${pxToRem(32)};

  border: ${({ theme }) => `1px solid ${theme.colors.blueGrayLight}`};
  border-radius: ${pxToRem(16)};

  &:focus-within {
    border: ${({ theme }) => `1px solid ${theme.colors.blueStrong}`};
  }
`;

export const InputSearch = styled.input`
  width: 100%;
  border: none;
  outline: none;

  font-family: Nunito;
  font-size: ${pxToRem(16)};
  font-style: normal;
  font-weight: 400;
  line-height: ${pxToRem(16)};

  color: ${({ theme }) => theme.colors.blueTypo};

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGrayLight};
  }
`;

export const SearchIcon = styled(CgSearch)`
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
  color: ${({ theme }) => theme.colors.blueGrayLight};
`;

export const Content = styled.div`
  padding: 0 ${pxToRem(32)};
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const ItemsWrapper = styled.div<{ alignToTop: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${p => (p.alignToTop ? 'start' : `flex-end`)};
  height: 100%;
  overflow-y: scroll;
`;
