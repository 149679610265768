import styled, { keyframes } from 'styled-components/macro';

import { pxToRem } from '../../utils';

const animate = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: ${pxToRem(500)};
    height: ${pxToRem(500)};
    opacity: 0;
  }
`;

export const Wrapper = styled.div<{
  paddingY?: number;
  paddingX?: number;
  noMargin?: boolean;
  fullWidth?: boolean;
}>`
  ${p => (p.noMargin ? '' : `margin-top: ${pxToRem(32)}`)};
  ${p => (p.fullWidth ? `width: 100%` : '')};
  padding: ${p => (p.paddingY ? pxToRem(p.paddingY) : '0')} ${p => (p.paddingX ? pxToRem(p.paddingX) : '0')};

  &.update {
    margin: ${pxToRem(24)} auto 0;
    display: flex;
    justify-content: center;
  }
`;

export const StyledButton = styled.button<{
  square?: boolean;
}>`
  width: 100%;
  height: ${pxToRem(56)};

  border-radius: ${pxToRem(16)};
  border: none;
  padding: ${pxToRem(6)} ${pxToRem(12)};
  cursor: pointer;

  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
  font-size: ${pxToRem(14)};
  line-height: 140%;
  text-align: center;

  overflow: hidden;
  position: relative;

  ${p => (p.square ? `border-radius: 0;margin-top: 0 !important;` : '')};

  span {
    position: absolute;
    background-color: #000;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ${animate} 1s linear infinite;
  }

  &:hover {
    opacity: 0.63;
  }

  &.update {
    width: fit-content;
    padding: ${pxToRem(10)} ${pxToRem(16)};
    border: 2px solid ${({ theme }) => theme.colors.blueStrong};
    border-radius: ${pxToRem(32)};
  }
`;
