import { lazy } from 'react';

/* CpCvg */
export const EntryPointPersonalLoans = lazy(() => import('../pages/CpCvg/EntryPointPersonalLoans'));
const Review = lazy(() => import('../pages/CpCvg/Review'));
const Renavam = lazy(() => import('../pages/CpCvg/Renavam'));
const TypeLoan = lazy(() => import('../pages/CpCvg/LoanType'));
const Documents = lazy(() => import('../pages/CpCvg/Documents/RgNumber'));
const StartPage = lazy(() => import('../pages/CpCvg/Start'));
const Patrimony = lazy(() => import('../pages/CpCvg/Documents/Patrimony'));
const Occupation = lazy(() => import('../pages/CpCvg/Documents/SalaryOccupation'));
const DetailSign = lazy(() => import('../pages/CpCvg/DetailSign'));
const WarrantyCar = lazy(() => import('../pages/CpCvg/CarDetails'));
const PaymentType = lazy(() => import('../pages/CpCvg/PaymentType'));
const Naturalness = lazy(() => import('../pages/CpCvg/Documents/Naturalness'));
const SendDocuments = lazy(() => import('../pages/CpCvg/Documents/SendDocuments'));
const DocumentStatus = lazy(() => import('../pages/CpCvg/Documents/DocumentStatus'));
const DocumentPending = lazy(() => import('../pages/CpCvg/Documents/DocumentPending'));
const DocumentSuccess = lazy(() => import('../pages/CpCvg/Documents/DocumentSuccess'));
const DocumentFailure = lazy(() => import('../pages/CpCvg/Documents/DocumentFailure'));
const SendDocumentation = lazy(() => import('../pages/CpCvg/SendDocumentation'));
const ValueSimulatorCVG = lazy(() => import('../pages/CpCvg/ValueSimulator'));
const AuthenticationSMS = lazy(() => import('../pages/CpCvg/AuthenticationSMS'));
const WaitingForApproval = lazy(() => import('../pages/CpCvg/WaitingForApproval'));
const AuthorizeToContinue = lazy(() => import('../pages/CpCvg/AuthorizeToContinue'));
const BankInformation = lazy(() => import('../pages/CpCvg/BankInformation'));
const DetailProposal = lazy(() => import('../pages/CpCvg/DetailProposal'));

/* Cvg AfterSales */
const Prepay = lazy(() => import('../pages/CpCvg/AfterSales/Prepay'));
const StatusPaying = lazy(() => import('../pages/CpCvg/AfterSales/StatusPaying'));
const PrepayOption = lazy(() => import('../pages/CpCvg/AfterSales/PrepayOption'));
const ChooseBenefits = lazy(() => import('../pages/CpCvg/ChooseBenefits'));
const HomeAfterSales = lazy(() => import('../pages/CpCvg/AfterSales/HomeAfterSales'));
const PaymentHistory = lazy(() => import('../pages/CpCvg/AfterSales/PaymentHistory'));
const ConsultContract = lazy(() => import('../pages/CpCvg/AfterSales/ConsultContract'));
const ProposalSent = lazy(() => import('../pages/CpCvg/AfterSales/ProposalSent'));
const ChooseInstallment = lazy(() => import('../pages/CpCvg/AfterSales/ChooseInstallment'));
const BenefitSuccess = lazy(() => import('../pages/CpCvg/BenefitSuccess'));
const BenefitDetails = lazy(() => import('../pages/CpCvg/BenefitDetails'));
const NotReceiveSMS = lazy(() => import('../pages/CpCvg/Documents/DocumentStatus/NotReceiveSMS'));

export const CP_CVG = [
  { path: '/cp', component: EntryPointPersonalLoans },
  { path: '/cvg', component: EntryPointPersonalLoans },
  { path: '/start', component: StartPage },
  { path: '/loan-type', component: TypeLoan },
  { path: '/authorize', component: AuthorizeToContinue },
  { path: '/warranty-car', component: WarrantyCar },
  { path: '/value-simulator', component: ValueSimulatorCVG },
  { path: '/review', component: Review },
  { path: '/waiting-for-approval', component: WaitingForApproval },
  { path: '/payment-type', component: PaymentType },
  { path: '/documents', component: Documents },
  { path: '/patrimony', component: Patrimony },
  { path: '/bank-information', component: BankInformation },
  { path: '/professional', component: Occupation },
  { path: '/send-documents', component: SendDocuments },
  { path: '/document-status', component: DocumentStatus },
  { path: '/send-document', component: SendDocumentation },
  { path: '/authentication-sms', component: AuthenticationSMS },
  { path: '/detail-sign', component: DetailSign },
  { path: '/document-pending', component: DocumentPending },
  { path: '/document-success', component: DocumentSuccess },
  { path: '/document-failure', component: DocumentFailure },
  { path: '/naturalness', component: Naturalness },
  { path: '/renavam', component: Renavam },
  { path: '/cvg/afterSales', component: HomeAfterSales },
  { path: '/cvg/consultContract', component: ConsultContract },
  { path: '/cvg/proposalSent', component: ProposalSent },
  { path: '/cvg/paymentHistory', component: PaymentHistory },
  { path: '/cvg/statusPaying', component: StatusPaying },
  { path: '/cvg/prepayOptions', component: PrepayOption },
  { path: '/cvg/prepay', component: Prepay },
  { path: '/cvg/chooseInstallments', component: ChooseInstallment },
  { path: '/cvg/chooseBenefit', component: ChooseBenefits },
  { path: '/cvg/benefitSuccess', component: BenefitSuccess },
  { path: '/cvg/benefitDetails', component: BenefitDetails },
  { path: '/notReceiveSMS', component: NotReceiveSMS },
  { path: '/detail-proposal', component: DetailProposal },
];
