import { Domain } from '../../models/domain';

export const ExpOrgs: Domain[] = [
  { id: 'SSP', name: 'Secretaria de Segurança Pública (SSP)' },
  { id: 'Detran', name: 'Departamento de Trânsito (Detran)' },
  { id: 'POM', name: 'Polícia Militar (POM)' },
  { id: 'SPTC', name: 'Secretaria de Polícia Técnico-Científica (SPTC)' },
  { id: 'FGTS', name: 'Fundo de Garantia do Tempo de Serviço (FGTS)' },
  { id: 'OAB', name: 'Ordem dos Advogados do Brasil (OAB)' },
  { id: 'MTE', name: 'Ministério do Trabalho e Emprego (MTE)' },
  { id: 'Cartorio', name: 'Cartório Civil' },
  { id: 'DPF', name: 'Departamento de Polícia Federal (DPF)' },
];
