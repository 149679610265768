/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerLoanRequest } from '../models/CustomerLoanRequest';
import type { CustomerLoanResponse } from '../models/CustomerLoanResponse';
import { request as __request } from '../core/request';

export class CustomerLoanControllerService {
  /**
   * save
   * @param product product
   * @param request request
   * @param simulationQuoteId simulationQuoteId
   * @param xCustomerUuid x-customer-uuid
   * @param acceptLanguage Accept-Language
   * @returns CustomerLoanResponse OK
   * @returns any Created
   * @throws ApiError
   */
  public static async saveUsingPost3(
    product: 'CP' | 'CVG',
    request: CustomerLoanRequest,
    simulationQuoteId: string,
    xCustomerUuid: string,
    acceptLanguage?: string,
  ): Promise<CustomerLoanResponse | any> {
    const result = await __request({
      method: 'POST',
      path: `/api/personal_loans/customer/${product}/simulation_quote/${simulationQuoteId}`,
      headers: {
        'x-customer-uuid': xCustomerUuid,
        'Accept-Language': acceptLanguage,
      },
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
    return result.body;
  }

  /**
   * update
   * @param quoteId quoteId
   * @param request request
   * @param xCustomerUuid x-customer-uuid
   * @param acceptLanguage Accept-Language
   * @returns CustomerLoanResponse OK
   * @returns any Created
   * @throws ApiError
   */
  public static async updateUsingPut4(
    quoteId: string,
    request: CustomerLoanRequest,
    xCustomerUuid: string,
    acceptLanguage?: string,
  ): Promise<CustomerLoanResponse | any> {
    const result = await __request({
      method: 'PUT',
      path: `/api/personal_loans/customer/${quoteId}`,
      headers: {
        'x-customer-uuid': xCustomerUuid,
        'Accept-Language': acceptLanguage,
      },
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
    return result.body;
  }

  /**
   * update
   * @param quoteId quoteId
   * @param request request
   * @param xCustomerUuid x-customer-uuid
   * @param acceptLanguage Accept-Language
   * @returns CustomerLoanResponse OK
   * @returns any Created
   * @throws ApiError
   */
  public static async updateUsingPatch(
    quoteId: string,
    request: CustomerLoanRequest,
    xCustomerUuid: string,
    acceptLanguage?: string,
  ): Promise<CustomerLoanResponse | any> {
    const result = await __request({
      method: 'PATCH',
      path: `/api/personal_loans/customer/${quoteId}`,
      headers: {
        'x-customer-uuid': xCustomerUuid,
        'Accept-Language': acceptLanguage,
      },
      body: request,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
    return result.body;
  }
}
