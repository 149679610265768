import { KeyboardEvent, useCallback, useState } from 'react';

import { Currency } from '../index';
import Modal from '../Modal';
import { ButtonInputCover, CleanInput, ContentInput, ContentModal, HelperText, InputFormatted } from '../InputCover/styles';
import { Container, Description, SliderWrapper, Title, ValueLabel, ValueWrapper } from './styles';

export interface CurrencyInputProps {
  title: string;
  price: number;
  maxPrice: number;
  action: (value: number) => void;
  onValueEdit?: () => void;
}

const CurrencyInput = ({ title, price, maxPrice, action, onValueEdit }: CurrencyInputProps): JSX.Element => {
  const [inputValue, setInputValue] = useState<number | string>(price > 0 ? price : maxPrice);
  const [showModal, setShowModal] = useState<boolean>(false);

  const cleanState = useCallback(() => setInputValue(''), []);

  const openModal = useCallback(() => setShowModal(true), []);
  const minWishValue = 1000;
  const isInputValid = !!inputValue && +inputValue <= maxPrice && +inputValue >= minWishValue;
  const handleClose = useCallback(() => {
    if (!inputValue) return;

    const newValue = !inputValue ? price : +inputValue;
    setShowModal(false);
    action(newValue);
  }, [inputValue, price, action]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (!isInputValid) return;

      if (event.key === 'Enter' || event.which === 13) {
        handleClose();
      }
    },
    [isInputValid],
  );

  return (
    <>
      <Container>
        <Title>{title}</Title>
        <ValueWrapper style={{ justifyContent: 'space-between' }}>
          <ValueLabel>
            <Currency value={price} />
          </ValueLabel>
          <ButtonInputCover aria-label="Editar valor desejado" onClick={openModal}>
            Editar
          </ButtonInputCover>
        </ValueWrapper>
        <SliderWrapper>
          <Description>
            Máximo de <Currency value={maxPrice} />
          </Description>
        </SliderWrapper>
      </Container>
      <Modal
        open={showModal}
        onClick={() => {
          if (onValueEdit) onValueEdit();
          handleClose();
        }}
        onClose={setShowModal}
        buttonDisabled={!isInputValid}
      >
        <ContentModal>
          <Title>Valor desejado</Title>
          <ContentInput error={!inputValue}>
            <InputFormatted
              displayType="input"
              value={inputValue}
              placeholder="R$ 0,00"
              onValueChange={e => setInputValue(e.floatValue || '')}
              autoFocus
              onKeyDown={handleKeyPress}
              prefix="R$ "
              thousandSeparator="."
              decimalScale={2}
              decimalSeparator=","
              inputMode="numeric"
              onBlur={evt => evt.target.focus()}
            />
            {!!inputValue && <CleanInput onClick={cleanState} />}
          </ContentInput>
          {isInputValid ? (
            <HelperText small>
              Máximo de <Currency value={maxPrice} />
            </HelperText>
          ) : (
            <HelperText small error>
              Valor deve estar entre <Currency value={minWishValue} /> e <Currency value={maxPrice} />
            </HelperText>
          )}
        </ContentModal>
      </Modal>
    </>
  );
};

export default CurrencyInput;
