const fakeNationalitiesData = [
  {
    partnerCode: '-1',
    description: 'BRASILEIRO',
  },
  {
    partnerCode: '-1',
    description: 'ESTRANGEIRO',
  },
  {
    partnerCode: '-1',
    description: 'NATURALIZADO',
  },
];

const fakeFederativeUnitData = [
  {
    codigo: 6839,
    nome: 'ABARRACAMENTO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6841,
    nome: 'AFONSO ARINOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6842,
    nome: 'AGULHAS NEGRAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6843,
    nome: 'AMPARO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6844,
    nome: 'ANDRADE PINTO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6845,
    nome: 'ANGRA DOS REIS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6846,
    nome: 'ANTA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6847,
    nome: 'APERIBE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6848,
    nome: 'ARARUAMA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6849,
    nome: 'AREAL',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6850,
    nome: 'ARMACAO DOS BUZIOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6851,
    nome: 'ARRAIAL DO CABO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6852,
    nome: 'ARROZAL',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6853,
    nome: 'AVELAR',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6854,
    nome: 'BACAXA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6855,
    nome: 'BALTAZAR',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6856,
    nome: 'BANQUETE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6857,
    nome: 'BARAO DE JUPARANA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6858,
    nome: 'BARCELOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6859,
    nome: 'BARRA ALEGRE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6860,
    nome: 'BARRA DE MACAE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6861,
    nome: 'BARRA DE SAO JOAO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6862,
    nome: 'BARRA DO PIRAI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6863,
    nome: 'BARRA MANSA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6864,
    nome: 'BARRA SECA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 10369,
    nome: 'BATATAL',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6865,
    nome: 'BELFORD ROXO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6866,
    nome: 'BEMPOSTA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6867,
    nome: 'BOA ESPERANCA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6868,
    nome: 'BOA SORTE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6869,
    nome: 'BOA VENTURA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6870,
    nome: 'BOM JARDIM',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6871,
    nome: 'BOM JESUS DO ITABAPOANA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6872,
    nome: 'BOM JESUS DO QUERENDO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6873,
    nome: 'CABO FRIO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6874,
    nome: 'CABUCU',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6875,
    nome: 'CACHOEIRAS DE MACACU',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6876,
    nome: 'CACHOEIROS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6877,
    nome: 'CALHEIROS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 11406,
    nome: 'CALIFORNIA DA BARRA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6878,
    nome: 'CAMBIASCA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6879,
    nome: 'CAMBUCI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6880,
    nome: 'CAMPO DO COELHO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6881,
    nome: 'CAMPOS DOS GOYTACAZES',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6882,
    nome: 'CAMPOS ELISEOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6883,
    nome: 'CANTAGALO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6884,
    nome: 'CARABUCU',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6885,
    nome: 'CARAPEBUS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6886,
    nome: 'CARDOSO MOREIRA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6887,
    nome: 'CARMO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6888,
    nome: 'CASCATINHA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6889,
    nome: 'CASIMIRO DE ABREU',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6890,
    nome: 'CAVA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6891,
    nome: 'COELHO DA ROCHA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6892,
    nome: 'COLONIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6893,
    nome: 'COMENDADOR LEVY GASPARIAN',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6894,
    nome: 'COMENDADOR VENANCIO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6895,
    nome: 'CONCEICAO DE JACAREI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6896,
    nome: 'CONCEICAO DE MACABU',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6897,
    nome: 'CONRADO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6898,
    nome: 'CONSELHEIRO PAULINO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6899,
    nome: 'CONSERVATORIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6900,
    nome: 'CORDEIRO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6901,
    nome: 'COROA GRANDE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6902,
    nome: 'CORREAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6903,
    nome: 'CORREGO DA PRATA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6904,
    nome: 'CORREGO DO OURO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6905,
    nome: 'CORRENTEZAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6906,
    nome: 'CUNHAMBEBE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6907,
    nome: 'DORANDIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6908,
    nome: 'DORES DE MACABU',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6909,
    nome: 'DOUTOR ELIAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6910,
    nome: 'DOUTOR LORETI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6911,
    nome: 'DUAS BARRAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6912,
    nome: 'DUQUE DE CAXIAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6913,
    nome: 'ENGENHEIRO PASSOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6914,
    nome: 'ENGENHEIRO PAULO DE FRONTIN',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6915,
    nome: 'ENGENHEIRO PEDREIRA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6916,
    nome: 'ESTRADA NOVA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6917,
    nome: 'EUCLIDELANDIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6918,
    nome: 'FALCAO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6919,
    nome: 'FLORIANO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 11282,
    nome: 'FRADE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6920,
    nome: 'FUMACA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6921,
    nome: 'FUNIL',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6922,
    nome: 'GAVIOES',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6923,
    nome: 'GETULANDIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6924,
    nome: 'GLICERIO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6925,
    nome: 'GOITACAZES',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6926,
    nome: 'GOVERNADOR PORTELA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6927,
    nome: 'GUAPIMIRIM',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6928,
    nome: 'GUIA DE PACOBAIBA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 12789,
    nome: 'IBIPEBA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6929,
    nome: 'IBITIGUACU',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6930,
    nome: 'IBITIOCA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6931,
    nome: 'IBITUPORANGA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6932,
    nome: 'IGUABA GRANDE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6840,
    nome: 'ILHA GRANDE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6933,
    nome: 'IMBARIE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6934,
    nome: 'INCONFIDENCIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6935,
    nome: 'INHOMIRIM',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6936,
    nome: 'INOA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6937,
    nome: 'IPIABAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6938,
    nome: 'IPIIBA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 10491,
    nome: 'IPITUNA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6939,
    nome: 'IPUCA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6940,
    nome: 'ITABAPOANA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6941,
    nome: 'ITABORAI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6942,
    nome: 'ITACURUSSA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6943,
    nome: 'ITAGUAI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6944,
    nome: 'ITAIPAVA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6945,
    nome: 'ITAIPU',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6946,
    nome: 'ITAJARA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6947,
    nome: 'ITALVA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6948,
    nome: 'ITAMBI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6949,
    nome: 'ITAOCARA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6950,
    nome: 'ITAPERUNA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6951,
    nome: 'ITATIAIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6953,
    nome: 'JAGUAREMBE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6954,
    nome: 'JAMAPARA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6955,
    nome: 'JAPERI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6956,
    nome: 'JAPUIBA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6957,
    nome: 'LAJE DO MURIAE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6958,
    nome: 'LARANJAIS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6959,
    nome: 'LIDICE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6960,
    nome: 'LUMIAR',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6961,
    nome: 'MACABUZINHO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6962,
    nome: 'MACAE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6963,
    nome: 'MACUCO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6964,
    nome: 'MAGE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6965,
    nome: 'MAMBUCABA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6966,
    nome: 'MANGARATIBA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 12107,
    nome: 'MANILHA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6967,
    nome: 'MANIVA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6968,
    nome: 'MANOEL RIBEIRO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6969,
    nome: 'MANUEL DUARTE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6970,
    nome: 'MARANGATU',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6971,
    nome: 'MARICA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6972,
    nome: 'MENDES',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6973,
    nome: 'MESQUITA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6974,
    nome: 'MIGUEL PEREIRA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6975,
    nome: 'MIRACEMA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6977,
    nome: 'MONJOLO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6976,
    nome: 'MONNERAT',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6978,
    nome: 'MONTE ALEGRE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6979,
    nome: 'MONTE VERDE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6980,
    nome: 'MONUMENTO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6981,
    nome: 'MORANGABA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6982,
    nome: 'MORRO DO COCO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6983,
    nome: 'MORRO GRANDE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6984,
    nome: 'MUSSUREPE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6985,
    nome: 'NATIVIDADE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6986,
    nome: 'NEVES',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6987,
    nome: 'NHUNGUACU',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6988,
    nome: 'NILOPOLIS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6989,
    nome: 'NITEROI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6990,
    nome: 'NOSSA SENHORA DA APARECIDA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6991,
    nome: 'NOSSA SENHORA DA PENHA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6992,
    nome: 'NOSSA SENHORA DO AMPARO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6993,
    nome: 'NOVA FRIBURGO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6994,
    nome: 'NOVA IGUACU',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6995,
    nome: 'OLINDA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6996,
    nome: 'OURANIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 12106,
    nome: 'PACHECOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6997,
    nome: 'PAPUCAIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6998,
    nome: 'PAQUEQUER PEQUENO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 6999,
    nome: 'PARACAMBI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7000,
    nome: 'PARAIBA DO SUL',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7001,
    nome: 'PARAISO DO TOBIAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7002,
    nome: 'PARAOQUENA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7003,
    nome: 'PARAPEUNA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7005,
    nome: 'PARATI MIRIM',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7004,
    nome: 'PARATY',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7006,
    nome: 'PASSA TRES',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7007,
    nome: 'PATY DO ALFERES',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7008,
    nome: 'PEDRA SELADA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7009,
    nome: 'PEDRO DO RIO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7010,
    nome: 'PENEDO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7011,
    nome: 'PENTAGNA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7012,
    nome: 'PETROPOLIS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7013,
    nome: 'PIABETA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7014,
    nome: 'PIAO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7015,
    nome: 'PINHEIRAL',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7016,
    nome: 'PIPEIRAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7017,
    nome: 'PIRAI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7018,
    nome: 'PIRANGAI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7019,
    nome: 'PIRAPETINGA DE BOM JESUS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7020,
    nome: 'PORCIUNCULA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7021,
    nome: 'PORTELA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7022,
    nome: 'PORTO DAS CAIXAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7023,
    nome: 'PORTO REAL',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7024,
    nome: 'PORTO VELHO DO CUNHA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7025,
    nome: 'POSSE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7027,
    nome: 'PUREZA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7028,
    nome: 'PURILANDIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7029,
    nome: 'QUARTEIS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7030,
    nome: 'QUATIS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7031,
    nome: 'QUEIMADOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7032,
    nome: 'QUISSAMA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7033,
    nome: 'RAPOSO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7034,
    nome: 'RENASCENCA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7035,
    nome: 'RESENDE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7036,
    nome: 'RETIRO DO MURIAE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7037,
    nome: 'RIALTO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7038,
    nome: 'RIBEIRAO DE SAO JOAQUIM',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7039,
    nome: 'RIO BONITO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7040,
    nome: 'RIO CLARO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7041,
    nome: 'RIO DAS FLORES',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7042,
    nome: 'RIO DAS OSTRAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7043,
    nome: 'RIO DE JANEIRO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7044,
    nome: 'RIOGRANDINA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7045,
    nome: 'ROSAL',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7046,
    nome: 'SACRA FAMILIA DO TINGUA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7047,
    nome: 'SALUTARIS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7048,
    nome: 'SAMBAETIBA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7049,
    nome: 'SAMPAIO CORREIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7050,
    nome: 'SANA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7051,
    nome: 'SANTA CLARA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7052,
    nome: 'SANTA CRUZ',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7053,
    nome: 'SANTA CRUZ DA SERRA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7054,
    nome: 'SANTA ISABEL',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7055,
    nome: 'SANTA ISABEL DO RIO PRETO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7056,
    nome: 'SANTA MARIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7057,
    nome: 'SANTA MARIA MADALENA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7058,
    nome: 'SANTA RITA DA FLORESTA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7059,
    nome: 'SANTANESIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7060,
    nome: 'SANTO ALEIXO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7061,
    nome: 'SANTO AMARO DE CAMPOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7062,
    nome: 'SANTO ANTONIO DE PADUA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7063,
    nome: 'SANTO ANTONIO DO IMBE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7064,
    nome: 'SANTO EDUARDO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7065,
    nome: 'SAO FIDELIS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7066,
    nome: 'SAO FRANCISCO DE ITABAPOANA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7067,
    nome: 'SAO GONCALO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7068,
    nome: 'SAO JOAO DA BARRA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7069,
    nome: 'SAO JOAO DE MERITI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7070,
    nome: 'SAO JOAO DO PARAISO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7071,
    nome: 'SAO JOAO MARCOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7072,
    nome: 'SAO JOAQUIM',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7073,
    nome: 'SAO JOSE DE UBA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7074,
    nome: 'SAO JOSE DO RIBEIRAO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7075,
    nome: 'SAO JOSE DO TURVO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7076,
    nome: 'SAO JOSE DO VALE DO RIO PRETO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7077,
    nome: 'SAO MATEUS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7078,
    nome: 'SAO PEDRO DA ALDEIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 10208,
    nome: 'SAO PEDRO DA SERRA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7079,
    nome: 'SAO SEBASTIAO DE CAMPOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7080,
    nome: 'SAO SEBASTIAO DO ALTO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7081,
    nome: 'SAO SEBASTIAO DO PARAIBA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7082,
    nome: 'SAO SEBASTIAO DOS FERREIROS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7083,
    nome: 'SAO VICENTE DE PAULA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7084,
    nome: 'SAPUCAIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7085,
    nome: 'SAQUAREMA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7086,
    nome: 'SARACURUNA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7087,
    nome: 'SEBASTIAO DE LACERDA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7088,
    nome: 'SEROPEDICA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7089,
    nome: 'SERRINHA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 10409,
    nome: 'SERRINHA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7090,
    nome: 'SETE PONTES',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7091,
    nome: 'SILVA JARDIM',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7092,
    nome: 'SODRELANDIA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7093,
    nome: 'SOSSEGO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7094,
    nome: 'SUBAIO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7095,
    nome: 'SUMIDOURO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7096,
    nome: 'SURUI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7097,
    nome: 'TABOAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7098,
    nome: 'TAMOIOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7099,
    nome: 'TANGUA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7100,
    nome: 'TAPERA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7101,
    nome: 'TARITUBA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7102,
    nome: 'TERESOPOLIS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7103,
    nome: 'TOCOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7104,
    nome: 'TRAJANO DE MORAES',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7105,
    nome: 'TRAVESSAO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7106,
    nome: 'TRES IRMAOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7107,
    nome: 'TRES RIOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7108,
    nome: 'TRIUNFO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7109,
    nome: 'VALAO DO BARRO',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7110,
    nome: 'VALENCA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7111,
    nome: 'VARGEM ALEGRE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7112,
    nome: 'VARRE-SAI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7113,
    nome: 'VASSOURAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7114,
    nome: 'VENDA DAS FLORES',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7115,
    nome: 'VENDA DAS PEDRAS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7116,
    nome: 'VILA DA GRAMA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7118,
    nome: 'VILA MURIQUI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7119,
    nome: 'VILA NOVA DE CAMPOS',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7120,
    nome: 'VISCONDE DE IMBE',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 12108,
    nome: 'VISCONDE DE ITABORAI',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 11452,
    nome: 'VISCONDE DE MAUA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7121,
    nome: 'VOLTA REDONDA',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7122,
    nome: 'WERNECK',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
  {
    codigo: 7123,
    nome: 'XEREM',
    uf: {
      codigo: 20,
      sigla: 'RJ',
      nome: 'RIO DE JANEIRO',
      ativo: true,
    },
    ativo: true,
  },
];

export const resolveFakeNationalitiesData = () => Promise.resolve(fakeNationalitiesData);
export const resolveFakeFederativeData = () => Promise.resolve(fakeFederativeUnitData);
