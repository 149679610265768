import styled, { css } from 'styled-components/macro';

import { ReactComponent as Exclamation } from '../../assets/icons/exclamationTriangle.svg';
import { ReactComponent as Info } from '../../assets/icons/info-icon-blue.svg';
import { pxToRem } from '../../utils';

export const Container = styled.div<{ type: string }>`
  font-family: Nunito;
  font-size: ${pxToRem(14)};
  font-weight: 600;
  line-height: ${pxToRem(18)};
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.blueTypo2};
  background: ${({ theme }) => theme.colors.blueLight};
  padding: ${pxToRem(16)};
  border-radius: ${pxToRem(16)};

  &.mt-8 {
    margin-top: ${pxToRem(8)};
  }

  &.mt-16 {
    margin-top: ${pxToRem(16)};
  }

  &.mt-24 {
    margin-top: ${pxToRem(24)};
  }

  &.mt-32 {
    margin-top: ${pxToRem(32)};
  }

  &.fit-content {
    width: auto;
    display: inline-flex;
    padding: ${pxToRem(8)} ${pxToRem(16)};
    margin: ${pxToRem(16)} 0;
  }

  &.car-plate {
    width: auto;
    display: inline-flex;
    padding: ${pxToRem(16)} ${pxToRem(16)};
    margin: ${pxToRem(22)} 0;
    border-radius: ${pxToRem(16)};
  }

  &.detail-proposal {
    align-items: flex-start;
    margin: ${pxToRem(32)} 0;
    gap: ${pxToRem(8)};
    background-color: #cbe3ff;
    line-height: 140%;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.brandBlue400};
  }

  ${({ type }) =>
    type === 'WARNING' &&
    css`
      color: ${({ theme }) => theme.colors.orange};
      background-color: ${({ theme }) => theme.colors.orange}1A;
    `};

  ${({ type }) =>
    type === 'ERROR' &&
    css`
      color: ${({ theme }) => theme.colors.red};
      background-color: ${({ theme }) => theme.colors.red}1A;
    `};

  ${({ type }) =>
    type === 'UPDATE' &&
    css`
      display: flex;
      align-items: flex-start;
      width: fit-content;
      padding: ${pxToRem(8)};
      gap: ${pxToRem(4)};
      border-radius: ${pxToRem(8)};
      color: ${({ theme }) => theme.colors.blueTypo};
      font-weight: 700;
      font-size: ${pxToRem(12)};
      line-height: ${pxToRem(16)};
      cursor: pointer;
    `};
`;

export const ImageIcon = styled.img`
  background-color: transparent;
  padding-right: ${pxToRem(10)};
  padding-top: ${pxToRem(0)};
  height: ${pxToRem(30)} !important;
  width: ${pxToRem(30)} !important;

  &.update {
    height: ${pxToRem(16)} !important;
    width: ${pxToRem(16)} !important;
    padding: 0;
  }
`;

export const ErrorSvg = styled(Exclamation)`
  padding-right: ${pxToRem(10)};
  padding-top: ${pxToRem(0)};
  height: ${pxToRem(30)} !important;
  width: ${pxToRem(30)} !important;
  path {
    fill: ${({ theme }) => theme.colors.red};
  }
`;

export const InfoSvg = styled(Info)`
  width: ${pxToRem(30)};

  path {
    fill: ${({ theme }) => theme.colors.blueStrong};
  }
`;
