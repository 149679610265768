import { memo, useCallback, useEffect, useState } from 'react';

import Modal from '../Modal';
import { formatNumber, isEmpty } from '../../utils';

import {
  Answer,
  AnswerFormatted,
  ButtonInputCover,
  CleanInput,
  Container,
  ContentInput,
  ContentModal,
  ExclamationTriangle,
  HelperText,
  InputFormatted,
  Question,
  QuestionCircle,
  Title,
} from './styles';

type Props = {
  action: (value) => void;
  answer: any;
  buttonLabel: string;
  icon: 'question';
  placeholder: string;
  question: string;
  helperText?: string;
  inputFormat?: string;
  inputMask?: string;
  invalidText?: string;
  isValidFn?: (value: string) => boolean;
  maxLength?: number;
  minLength?: number;
  modalClickAction?: () => void;
  placeholderModal?: string;
  prefix?: string;
  small?: boolean;
  tagAction?: () => void;
  tagModalShowAction?: () => void;
  titleModal?: string;
  iconClick: () => void;
};

const InputNumberIconModal = (props: Props): JSX.Element => {
  const {
    action,
    answer,
    buttonLabel,
    icon,
    iconClick,
    placeholder,
    question,
    helperText,
    inputFormat,
    inputMask,
    invalidText,
    isValidFn,
    maxLength,
    minLength,
    modalClickAction,
    placeholderModal,
    prefix,
    small = false,
    tagAction,
    tagModalShowAction,
    titleModal,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState(answer === '-' ? '' : answer);
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const stateIsEmpty = isEmpty(inputValue);
  const [isDisabled, setIsDisabled] = useState(!inputValue);

  const openModal = useCallback(() => {
    if (tagAction && !showModal) {
      tagAction();
    }
    if (!showModal && tagModalShowAction) {
      tagModalShowAction();
    }
    setShowModal(prev => !prev);
  }, []);

  const cleanState = useCallback(() => {
    setInputValue('');
  }, [action]);

  const handleKeyPress = event => {
    if (isDisabled) return;

    if (event.key === 'Enter' || event.which === 13) {
      setShowModal(false);
      action(inputValue);
    }
  };

  const handleChange = useCallback(
    value => {
      if (!value) {
        setInputValue(value);
        return;
      }

      const [integer, decimal] = value.toString().split('.');
      if (maxLength && (integer.length > maxLength || decimal?.length > 2)) {
        setInputValue(`${integer.substring(0, maxLength)}.${decimal ? decimal.substring(0, 2) : '00'}`);
        return;
      }

      setInputValue(value);
    },
    [maxLength],
  );

  const handleClick = e => {
    if (answer === '' || undefined) cleanState();
    const ripples = document.createElement('span');
    const buttonPosition = e.target.getBoundingClientRect();
    const top = Math.abs(buttonPosition.top - e.clientY);
    const left = Math.abs(buttonPosition.left - e.clientX);

    ripples.style.top = `${top}px`;
    ripples.style.left = `${left}px`;

    e.target.appendChild(ripples);

    setTimeout(() => {
      ripples.remove();

      return openModal();
    }, 300);
  };

  useEffect(() => {
    if (isValidFn !== undefined) {
      const isValid: boolean = isValidFn(inputValue);
      setIsDisabled(!isValid);
      if (invalidText && inputValue?.trim().length === minLength) {
        setErrorMessage(isValid ? undefined : invalidText);
      } else {
        setErrorMessage(undefined);
      }
    } else {
      if (typeof inputValue === 'number') setIsDisabled(inputValue === 0);
      if (typeof inputValue === 'string') setIsDisabled(inputValue?.trim().length === 0);
    }
  }, [inputValue]);

  return (
    <>
      <Container>
        <div>
          <span>
            <Question>{question}</Question>
            {icon === 'question' && <QuestionCircle onClick={iconClick} />}
          </span>
          {stateIsEmpty && <Answer place={!answer ? placeholder : ''}>{answer || placeholder}</Answer>}
          {prefix && !stateIsEmpty && !!answer && <Answer>{formatNumber.currency(answer) || placeholder}</Answer>}
          {!stateIsEmpty && !prefix && (
            <AnswerFormatted format={inputFormat} mask={inputMask} value={answer} placeholder={placeholder} />
          )}
        </div>
        <ButtonInputCover onClick={handleClick}>{answer && answer !== '-' ? 'Editar' : buttonLabel}</ButtonInputCover>
      </Container>
      <Modal
        open={showModal}
        onClose={setShowModal}
        buttonDisabled={isDisabled}
        onClick={() => (modalClickAction ? modalClickAction() : action(inputValue))}
      >
        <ContentModal>
          <Title>{titleModal || question}</Title>
          <ContentInput error={!!errorMessage}>
            <InputFormatted
              displayType="input"
              format={inputFormat}
              mask={inputMask}
              value={inputValue?.replace?.(/\D/gm, '')}
              placeholder={placeholderModal || placeholder}
              onValueChange={e => (prefix ? handleChange(e.floatValue) : handleChange(e.value))}
              autoFocus
              onKeyDown={handleKeyPress}
              prefix={prefix}
              thousandSeparator={prefix ? '.' : false}
              decimalSeparator=","
              inputMode="numeric"
              onBlur={evt => evt.target.focus()}
            />

            {errorMessage ? <ExclamationTriangle onClick={cleanState} /> : !stateIsEmpty && <CleanInput onClick={cleanState} />}
          </ContentInput>

          {errorMessage ? (
            <HelperText small error>
              {invalidText || errorMessage}
            </HelperText>
          ) : (
            helperText && <HelperText small={small}>{helperText}</HelperText>
          )}
        </ContentModal>
      </Modal>
    </>
  );
};

export default memo(InputNumberIconModal);
