const fakeSimulationQuoteData = [
  {
    customerId: 'CUS-08cd6e3b-f9d5-4e80-85ab-a00882caa5aa',
    productType: 'CVG',
    status: 'DISAPPROVED',
    id: 'SML-08ba3ae7-2cdb-4aaf-8c02-cc8b14aa7dfd',
    valueLoan: 104000,
    countInstallments: 12,
    createdAt: '2022-10-24',
    vehicle: {
      id: 'VCL-0434b1b6-d953-448c-a1d5-97870f9653cb',
      crv: false,
    },
    customerLoan: {
      document: '34863165838',
      firstName: 'Jeniffer',
      motherName: 'Ana Lima',
      id: 'CUL-5eb7edd8-7577-442e-8d40-6464a91b067f',
    },
    flowSimulation: {
      active: true,
      step: 'VEHICLE_INSPECTION',
      status: 'PENDING',
    },
    resultQuote: {
      costPrice: 1258,
      installmentWithoutInsurance: 10479.49,
      iof: 2230.68,
      taxAnnualFinance: 34.18,
      taxMonthFinance: 2.48,
      tc: 924,
      totalLoan: 125753.88,
      valuePercentCetAnnual: 43.7,
      valuePercentCetMonth: 3.02,
      valueTotalInstallmentWithoutInsurance: 105258,
      valueTotalInstallments: 125753.88,
      registrationFee: 334,
    },
  },
  {
    customerId: 'CUS-08cd6e3b-f9d5-4e80-85ab-a00882caa5aa',
    productType: 'CVG',
    status: 'DISAPPROVED',
    id: 'SML-18c3011a-293e-4a8a-9dab-f1767b7b21e5',
    createdAt: '2022-12-29',
    vehicle: {
      id: 'VCL-a4381569-d883-4d2a-9734-7e6ae0ebb7b7',
      crv: false,
    },
    customerLoan: {
      document: '34863165838',
      firstName: 'Jeniffer',
      motherName: 'Ana Lima',
      id: 'CUL-da4238c3-ff8b-4164-b5ba-a4400151bdab',
    },
    flowSimulation: {
      active: true,
      step: 'BV_MANAGEMENT_TRANSACTION',
      status: 'WAITING',
    },
    resultQuote: {},
  },
  {
    customerId: 'CUS-08cd6e3b-f9d5-4e80-85ab-a00882caa5aa',
    productType: 'CVG',
    status: 'OPENED',
    id: 'SML-2e722708-be8d-4424-9c1d-d5692232f806',
    valueLoan: 37900,
    countInstallments: 12,
    createdAt: '2023-01-31',
    vehicle: {
      id: 'VCL-755f2a42-dda8-4821-9686-6615bc643672',
      crv: false,
    },
    customerLoan: {
      document: '34863165838',
      firstName: 'Jeniffer',
      motherName: 'ANA LIMA DA SILVA SANTOS',
      id: 'CUL-5a1fe956-e166-452c-92d5-41824cc55a27',
      bankAccountResponse: {
        uuid: 'BAK-52d4f31c-c255-11ea-963e-0a2270937ff5',
        code: '001',
        agency: '0001',
        number: '1298198291821',
        checkNumber: '1',
      },
    },
    flowSimulation: {
      active: true,
      step: 'BV_MANAGEMENT_TRANSACTION',
      status: 'WAITING',
    },
    resultQuote: {
      costPrice: 1258,
      installmentWithoutInsurance: 3990.2,
      iof: 839.63,
      taxAnnualFinance: 39.85,
      taxMonthFinance: 2.83,
      tc: 924,
      totalLoan: 47882.4,
      valuePercentCetAnnual: 56.13,
      valuePercentCetMonth: 3.73,
      valueTotalInstallmentWithoutInsurance: 39158,
      valueTotalInstallments: 47882.4,
      registrationFee: 334,
    },
  },
];

const fakeStatusOpenedData = {
  customerId: 'CUS-08cd6e3b-f9d5-4e80-85ab-a00882caa5aa',
  dueDateFirstInstallments: '2023-03-17',
  firstInstalment: 0,
  partnerQuoteId: '97171443',
  paymentType: '0',
  productType: 'CVG',
  status: 'OPENED',
  id: 'SML-2e722708-be8d-4424-9c1d-d5692232f806',
  valueLoan: 37900,
  countInstallments: 12,
  waitingDay: 30,
  zeroKmVehicle: false,
  vehicle: {
    brand: 'TOYOTA',
    categoryDescription: 'AUTOMOVEL',
    manufactoreYear: '2018',
    model: 'ETIOS SEDAN X 1.5 16V 4P (AG) Completo',
    modelYear: '2018',
    modelVersion: 'ETIOS SEDAN X 1.5 16V',
    stateLicenseVehicle: 'RJ',
    licensePlate: 'FCH7525',
    renavan: '79032957666',
    id: 'VCL-755f2a42-dda8-4821-9686-6615bc643672',
    crv: false,
  },
  customerLoan: {
    document: '34863165838',
    typePeople: 'F',
    firstName: 'Jeniffer',
    lastName: 'Lima Asbahr',
    fullName: 'Jeniffer Lima Asbahr',
    birthDate: '1986-09-01',
    gender: 'M',
    maritalStatus: 'SOLTEIRO',
    motherName: 'ANA LIMA DA SILVA SANTOS',
    nacionality: 'BRASILEIRO',
    placeOfBirthCity: 'RIO DE JANEIRO',
    placeOfBirthState: 'RJ',
    occupationCode: 125,
    occupationType: 1,
    monthlyIncome: 20000,
    totalPatrimony: 200000,
    email: 'bsuheue@gmail.com',
    id: 'CUL-5a1fe956-e166-452c-92d5-41824cc55a27',
    customerAddresses: [
      {
        id: 'ADS-d1653e64-3086-4f07-8073-43fce05b1cd7',
        type: '1',
        street: 'Rua Barão de Mesquita',
        number: 'casa 2',
        city: 'Rio de Janeiro',
        district: 'Andaraí',
        zip: '20540156',
      },
    ],
    contacts: [
      {
        areaCode: '21',
        number: '965712467',
        type: 'MOBILE',
        id: '230',
      },
    ],
    customerDocuments: [
      {
        document: '34863165838',
        type: 'CPF',
        uuid: 'DOC-1a2c8fc2-e5e8-402e-acbf-a0f9e2d57db9',
        customerLoan: {
          id: 'CUL-5a1fe956-e166-452c-92d5-41824cc55a27',
        },
      },
      {
        document: '01920192091',
        issueDate: '2022-01-01',
        issuingAuthority: 'Detran',
        issuingState: 'RJ',
        type: 'CNH',
        uuid: 'DOC-b4498e85-df94-4313-818b-64e5808775f8',
        customerLoan: {
          id: 'CUL-5a1fe956-e166-452c-92d5-41824cc55a27',
        },
      },
    ],
    bankAccountResponse: {
      uuid: 'BNK-c7770b77-2007-4679-9bc0-8968df65451d',
      code: '001',
      agency: '0001',
      number: '1298198291821',
      checkNumber: '1',
    },
  },
  flowSimulation: {
    active: true,
    step: 'SIMULATION',
    status: 'WAITING',
  },
};

export const resolveFakeSimulationQuoteData = () => Promise.resolve(fakeSimulationQuoteData);
export const resolveFakeStatusOpenendData = () => Promise.resolve(fakeStatusOpenedData);
